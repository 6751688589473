<div class="modal-top-bar">
    <div class="modal-top-left-action"></div>

    <!-- Title -->
    <div class="modal-top-title">
       {{ (!!(tempData$ | async)?.id ? 'menu_addon_create_group_title_edit' : 'menu_addon_create_group_title') | translate }}
    </div>

    <!-- Cancel button -->
    <div class="modal-top-right-action" >
        <div class="action-item" (click)="onCancel()">
            {{ 'menu_product_modal_cancel' | translate }}
        </div>
    </div>
</div>

<div class="modal-content">

    <div class="modal-top-inputs">

      <!-- Left side -->
      <div class="addon-input-container-left">
        <!-- Name title -->
        <div class="subtitle-text">
          {{ 'menu_addon_create_group_popup_name' | translate }}
        </div>

        <!-- Deutsch -->
        <div class="names-column-container">
          <div class="names-column-1-container">
            <!-- Icon -->
            <div class="icon-language-container">
              <img class="icon-language" [src]="'apps/restaurant/src/assets/flag-austria.svg'"/>
            </div>
            <!-- Input Name (Deutsch) -->
            <fs-input [label]="'menu_product_modal_name_de' | translate" [style]="'full-width'" class="r-info-public-name-input mr-6"
                      [inputValue]="(tempData$ | async)?.name || ''" (valueUpdate)="nameUpdate($event)">
            </fs-input>
          </div>
          <div class="names-column-2-container">
            <!-- Icon -->
            <div class="icon-language-container">
              <img class="icon-language" [src]="'apps/restaurant/src/assets/globe-green-round-v2.svg'"/>
            </div>
            <!-- Input Name (English) -->
            <fs-input [label]="'menu_product_modal_name_en' | translate" [style]="'full-width'" class="r-info-public-name-input mr-6"
                      [inputValue]="(tempData$ | async)?.nameEn || ''"  (valueUpdate)="nameEnUpdate($event)">
            </fs-input>
          </div>
        </div>
      </div>


      <!-- Right side -->
      <div class="addon-input-container-right">

        <!-- Selection title -->
        <div class="subtitle-text">
          {{ 'menu_addon_create_group_popup_selection_title' | translate }}
        </div>

        <div class="names-column-container">
          <!-- Min selection -->
          <div class="names-column-1-container">
            <fs-input [label]="'menu_addon_create_group_popup_min' | translate" [style]="'full-width'" class="r-info-public-name-input mr-4"
                      [inputValue]="'' + (tempData$ | async)?.minSelection || ''" [type]="'number'" (valueUpdate)="minSelectionUpdate($event)">
            </fs-input>
            <div class="selection-info-container"
                 #tooltip="matTooltip"
                 [matTooltip]="'menu_addon_create_group_popup_selection_min_tooltip' | translate"
                 [matTooltipPosition]="'right'"
                 [matTooltipClass]="'custom-selection-tooltip'"
                 (mousedown)="tooltip.toggle()">
                <img [src]="'apps/restaurant/src/assets/add-menu-blue-info.svg'"/>
            </div>
          </div>
          <!-- Max selection -->
          <div class="names-column-2-container">
            <fs-input [label]="'menu_addon_create_group_popup_max' | translate" [style]="'full-width'" class="r-info-public-name-input mr-4"
                      [inputValue]="'' + (tempData$ | async)?.maxSelection || ''" [type]="'number'" (valueUpdate)="maxSelectionUpdate($event)">
            </fs-input>
            <div class="selection-info-container"
                 #tooltip="matTooltip"
                 [matTooltip]="'menu_addon_create_group_popup_selection_max_tooltip' | translate"
                 [matTooltipPosition]="'right'"
                 [matTooltipClass]="'custom-selection-tooltip'"
                 (mousedown)="tooltip.toggle()">
              <img [src]="'apps/restaurant/src/assets/add-menu-blue-info.svg'"/>
            </div>
          </div>
        </div>
      </div>

    </div>


  <!-- Delete Add-on Group -->
  <ng-container *ngIf="!!(tempData$ | async)?.id">
    <!-- Show only if it has an ID => meaning it was stored on API before. Otherwise, when creating a new Addon group, we shouldn't see the Delete option -->
    <div class="addon-delete-container">
      <div class="delete-action-area" (mousedown)="onDeleteClick()">
        <div class="flex mr-1">
          <img [src]="'apps/restaurant/src/assets/delete-bin-orange.svg'"/>
        </div>
        <div class="addon-delete-text">
          {{ 'menu_addon_create_group_popup_delete' | translate }}
        </div>
      </div>
    </div>
  </ng-container>


    <!-- Addons selection -->

    <div class="addons-selection-area">

      <div class="addon-title-dropdown">
        <!-- Subtitle -->
        <div class="subtitle-text">
          {{ 'menu_addon_create_group_popup_addons' | translate }}
        </div>

        <ng-container *ngIf="((tempData$ | async)?.addonList?.length || 0) > 0;else noAddons">
          <div class="addon-dropdown">
            <!--<fs-menu-advanced
              [itemsList]='(addonsList$ | async) || []'
              [selectedItem]="(getCategoryById$((tempData$ | async)?.menuCategoryId) | async)"
              [preselectPlaceholder]="'menu_product_modal_category_pick' | translate"
              (onSelect)="onSelectCategory($event)">
            </fs-menu-advanced>-->
          </div>
        </ng-container>

        <ng-template #noAddons>
            <div class="missing-addons">
                <div class="missing-addons-text">
                  <div>{{ 'menu_addon_create_group_popup_missing_addons' | translate }}</div>
                </div>
                <div class="missing-addons-button>">
                  <div class="flex button-create-addon">
                    <!-- Button - Create addon -->
                    <fs-button [text]="'menu_addon_create_group_popup_new_addon' | translate"
                               [type]="'raised'" [leadingIcon]="'add'"
                               (onPress)="addCreateAddonPress()">
                    </fs-button>
                  </div>
                </div>
            </div>
        </ng-template>


      </div>

      <!-- Add-ons list -->
      <div class="addon-list">
        <mat-chip-listbox aria-label="Addons selection">
          <ng-container *ngFor="let item of (tempData$ | async)?.addonList; index as i " class="category-list-item-container">
            <mat-chip-option color="primary" [id]="item.id" [selectable]="false" (removed)="onRemoveAddon(item)">
            <!--<mat-chip-option color="primary" [id]="item.id" (selectionChange)="onAllergenChange($event, item)">-->
              {{ item.name }}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-option>

          </ng-container>
        </mat-chip-listbox>
      </div>

    </div>

    <!-- Add or Save changes button -->
    <div class="modal-bottom-action">
        <fs-button [text]="(!!(tempData$ | async)?.id ? 'menu_addon_create_group_popup_button_save' : 'menu_addon_create_group_popup_button_add') | translate" [style]="'full-width-large-height'"
                   [type]="'raised-primary'" (onPress)="onAddGroupClick()" [disabled]="(isButtonDisabled$() | async) || false">
        ></fs-button>
    </div>
</div>

