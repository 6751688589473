import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ModalService} from "libs/shared-ui/src/lib/modal-service/modal-service.service";
import {RestaurantMenuService} from "../../../../../services/restaurant-menu/restaurant-menu.service";
import {BehaviorSubject, map, Observable} from "rxjs";
import {LocaleService} from "libs/shared-services/src/lib/locale.service";
import {CategoryLocalResponse} from "../../../../../services/restaurant-menu/data-store/model/category-local-response";
import {ProductSlimInfo} from "libs/shared-models/src/lib/restaurant/product-slim-info";
import {MenuCategoryResponse} from "../../../../../services/restaurant-menu/data-store/model/menu-category-response";
import {LocaleTranslatePipe} from "libs/shared-services/src/lib/locale-pipe";
import {ProductResponse} from "../../../../../services/restaurant-menu/data-store/model/product-response";
import {ProductModalComponent} from "../product-modal/product-modal.component";

@Component({
  selector: 'category-product-modal',
  templateUrl: './category-product-modal.component.html',
  styleUrls: ['./category-product-modal.component.scss'],
})
export class CategoryProductModalComponent implements OnInit {


    /*
        Local data
     */
    public tempData$: BehaviorSubject<CategoryLocalResponse> = new BehaviorSubject<CategoryLocalResponse>(new CategoryLocalResponse());

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: CategoryLocalResponse,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<CategoryProductModalComponent>,
        private modalService: ModalService,
        private menuService: RestaurantMenuService,
        private localeService: LocaleService
    ) {
    }

    public ngOnInit() {

        // Assign the data received on Modal creation
        const newCategory: CategoryLocalResponse = Object.assign(new CategoryLocalResponse(), this.data); // new instance
        newCategory.category = Object.assign(new MenuCategoryResponse(), this.data.category); // new instance
        newCategory.products = Object.assign([], this.data.products); // new instance
        this.tempData$.next(Object.assign(new CategoryLocalResponse(), newCategory));

        // Listen to UI modal backdrop:
        this.dialogRef.backdropClick().subscribe(() => {
            this.onCancel();
        });
    }

    private getData(): CategoryLocalResponse {
        return this.tempData$.getValue();
    }

    private setData(val: CategoryLocalResponse) {
        this.tempData$.next(val);
    }

    // Popup cancel
    public onCancel() {
        const newLocal = Object.assign(new CategoryLocalResponse(), this.getData());
        const isSameContent: boolean = JSON.stringify(newLocal) === JSON.stringify(this.data); // compare to the initial one before the popup was opened
        if (!isSameContent) {
            // Notify the user that he'll lose the current changes
            this.modalService.openConfirmDialog$().subscribe((response) => {
                if (response) { // true
                    this.dialogRef.close();
                }
            });
        } else {
            this.dialogRef.close();
        }
    }

  // Button add new Group
  public onAddGroupClick() {
    const currData: CategoryLocalResponse = this.tempData$.getValue();
    this.menuService.updateCategoryAPI$(currData).subscribe((value) => {
      if (value) {
        // close the popup
        this.dialogRef.close();
      }
    })
  }

  public nameUpdate(event: any) {
      const currentData = this.getData();
      currentData.category.name = event;
      this.setData(currentData);
  }

  public descriptionUpdate(event: any) {
    const currentData = this.getData();
    currentData.category.description = event;
    this.setData(currentData);
  }

  public isButtonDisabled$(): Observable<boolean> {
    return this.tempData$.pipe(
      map(data => {
        // came from API
        if (!!data.category.id) {
          // make sure that the initial changes done inside this componnent don't affect the comparison. So make them as they were initially
          const newLocal = Object.assign(new CategoryLocalResponse(), data);
          return JSON.stringify(newLocal) === JSON.stringify(this.data); // compare to the initial one before the popup was opened
        } else {
          // it was a temp one
          return !data.category.name; //TODO: maybe add more conditions here
        }
      })
    );
  }

  // Remove category
  public onDeleteClick() {
    const config = this.modalService.getDefaultDialogConfig();
    config.width = "500px";
    config.data = {
      title: new LocaleTranslatePipe(this.localeService).transform("menu_category_modal_delete_confirm_title"),
      midContent: new LocaleTranslatePipe(this.localeService).transform("menu_category_modal_delete_confirm_body"),
      leftButtonContent: new LocaleTranslatePipe(this.localeService).transform("generic_modal_default_left_action"),
      rightButtonContent: new LocaleTranslatePipe(this.localeService).transform("generic_modal_default_right_action"),
      preselectedButton: "left"
    }

    this.modalService.openConfirmDialog$(config).subscribe((response) => {
      if (response) { // YES pressed in the confirm modal
        this.menuService.deleteCategoryAPI$(this.getData()).subscribe((value) => {
          if (value) {
            // close the popup
            this.dialogRef.close();
          }
        })
        this.dialogRef.close();
      }
    });
  }


  // Cancel (x) icon tap on the product chip
  public onRemoveProduct(product: ProductSlimInfo) {
    const config = this.modalService.getDefaultDialogConfig();
    config.width = "500px";
    config.data = {
      title: new LocaleTranslatePipe(this.localeService).transform("menu_product_modal_delete_confirm_title"),
      midContent: new LocaleTranslatePipe(this.localeService).transform("menu_product_modal_delete_confirm_body"),
      leftButtonContent: new LocaleTranslatePipe(this.localeService).transform("generic_modal_default_left_action"),
      rightButtonContent: new LocaleTranslatePipe(this.localeService).transform("generic_modal_default_right_action"),
      preselectedButton: "left"
    }

    // Notify the user that he'll lose the current changes
    this.modalService.openConfirmDialog$(config).subscribe((response) => {
      if (response) { // true
        const p = Object.assign(new ProductResponse(), product);
        // add it so it knows then to delete it from the category list too
        p.menuCategoryId = this.getData().category.id;
        this.menuService.deleteProductAPI$(p).subscribe((value) => {
          if (value) {
            this.clearLocalPopupData(p);
          }
        });
      }
    });
  }

  // Add new product
  public addCreateProductPress() {
    const tempNewProduct = new ProductResponse();
    tempNewProduct.available = true; // pre-select it in the modal
    tempNewProduct.menuCategoryId = this.getData().category.id; // pre-select it in the modal
    tempNewProduct.menuIds = this.menuService.getMenuListState().menuList.map((m) => m.id);
    this.openAddProductModal(tempNewProduct);
  }

  private openAddProductModal(product: ProductResponse) {
    const modalConfig = this.modalService.getDefaultDialogConfig();
    modalConfig.data = product;
    modalConfig.width = "700px";
    modalConfig.height = "650px";
    modalConfig.disableClose = true;
    this.modalService.openCustomDialog$(ProductModalComponent, modalConfig).subscribe((callbackResponse) => {

      // as it was updated to API and inside the service at the end of product modal, now just get that data
      const newUpdatedCategory = this.menuService.getProductsByCategoryListState().categories.find((c) => c.categoryId === this.getData().category.id);
      if (!!newUpdatedCategory) {
        // update the initial modal one, so it doesn't trigger changes in isDisabled button
        this.data.products = [...newUpdatedCategory.products];
        // update also the local data
        const currentData = this.getData();
        currentData.products = [...newUpdatedCategory.products];
        this.setData(currentData);
      }
      
    });
  }

  private clearLocalPopupData(product: ProductResponse) {
      // Initial popup data
      this.data.products = [...this.data.products];
      this.data.products = this.data.products.filter(p => p.id !== product.id);

      // Current temp data
      const currentData = this.getData();
      currentData.products = [...currentData.products];
      currentData.products = currentData.products.filter(p => p.id !== product.id);
      this.setData(currentData);
  }
}
