export const environment = {
    BUILD_PRODUCT: "restaurant",
    API_URL_BASE: "https://api.staging.foodis.day/",
    PRODUCT_URLS: {
        LANDING_PAGE: "https://staging.foodis.day",
        USER_APP: "https://app.staging.foodis.day",
        DRIVER: "https://driver.staging.foodis.day",
        ADMIN: "https://admin.staging.foodis.day",
        ACCOUNT: "https://account.staging.foodis.day",
        ACCOUNT_REGISTRATION: "https://account.staging.foodis.day/register"
    },
    URL_ALLERGEN_EXTERNAL: "https://www.wko.at/oe/tourismus-freizeitwirtschaft/gastronomie/allergeninfos-english",
    API_USER_SELF: "v1/users/self",
    API_RESTAURANT_CREATE: "v1/restaurants",
    API_RESTAURANT_INFO: "v1/restaurants/my",
    API_GET_MENU: "v1/restaurants/my/menu",
    API_GET_MENU_CATEGORY_LIST: "v1/restaurants/my/menu-category",
    API_GET_MENU_PRODUCTS_LIST: "v1/restaurants/my/products",
    API_GET_MENU_ADDON_LIST: "v1/restaurants/my/addongroup",
    API_UPLOAD_FILE: "v1/blob/upload",
    API_POST_REQUEST_VERIFICATION: "v1/restaurants/my/request-verification",
    API_GET_POST_KITCHEN_INVITATION: "v1/restaurants/my/kitchen-invitation",
    API_GET_DELETE_KITCHEN_INVITATION: "v1/restaurants/my/kitchen-invitation/{kitchenInvitationId}",
    API_POST_REGISTER_INVITATION: "v1/users/register-invitation",
    API_GET_TEAM_USERS_LIST: "v1/restaurants/my/team-users",
    API_GET_DELETE_TEAM_USER: "v1/restaurants/my/team-users/{userId}",
    API_GET_PUBLIC_KITCHEN_INVITATION: "v1/public/invitations/kitchen/{id}",
    API_GET_ORDERS_LIST: "v1/restaurants/my/orders",
    API_GET_ORDERS_SET_STATUS: "v1/restaurants/my/orders/{orderId}/status",
    DEBUG_STAGING_BADGE: true
};
