import {Inject, Injectable} from '@angular/core';
import {ApiBaseService} from "libs/shared-services/src/lib/api-base.service";
import {ToasterService} from "libs/shared-services/src/lib/toaster.service";
import {environment} from "../../../environments/environment";
import {Observable, catchError, map, of} from "rxjs";
import { RestaurantResponse, RestaurantVerificationEnum, DocumentResponse } from "libs/shared-models/src/lib/restaurant/restaurant-response";
import {RestaurantInfoState} from "./data-store/restaurant-info.state.";
import {RestaurantMenuService} from "../restaurant-menu/restaurant-menu.service";
import { LocaleService } from 'libs/shared-services/src/lib/locale.service';
import { CustomBusinessHours } from '../../models/custom-business-hours';
import { BusinessHoursCheck, BusinessHoursHelper } from 'libs/shared-models/src/lib/utils/business-hours-helper';
import { BusinessHours } from 'libs/shared-models/src/lib/restaurant/business-hours';
import { AddressResponse } from 'libs/shared-models/src/lib/address-response';
import { CustomUpload } from '../../models/custom-upload';
import { BaseLoadingService } from 'libs/shared-services/src/lib/base-loading';

@Injectable({
    providedIn: 'root',
})
export class RestaurantInfoService extends BaseLoadingService {

    constructor(
        @Inject('env') private environment: any,
        private apiService: ApiBaseService,
        private toasterService: ToasterService,
        private restaurantInfoState: RestaurantInfoState,
        private restaurantMenuService: RestaurantMenuService,
        private localeService: LocaleService
    ) {
        super();
    }

    /*
        App load restaurant API
    */
    public fetchRestaurantAPI() {
        this.apiService.get(environment.API_RESTAURANT_INFO).subscribe({
            next: (res: any) => {
                if (!!res) { // make sure it's not 204 No Content
                    this.setState(res);
                }
                this.setFinished();
            },
            error: (err) => {
                if (err?.error?.code == "not_found") {

                } else {
                    this.toasterService.showError("Error", err?.error?.message);
                }
            }
        });
    }

    /*
        Upsert to API the entire restaurant
    */
    public updateRestaurantInfoAPI(data: RestaurantResponse) {
        if (!!data.id) {
            // Update
            this.apiService.patch(environment.API_RESTAURANT_INFO, data).subscribe({
                next: (res: any) => {
                    this.setState(res);
                    this.toasterService.showSuccess("",this.localeService.translate("restaurant_info_update_success"));
                },
                error: (err) => {
                    this.toasterService.showError("Error", err?.error?.message);
                }
            });
        } else {
            // Create
            this.apiService.post(environment.API_RESTAURANT_CREATE, data).subscribe({
                next: (res: any) => {
                    let updatedRestaurant = Object.assign(new RestaurantResponse(), res);
                    this.setState(updatedRestaurant);
                    this.toasterService.showSuccess("", this.localeService.translate("restaurant_info_update_success"));
                    this.restaurantMenuService.fetchMenuAPI();
                },
                error: (err) => {
                    this.toasterService.showError("Error", err?.error?.message);
                }
            });
        }

    }

    public getState(): RestaurantResponse {
        return this.restaurantInfoState.getData();
    }

    public getState$(): Observable<RestaurantResponse> {
        return this.restaurantInfoState.getData$();
    }

    private setState(value: RestaurantResponse): void {
        return this.restaurantInfoState.setData(value);
    }

    /*
        Set availability status (open / closed)
    */
    public setRestaurantClosed(value: boolean) {
        this.apiService.patch(environment.API_RESTAURANT_INFO, {
            id: this.restaurantInfoState.getData().id,
            closed: value
        }).subscribe({
            next: (res: any) => {
                this.setState(res);
                this.toasterService.showInfo("",this.localeService.translate( value ? "restaurant_closed_successful" : "restaurant_opened_successful"));
            },
            error: (err) => {
                this.toasterService.showError("Error", err?.error?.message);
            }
        });
    }

    /*
        Update opening hours
    */
    public updateBusinessHours$(newData: CustomBusinessHours): Observable<boolean> {

        // get current ones
        const pickupHours = Object.assign(new BusinessHours(), JSON.parse(JSON.stringify(this.restaurantInfoState.getData().businessHoursPickup)));
        const deliveryHours = Object.assign(new BusinessHours(), JSON.parse(JSON.stringify(this.restaurantInfoState.getData().businessHoursDelivery)));
        
        // override with the new popup ones
        pickupHours[newData.day] = newData.businessHoursPickup;
        deliveryHours[newData.day] = newData.businessHoursDelivery;

        // send data to API
        return this.apiService.patch(environment.API_RESTAURANT_INFO, {
            id: this.restaurantInfoState.getData().id,
            // A hack to overcome the empty list [] vs null in API/Java:
            // TODO: fix this ugly code in the future
            businessHoursPickup: {
                mon: pickupHours.mon?.length > 0 ? pickupHours.mon : null,
                tue: pickupHours.tue?.length > 0 ? pickupHours.tue : null,
                wed: pickupHours.wed?.length > 0 ? pickupHours.wed : null,
                thu: pickupHours.thu?.length > 0 ? pickupHours.thu : null,
                fri: pickupHours.mon?.length > 0 ? pickupHours.fri : null,
                sat: pickupHours.mon?.length > 0 ? pickupHours.sat : null,
                sun: pickupHours.mon?.length > 0 ? pickupHours.sun : null
            },
            businessHoursDelivery: {
                mon: deliveryHours.mon?.length > 0 ? deliveryHours.mon : null,
                tue: deliveryHours.tue?.length > 0 ? deliveryHours.tue : null,
                wed: deliveryHours.wed?.length > 0 ? deliveryHours.wed : null,
                thu: deliveryHours.thu?.length > 0 ? deliveryHours.thu : null,
                fri: deliveryHours.mon?.length > 0 ? deliveryHours.fri : null,
                sat: deliveryHours.mon?.length > 0 ? deliveryHours.sat : null,
                sun: deliveryHours.mon?.length > 0 ? deliveryHours.sun : null
            }
        }).pipe(
            map((res: any) => {
                this.setState(res);
                this.toasterService.showSuccess("", this.localeService.translate("restaurant_info_hours_update_success"));
                return of(true);
            }),
            catchError((err: any, caught: Observable<any>): Observable<any> => {
                this.toasterService.showError("Error", err?.error?.message);
                return of(false);
            })
        );

    }

    /*
        Update Restaurant Address
    */
    public updateRestaurantAddress$(newAddress: AddressResponse): Observable<boolean> {
        // send data to API
        return this.apiService.patch(environment.API_RESTAURANT_INFO, {
            id: this.restaurantInfoState.getData().id,
            address: newAddress
        }).pipe(
            map((res: any) => {
                this.setState(res);
                this.toasterService.showSuccess("", this.localeService.translate("restaurant_info_address_update_success"));
                return of(true);
            }),
            catchError((err: any, caught: Observable<any>): Observable<any> => {
                this.toasterService.showError("Error", err?.error?.message);
                return of(false);
            })
        );
    }

    /*
        Update Invoice Restaurant Address
    */
        public updateRestaurantInvoiceAddress$(newAddress: AddressResponse): Observable<boolean> {
            // send data to API
            return this.apiService.patch(environment.API_RESTAURANT_INFO, {
                id: this.restaurantInfoState.getData().id,
                invoiceAddress: newAddress
            }).pipe(
                map((res: any) => {
                    this.setState(res);
                    this.toasterService.showSuccess("", this.localeService.translate("restaurant_info_invoice_address_update_success"));
                    return of(true);
                }),
                catchError((err: any, caught: Observable<any>): Observable<any> => {
                    this.toasterService.showError("Error", err?.error?.message);
                    return of(false);
                })
            );
        }

    /*
        Update Background image
    */

    public updateProfileImage$(data: CustomUpload): Observable<boolean> {
        // send data to API
        return this.apiService.patch(environment.API_RESTAURANT_INFO, {
            id: this.restaurantInfoState.getData().id,
            mainImageBlobId: data.imageData.id
        }).pipe(
            map((res: any) => {
                this.setState(res);
                this.toasterService.showSuccess("", this.localeService.translate("restaurant_info_image_update_success"));
                return of(true);
            }),
            catchError((err: any, caught: Observable<any>): Observable<any> => {
                this.toasterService.showError("Error", err?.error?.message);
                return of(false);
            })
        );
    }

    /*
        Update Logo image
    */
    public updateLogoImage$(data: CustomUpload): Observable<boolean> {
        // send data to API
        return this.apiService.patch(environment.API_RESTAURANT_INFO, {
            id: this.restaurantInfoState.getData().id,
            logoImageBlobId: data.imageData.id
        }).pipe(
            map((res: any) => {
                this.setState(res);
                this.toasterService.showSuccess("", this.localeService.translate("restaurant_info_image_update_success"));
                return of(true);
            }),
            catchError((err: any, caught: Observable<any>): Observable<any> => {
                this.toasterService.showError("Error", err?.error?.message);
                return of(false);
            })
        );
    }


    /*
        Update Verification documents
    */
    public updateVerificationDocuments$(data: CustomUpload): Observable<boolean> {

        let docs = [...this.restaurantInfoState.getData().documents];
        
        // We push all the time a new file - we don't let the user update the blobid for the existing id / already stored file
        const newDoc = new DocumentResponse();
        newDoc.blobId = data.imageData.id;
        newDoc.name = !!data.fileName ? data.fileName : data.imageData.usageType + "_" + data.imageData.id;
        docs.push(newDoc); 
        docs = docs.map((d) => {
            if (!d.id) {
                d.id = null;
            }
            return d;
        })

        // send data to API
        return this.apiService.patch(environment.API_RESTAURANT_INFO, {
            id: this.restaurantInfoState.getData().id,
            documents: docs
        }).pipe(
            map((res: any) => {
                this.setState(res);
                this.toasterService.showSuccess("", this.localeService.translate("restaurant_info_document_update_success"));
                return of(true);
            }),
            catchError((err: any, caught: Observable<any>): Observable<any> => {
                this.toasterService.showError("Error", err?.error?.message);
                return of(false);
            })
        );
    }


    /*
        Delete verification document
    */
    public deleteVerificationDocument(doc: DocumentResponse) {

        let docs = [...this.restaurantInfoState.getData().documents].filter((d) => d.id !== doc.id);

        // send data to API
        this.apiService.patch(environment.API_RESTAURANT_INFO, {
            id: this.restaurantInfoState.getData().id,
            documents: docs
        }).subscribe({
            next: (res: any) => {
                this.setState(res);
                this.toasterService.showSuccess("", this.localeService.translate("restaurant_info_document_delete_success"));
                return of(true);
            },
            error: (err) => {
                this.toasterService.showError("Error", err?.error?.message);
                return of(false);
            }
        });
    }

    /*
        After the restaurant was submitted for verification, we need to internally update the state
    */

    public setVerificationPending() {
        const data = this.getState();
        data.verificationStatus = RestaurantVerificationEnum.VERIFICATION_REQUESTED
        this.setState(data);
    }        
    
    // observable
    public checkIsActiveDuringHoursPickup$(): Observable<boolean> {
        return this.getState$().pipe(map((data) => {
            return BusinessHoursHelper.checkIsActiveDuringHoursPickup(this.createHoursCheck(data));
        }))
    }


    public checkIsActiveDuringHoursDelivery$(): Observable<boolean> {
        return this.getState$().pipe(map((data) => {
            return BusinessHoursHelper.checkIsActiveDuringHoursDelivery(this.createHoursCheck(data));
        }))
    }   
    
    public checkIsActiveDuringHoursPickup(): boolean {
        return BusinessHoursHelper.checkIsActiveDuringHoursPickup(this.createHoursCheck(this.getState()));
    }

    public checkIsActiveDuringHoursDelivery(): boolean {
        return BusinessHoursHelper.checkIsActiveDuringHoursDelivery(this.createHoursCheck(this.getState()));
    }

    public isToday(day: string): boolean {
        return BusinessHoursHelper.isToday(day);
    }

    private createHoursCheck(data: RestaurantResponse): BusinessHoursCheck {
        const paramData: BusinessHoursCheck = {
            businessHoursPickup: data.businessHoursPickup,
            businessHoursDelivery: data.businessHoursDelivery,
            hasFoodisDelivery: data.hasFoodisDelivery,
            hasOwnDelivery: data.hasOwnDelivery,
            hasPickup: data.hasPickup,
            closed: data.closed
        }
        return paramData;
    }
}

