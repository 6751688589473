import {AddonRequest} from "./addon-request";

export class AddonGroupRequest {
    id: string = "";
    name: string = "";
    nameEn: string = "";
    minSelection: number = 0;
    maxSelection: number = 1;
    addonList: AddonRequest[] = [];
}
