<main-layout [selected]="'ORDERS'">
    <content>
        <!-- Orders -->
        <div *ngIf="false; else noOrders">

        </div>

        <!-- User has no orders -->
        <ng-template #noOrders>
                
            <div class="missing-orders-container">
                <!-- Image -->
                <div class="missing-orders-image">
                    <img [src]="'apps/restaurant/src/assets/no-orders-found.jpg'"/>
                </div>

                <!-- Texts -->
                <div class="missing-orders-text-container">
                    <!-- Title -->
                    <div class="missing-orders-title">
                        <!-- Image (mobile) -->
                        <div class="missing-orders-image-mobile">
                            <img [src]="'apps/restaurant/src/assets/no-orders-found.jpg'"/>
                        </div>
                        <div class="mr-2">{{ "orders_page_no_orders_title" |  translate }}</div>
                        <mat-icon>history</mat-icon>
                    </div>
                    <!-- Body -->
                    <div class="missing-orders-body">
                        {{ "orders_page_no_orders_body" |  translate }}
                    </div>
                    <!-- Button -->
                    <ng-container *ngIf="(isVerified$ | async) === false"> 
                        <div class="missing-orders-button">
                            <!-- Button - Check restaurants -->
                            <fs-button [text]="'orders_page_no_orders_cta_1' | translate"
                                    class="button-container"
                                    [type]="'raised'" [leadingIcon]="'assignment_ind'" (onPress)="onPressVerify()">
                            </fs-button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-template>
    </content>
</main-layout>

