import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SharedServicesInit } from 'libs/shared-services/src/lib/shared-services-init';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { RestaurantServicesInit } from './services/restaurant-services-init';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../environments/environment';

@Component({
  selector: 'foodis-restaurant-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'restaurant';

  public allLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public baseServicesLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public restaurantServicesLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public showRouterOutlet: boolean = false;
  public isStaging$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
      private router: Router,
      private sharedServicesInit: SharedServicesInit,
      private restaurantServicesInit: RestaurantServicesInit,
      private cdRef: ChangeDetectorRef
    ) {      
    }

    public ngOnInit() {
      
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.showRouterOutlet = event.url === '/login';
        }
      });

      // wait for base services to load first
      this.sharedServicesInit.allServicesLoaded$().subscribe((value) => {
        if (value) {
          this.baseServicesLoaded$.next(true);          
          this.loadRestaurantServices();
        }
      });   

      combineLatest([
          this.baseServicesLoaded$,
          this.restaurantServicesInit.allServicesLoaded$()
          ]).subscribe(
          ([baseFinished, restaurantServices]) => {
            if (restaurantServices) {
              this.restaurantServicesLoaded$.next(true);
            }
            if (baseFinished && restaurantServices) {
              this.allLoaded$.next(true);
              this.cdRef.detectChanges();
            }
          }
      );           

      this.isStaging$.next(environment.DEBUG_STAGING_BADGE);
    }

    private loadRestaurantServices() {
      this.restaurantServicesInit.initServices();
    }
}
