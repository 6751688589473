
export enum AllergenEnum {
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    F = "F",
    G = "G",
    H = "H",
    L = "L",
    M = "M",
    N = "N",
    O = "O",
    P = "P",
    R = "R"
}
