export class VerificationStateModel {

    private isVerificationNeeded: boolean = false;
    private stepsList: VerificationStep[] = defaultConfig;

    public getStepsList(): VerificationStep[] {
        return this.stepsList;
    }
    
    private setStepsList(list: VerificationStep[]) {
        this.stepsList = list;
    }
    

    /*
        Mandatory ones (main step category)
    */
    public isStepCompleted(name: string): boolean {    
        const step = this.getStepsList().find((step) => step.name === name);
        if (!!step) {
            return step.requirements.filter((level) => level.type === VerificationPriority.MANDATORY).every((level) => level.completed);
        }
        return false;
    }

    /*
        Mandatory ones (main step category)
    */
    public areMultipleStepsCompleted(steps: VerificationStepName[]): boolean {    
        return steps.every((stepName: VerificationStepName) => {
            const temp = this.getStepsList().find((temp) => temp.name === stepName);
            if (!!temp) {
                return temp.requirements.filter((level) => level.type === VerificationPriority.MANDATORY).every((level) => level.completed);
            }
            return false;
        })
    }


    /*
        All, including the optional ones
    */
    public isStepCompletedAll(name: string): boolean {
        const step = this.getStepsList().find((step) => step.name === name);
        if (!!step) {
            return step.requirements.every((level) => level.completed);
        }
        return false;
    }

    /*
        Specific sub level
    */
    public isSubLevelCompleted(name: string): boolean {
        const step = this.getStepsList().find((step) => step.requirements.find((level) => level.name === name));
        if (!!step) {
            const subLevel = step.requirements.find((level) => level.name === name);
            return subLevel?.completed || false;
        }
        return false;
    }

    public needsVerification(): boolean {
        return this.isVerificationNeeded;
    }

    public setNeedsVerification(b: boolean) {
        this.isVerificationNeeded = b;
    }

    public completeSubLevel(name: string, value: boolean) {
        const step = this.getStepsList().find((step) => step.requirements.find((level) => level.name === name));
        if (!!step) {
            const subLevel = step.requirements.find((level) => level.name === name);
            if (subLevel) {
                subLevel.completed = value;
                const list = [...this.getStepsList()].map((step) => {
                    step.requirements = step.requirements.map((level) => level.name === subLevel.name ? subLevel : level);
                    return step;
                });
                this.setStepsList(list);
            }
        }
        return false;
    }
}

export enum VerificationStepName {
    CREATE_RESTAURANT = "CREATE_RESTAURANT",
    ADD_MENU = "ADD_MENU",
    OPENING_HOURS = "OPENING_HOURS",
    DELIVERY_OPTIONS = "DELIVERY_OPTIONS",
    UPLOAD_DOCUMENTS = "UPLOAD_DOCUMENTS",
    BANK_DETAILS = "BANK_DETAILS",
    SUBMIT_VERIFICATION = "SUBMIT_VERIFICATION",
    OPEN_CUSTOMERS = "OPEN_CUSTOMERS",
    NOT_SET = "NOT_SET",
}

export enum VerificationSubLevel {
    // create restaurant
    RESTAURANT_CREATED  = "verify_restaurant_created",
    ADDRESS_ADDED       = "verify_address_added",
    LOGO_ADDED          = "verify_logo_added",
    DESCRIPTION_ADDED   = "verify_profile_description",
    CUISINE_LIST        = "verify_cuisine_list",
    
    // add menu
    CATEGORY_ADDED      = "verify_category_added",
    MIN_PRODUCTS        = "verify_min_products",
    ADDON_GROUP_CREATED = "verify_addon_group_created",
    ADDONS_CREATED      = "verify_addons_created",

    // opening hours
    PICKUP_HOURS        = "verify_pickup_hours",
    DELIVERY_HOURS      = "verify_delivery_hours",
    
    // delivery
    DELIVERY_TYPE       = "verify_selected_delivery_type",
    DELIVERY_TIME       = "verify_delivery_time",
    DELIVERY_RANGE      = "verify_delivery_range",

    // documents
    DOCUMENTS_UPLOADED  = "verify_documents_uploaded",
    VAT_NUMBER          = "verify_vat_number",
    EMEREGENCY_PHONE    = "verify_emergency_phone",

    // bank
    IBAN                = "verify_account_iban",
    INVOICE_ADDRESS     = "verify_invoice_address",

    SUBMITTED           = "verify_submitted",

    OPENED_ONCE         = "verify_opened_once",
}

export enum VerificationPriority {
    MANDATORY = "MANDATORY",
    OPTIONAL = "OPTIONAL"
}

export interface VerificationStepRequirement {
    name: string,
    type: VerificationPriority,
    completed: boolean
}

export interface VerificationStep {
    name: VerificationStepName,    
    lokaKey: string,
    icon: string,
    orderNumber: string,
    urlPath: string,
    requirements: VerificationStepRequirement[]
}

const defaultConfig: VerificationStep[] = [
    {
        name: VerificationStepName.CREATE_RESTAURANT, 
        lokaKey: "card_button_assign_restaurant",
        icon: "card-assign-restaurant",
        urlPath: "/settings",
        orderNumber: "1",
        requirements: [
            {
                name: VerificationSubLevel.RESTAURANT_CREATED,                
                type: VerificationPriority.MANDATORY,
                completed: false,
            },
            {
                name: VerificationSubLevel.ADDRESS_ADDED,                
                type: VerificationPriority.MANDATORY,
                completed: false,
            },
            {
                name: VerificationSubLevel.LOGO_ADDED,                
                type: VerificationPriority.MANDATORY,
                completed: false,
            },
            {
                name: VerificationSubLevel.CUISINE_LIST,                
                type: VerificationPriority.MANDATORY,
                completed: false,
            },
            {
                name: VerificationSubLevel.DESCRIPTION_ADDED,                
                type: VerificationPriority.OPTIONAL,
                completed: false,
            }
        ]
    },
    {
        name: VerificationStepName.ADD_MENU,       
        lokaKey: "card_button_add_menu",
        icon: "card-add-menu",
        urlPath: "/menu",
        orderNumber: "2", 
        requirements: [
            {
                name: VerificationSubLevel.CATEGORY_ADDED,                
                type: VerificationPriority.MANDATORY,
                completed: false,
            },
            {
                name: VerificationSubLevel.MIN_PRODUCTS,                
                type: VerificationPriority.MANDATORY,
                completed: false,
            },
            {
                name: VerificationSubLevel.ADDON_GROUP_CREATED,                
                type: VerificationPriority.OPTIONAL,
                completed: false,
            },
            {
                name: VerificationSubLevel.ADDONS_CREATED,                
                type: VerificationPriority.OPTIONAL,
                completed: false,
            }
        ]
    },
    {
        name: VerificationStepName.OPENING_HOURS,     
        lokaKey: "card_button_hours",
        icon: "res-info-opening-hours-selected",
        urlPath: "/settings/opening-hours",
        orderNumber: "3",    
        requirements: [
            {
                name: VerificationSubLevel.PICKUP_HOURS,                
                type: VerificationPriority.MANDATORY,
                completed: false,
            },
            {
                name: VerificationSubLevel.DELIVERY_HOURS,                
                type: VerificationPriority.MANDATORY,
                completed: false,
            }
        ]
    },
    {
        name: VerificationStepName.DELIVERY_OPTIONS,    
        lokaKey: "card_button_delivery",
        icon: "delivery-type-bicycle-selected",
        urlPath: "/settings/delivery",
        orderNumber: "4",       
        requirements: [
            {
                name: VerificationSubLevel.DELIVERY_TYPE,                
                type: VerificationPriority.MANDATORY,
                completed: false,
            },
            {
                name: VerificationSubLevel.DELIVERY_TIME,                
                type: VerificationPriority.MANDATORY,
                completed: false,
            },
            {
                name: VerificationSubLevel.DELIVERY_RANGE,                
                type: VerificationPriority.MANDATORY,
                completed: false,                
            }
        ]
    },
    {
        name: VerificationStepName.UPLOAD_DOCUMENTS,    
        lokaKey: "card_button_verify_documents",
        icon: "card-verify-documents",
        urlPath: "/settings/private",
        orderNumber: "5",        
        requirements: [
            {
                name: VerificationSubLevel.DOCUMENTS_UPLOADED,                
                type: VerificationPriority.MANDATORY,
                completed: false,                
            },
            {
                name: VerificationSubLevel.VAT_NUMBER,                
                type: VerificationPriority.MANDATORY,
                completed: false,
            },
            {
                name: VerificationSubLevel.EMEREGENCY_PHONE,                
                type: VerificationPriority.OPTIONAL,
                completed: false,
            }
        ]
    },
    {
        name: VerificationStepName.BANK_DETAILS,     
        lokaKey: "card_button_bank",
        icon: "bank-details",
        urlPath: "/settings/private",
        orderNumber: "6",      
        requirements: [
            {
                name: VerificationSubLevel.IBAN,                
                type: VerificationPriority.MANDATORY,
                completed: false,                
            },
            {
                name: VerificationSubLevel.INVOICE_ADDRESS,                
                type: VerificationPriority.MANDATORY,
                completed: false,                
            }
        ]
    },
    {
        name: VerificationStepName.SUBMIT_VERIFICATION,  
        lokaKey: "card_button_submit",
        icon: "submit-verification",
        urlPath: "/dashboard/submit-verification",
        orderNumber: "7",         
        requirements: [
            {
                name: VerificationSubLevel.SUBMITTED,                                
                type: VerificationPriority.MANDATORY,
                completed: false,
            }
        ]
    },
    {
        name: VerificationStepName.OPEN_CUSTOMERS,       
        lokaKey: "card_button_open",
        icon: "open-restaurant",
        urlPath: "/",
        orderNumber: "8",          
        requirements: [
            {
                name: VerificationSubLevel.OPENED_ONCE,                
                type: VerificationPriority.MANDATORY,
                completed: false,
            }
        ]
    }
]
