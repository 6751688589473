import { Component, OnInit } from '@angular/core';
import { SelfDataService } from '../../../services/self/self.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserSelfResponse } from '../../../models/user-response';
import { RestaurantAccounts } from './service/restaurant-accounts.service';
import { InvitationListResponse } from './models/invitation-response';
import { RestaurantTeamUsersResponse } from './models/user-response';
import { ModalService } from 'libs/shared-ui/src/lib/modal-service/modal-service.service';
import { LocaleTranslatePipe } from 'libs/shared-services/src/lib/locale-pipe';
import { LocaleService } from 'libs/shared-services/src/lib/locale.service';

@Component({
  selector: 'web-foodis-section-accounts',
  templateUrl: './section-accounts.component.html',
  styleUrls: ['./section-accounts.component.scss'],
})
export class SectionAccountsComponent implements OnInit {

  // Self (email) data
  public selfData$: Observable<UserSelfResponse> = this.selfDataService.getSelfData$();

  // Invitations list
  public invitations$: Observable<InvitationListResponse> = this.accountsService.getInvitations$();

  public showInvitationInput$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private kitchenInputInviteEmail: string = "";


  // Users list
  public usersList$: Observable<RestaurantTeamUsersResponse> = this.accountsService.getUsersList$();

  constructor(
    private selfDataService: SelfDataService,
    private accountsService: RestaurantAccounts,
    private modalService: ModalService,
    private localeService: LocaleService
  ) {

  }

  public ngOnInit() {
    this.accountsService.fetchInvitations();
    this.accountsService.fetchUsersListApi();
  }

  public addKitchenAccount() {
    this.showInvitationInput$.next(true);
  }

  public sendKitchenEmailInvite() {

    if (this.kitchenInputInviteEmail) {
      // do the api call      
      this.accountsService.requestInvitationApi$(this.kitchenInputInviteEmail).subscribe((result) => {
        if (result) {
          // hide the input
          this.showInvitationInput$.next(false);
        }        
      })
    }
    
  }
  
  public onEmailKitchenInputUpdate(value: any) {
    this.kitchenInputInviteEmail = value;
  }

  /*  
    Button press to remove (kitchen) User assigned to this restaurant
    A confirmation popup opens and asks if you're sure about it
  */
  public removeUserPress(userId: string) {

    const config = this.modalService.getDefaultDialogConfig();
    config.width = "500px";
    config.data = {
        title: new LocaleTranslatePipe(this.localeService).transform("accounts_popup_remove_user_title"),
        midContent: new LocaleTranslatePipe(this.localeService).transform("accounts_popup_remove_user_description"),
        leftButtonContent: new LocaleTranslatePipe(this.localeService).transform("accounts_popup_remove_user_yes"),
        rightButtonContent: new LocaleTranslatePipe(this.localeService).transform("accounts_popup_remove_user_no"),
        preselectedButton: "left"
    }

    this.modalService.openConfirmDialog$(config).subscribe((response) => {
        if (response) { // YES pressed in the confirm modal                
          this.accountsService.deleteUser$(userId).subscribe((result) => {      
          })
        }
    });
  }

  public removeInvitation(userId: string) {  
    const config = this.modalService.getDefaultDialogConfig();
    config.width = "500px";
    config.data = {
        title: new LocaleTranslatePipe(this.localeService).transform("accounts_popup_remove_invitation_title"),
        midContent: new LocaleTranslatePipe(this.localeService).transform("accounts_popup_remove_invitation_description"),
        leftButtonContent: new LocaleTranslatePipe(this.localeService).transform("accounts_popup_remove_invitation_yes"),
        rightButtonContent: new LocaleTranslatePipe(this.localeService).transform("accounts_popup_remove_invitation_no"),
        preselectedButton: "left"
    }

    this.modalService.openConfirmDialog$(config).subscribe((response) => {
        if (response) { // YES pressed in the confirm modal                
          this.accountsService.deleteInvitation$(userId).subscribe((result) => {      
          })      
        }
    });
  }

}
