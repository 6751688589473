import {Component, Input} from '@angular/core';

@Component({
  selector: 'card-button',
  templateUrl: './card-button.component.html',
  styleUrls: ['./card-button.component.scss'],
})
export class CardButtonComponent {
    @Input() public key: string = "";
    @Input() public icon: string = "";
    @Input() public numberLabel: string = "";
    @Input() public completed: boolean = false;
}
