
<!-- Staging badge -->
<ng-container *ngIf="isStaging$ | async">
    <fs-debug-badge></fs-debug-badge>
</ng-container>

<ng-container *ngIf="allLoaded$ | async; else loadingScreen">
    <router-outlet></router-outlet>
</ng-container>

<ng-template #loadingScreen>
    <!-- Loading -->
     <ng-container *ngIf="showRouterOutlet">
        <router-outlet></router-outlet>
     </ng-container>
</ng-template>

