import {Inject, Injectable} from '@angular/core';
import {ApiBaseService} from "libs/shared-services/src/lib/api-base.service";
import {ToasterService} from "libs/shared-services/src/lib/toaster.service";
import {environment} from "../../../environments/environment";
import {BehaviorSubject, catchError, combineLatest, forkJoin, map, Observable, of} from "rxjs";
import {RestaurantMenuState} from "./data-store/restaurant-menu.state.";
import {MenuListResponse} from "./data-store/model/menu-list-response";
import {MenuCategoryListResponse} from "./data-store/model/menu-category-list-response";
import {ProductListGroupedByCategoryResponse} from "libs/shared-models/src/lib/restaurant/product-list-grouped-by-category-response";
import {MenuCategoryResponse} from "./data-store/model/menu-category-response";
import {ProductResponse} from "./data-store/model/product-response";
import {ProductSlimInfo} from "libs/shared-models/src/lib/restaurant/product-slim-info";
import {AddonGroupListResponse} from "./data-store/model/addon-group-list-response";
import {AddonGroupResponse} from "libs/shared-models/src/lib/restaurant/addon-group-response";
import {AddonLocalResponse} from "./data-store/model/addon-local-response";
import {AddonResponse} from "libs/shared-models/src/lib/restaurant/addon-response";
import {AddonGroupRequest} from "./data-store/model/addon-group-request";
import {AddonRequest} from "./data-store/model/addon-request";
import {LocaleService} from "libs/shared-services/src/lib/locale.service";
import {CategoryLocalResponse} from "./data-store/model/category-local-response";
import { BlobResponse, CustomUpload, UploadFileAccept, UploadFileState } from '../../models/custom-upload';
import { HttpHeaders } from '@angular/common/http';
import { BaseLoadingService } from 'libs/shared-services/src/lib/base-loading';

@Injectable({
    providedIn: 'root',
})
export class RestaurantMenuService extends BaseLoadingService {

    private loadedMenuApi$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private loadedCategoriesApi$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private loadedProductGroupApi$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private loadedAddonsListApi$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        @Inject('env') private environment: any,
        private apiService: ApiBaseService,
        private toasterService: ToasterService,
        private restaurantMenuState: RestaurantMenuState,
        private localeService: LocaleService
    ) {
      super();
      this.listenInitApiFetch();
    }

    private listenInitApiFetch() {
      combineLatest([
        this.loadedMenuApi$,
        this.loadedCategoriesApi$,
        this.loadedProductGroupApi$,
        this.loadedAddonsListApi$
      ]).subscribe(
        ([menu, categories, productGroup, addonsList]) => {

            // if already loaded before and some service emits again, ignore it
            if (this.isFinished()) {
                return; 
            }

            // make sure all are loaded
            if (menu && categories && productGroup && addonsList) {
                this.setFinished();
            }            
        }
    );
    }

    // API communication:

    /*
        Get the current Menu assigned to the account from API
     */
    public fetchMenuAPI() {
        // GET
        this.apiService.get(environment.API_GET_MENU).subscribe({
            next: (res: any) => {
                if (!!res && !!res?.menuList && res.menuList.length > 0) { // make sure it's not 204 No Content
                    this.setMenuListState(res);
                }
                this.loadedMenuApi$.next(true);
            },
            error: (err) => {
                this.toasterService.showError("Error", err?.error?.message);
            }
        });
    }

    /*
        Get all the Categories list from API (but without any products details)
     */
    public fetchCategoriesAPI() {
        // GET
        this.apiService.get(environment.API_GET_MENU_CATEGORY_LIST).subscribe({
            next: (res: any) => {
                if (!!res && !!res?.menuCategoryList && res.menuCategoryList.length > 0) { // make sure it's not 204 No Content
                    this.setCategoryListState(res);
                }
                this.loadedCategoriesApi$.next(true);              
            },
            error: (err) => {
                this.toasterService.showError("Error", err?.error?.message);
            }
        });
    }

    /*
        Get all the (Slim) Products list grouped in categories from API
     */
    public fetchProductsGroupedAPI() {
        // GET
        this.apiService.get(environment.API_GET_MENU_PRODUCTS_LIST).subscribe({
            next: (res: any) => {
                if (!!res && !!res?.categories && res.categories.length > 0) { // make sure it's not 204 No Content
                    this.setProductsByCategoryListState(res);
                }
                this.loadedProductGroupApi$.next(true);
            },
            error: (err) => {
                this.toasterService.showError("Error", err?.error?.message);
            }
        });
    }

    /*
       Add a new Category to API (created from UI)
    */
    public addNewCategoryAPI$(data: MenuCategoryResponse): Observable<boolean> {
        // POST
        let apiCall = this.apiService.post(environment.API_GET_MENU_CATEGORY_LIST, data);
        return apiCall.pipe(
            map((res) => {
                let resCategory = Object.assign(new MenuCategoryResponse(), res);
                this.addCategory(resCategory);
                this.toasterService.showSuccess("", this.localeService.translate("menu_toaster_success_category_add"));
                return true;
            }),
            catchError((err: any, caught: Observable<any>): Observable<any> => {
                this.toasterService.showError("Error", err?.error?.message);
                return of(false);
            })
        );
    }






    /*
       Add a new Product / Update an existing Product to API
    */
    public upsertProductAPI$(data: ProductResponse): Observable<boolean> {
        // We identify by "id" if it was already created in the past or not.
        if (!!data.id) {
            // PATCH
            return this.patchProductAPI$(data);
        } else {
            // POST (new)
            return this.addNewProductAPI$(data);
        }
    }

    /*
        Add new Product to API (it is created from UI for the first time)
     */
    public addNewProductAPI$(data: ProductResponse): Observable<boolean> {
        // POST
        let apiCall = this.apiService.post(environment.API_GET_MENU_PRODUCTS_LIST, data);
        return apiCall.pipe(
            map((res) => {
                let resProduct = Object.assign(new ProductSlimInfo(), res);
                this.restaurantMenuState.addSlimProductToCategory(data.menuCategoryId, resProduct);
                this.toasterService.showSuccess("", this.localeService.translate("menu_toaster_success_product_add"));
                return true;
            }),
            catchError((err: any, caught: Observable<any>): Observable<any> => {
                this.toasterService.showError("Error", err?.error?.message);
                return of(false);
            })
        );
    }

    /*
        Update an existing Product to API -
     */
    public patchProductAPI$(data: ProductResponse): Observable<boolean> {
        // PATCH
        let apiCall = this.apiService.patch(environment.API_GET_MENU_PRODUCTS_LIST + "/" + data.id, data);
        return apiCall.pipe(
            map((res) => {
                let resProduct = Object.assign(new ProductSlimInfo(), res);
                this.restaurantMenuState.updateSlimProductToCategory(data.menuCategoryId, resProduct);
                this.toasterService.showSuccess("", this.localeService.translate("menu_toaster_success_product_update"));
                return true;
            }),
            catchError((err: any, caught: Observable<any>): Observable<any> => {
                this.toasterService.showError("Error", err?.error?.message);
                return of(false);
            })
        );
    }

    /*
        Delete an existing Product to API
     */
    public deleteProductAPI$(data: ProductResponse): Observable<boolean> {
        // DELETE
        let apiCall = this.apiService.delete(environment.API_GET_MENU_PRODUCTS_LIST + "/" + data.id);
        return apiCall.pipe(
            map((res) => {
                this.restaurantMenuState.deleteSlimProductToCategory(data.menuCategoryId, data);
                this.toasterService.showSuccess("", this.localeService.translate("menu_toaster_success_product_delete"));
                return true;
            }),
            catchError((err: any, caught: Observable<any>): Observable<any> => {
                this.toasterService.showError("Error", err?.error?.message);
                return of(false);
            })
        );
    }

    /*
        Get all the details about a Product from API (contains All attributes, not only the Slim Product)
     */
    public fetchFullProductAPI$(productId: string): Observable<ProductResponse | null> {
        // GET
        let apiCall = this.apiService.get(environment.API_GET_MENU_PRODUCTS_LIST + "/" + productId);
        return apiCall.pipe(
            map((res) => {
                let resProduct = Object.assign(new ProductResponse(), res);
                return resProduct;
            }),
            catchError((err: any, caught: Observable<any>): Observable<any> => {
                this.toasterService.showError("Error", err?.error?.message);
                return of(null);
            })
        );
    }

  /*
      Get all the Addons from API
   */

  public fetchAddonsListAPI() {
    // GET
    this.apiService.get(environment.API_GET_MENU_ADDON_LIST).subscribe({
      next: (res: any) => {
        if (!!res && !!res?.addonGroupList && res.addonGroupList.length > 0) { // make sure it's not 204 No Content
          const resAddons = Object.assign(new AddonGroupListResponse(), res);
          this.setAddonsGroupListState(resAddons);
        }
        this.loadedAddonsListApi$.next(true);
      },
      error: (err) => {
        this.toasterService.showError("Error", err?.error?.message);
      }
    });
  }

    // End API communication


    /*
        Menu list
     */
    public getMenuListState$(): Observable<MenuListResponse> {
        return this.restaurantMenuState.getMenuList$();
    }

    public getMenuListState(): MenuListResponse {
        return this.restaurantMenuState.getMenuList();
    }

    private setMenuListState(value: MenuListResponse): void {
        return this.restaurantMenuState.setMenuList(value);
    }

    /*
        Category list
    */
    public getCategoryListState$(): Observable<MenuCategoryListResponse> {
        return this.restaurantMenuState.getCategoryList$();
    }

    public getCategoryListState(): MenuCategoryListResponse {
        return this.restaurantMenuState.getCategoryList();
    }

    private setCategoryListState(value: MenuCategoryListResponse): void {
        return this.restaurantMenuState.setCategoryList(value);
    }

    /*
        Products grouped by categories List
     */
    public getProductsByCategoryListState$(): Observable<ProductListGroupedByCategoryResponse> {
        return this.restaurantMenuState.getProductsByCategoryList$();
    }

    public getProductsByCategoryListState(): ProductListGroupedByCategoryResponse {
        return this.restaurantMenuState.getProductsByCategoryList();
    }

    private setProductsByCategoryListState(value: ProductListGroupedByCategoryResponse): void {
        return this.restaurantMenuState.setProductsByCategoryList(value);
    }

    /*
        Category functionality
    */
    public addCategory(value: MenuCategoryResponse) {
        this.restaurantMenuState.addCategory(value);
    }

    /*
        Delete an existing product Category
     */
    public deleteCategoryAPI$(data: CategoryLocalResponse): Observable<boolean> {
      // DELETE
      const apiCall = this.apiService.delete(environment.API_GET_MENU_CATEGORY_LIST + "/" + data.category.id);
      return apiCall.pipe(
        map((res) => {
          this.restaurantMenuState.deleteCategory(data.category);
          this.toasterService.showSuccess("", this.localeService.translate("menu_toaster_success_category_delete"));
          return true;
        }),
        catchError((err: any, caught: Observable<any>): Observable<any> => {
          this.toasterService.showError("Error", err?.error?.message);
          return of(false);
        })
      );
    }

    public updateCategoryAPI$(data: CategoryLocalResponse): Observable<boolean> {
      // PATCH
      const apiCall = this.apiService.put(environment.API_GET_MENU_CATEGORY_LIST + "/" + data.category.id, data.category);
      return apiCall.pipe(
        map((res) => {
          let resCategory = Object.assign(new MenuCategoryResponse(), res);
          this.restaurantMenuState.updateCategory(resCategory);
          this.toasterService.showSuccess("", this.localeService.translate("menu_toaster_success_category_update"));
          return true;
        }),
        catchError((err: any, caught: Observable<any>): Observable<any> => {
          this.toasterService.showError("Error", err?.error?.message);
          return of(false);
        })
      );
    }








    /*
        Addons Groups / Add-ons list
    */
    public getAddonsGroupListState$(): Observable<AddonGroupListResponse> {
      return this.restaurantMenuState.getAddonsGroupList$();
    }

    public getAddonsGroupListState(): AddonGroupListResponse {
      return this.restaurantMenuState.getAddonsGroupList();
    }

    private setAddonsGroupListState(value: AddonGroupListResponse): void {
      return this.restaurantMenuState.setAddonsGroupList(value);
    }

    public getAddonsGroupById(id: string): AddonGroupResponse | undefined {
      return this.restaurantMenuState.getAddonsGroupList().addonGroupList.find((group) => group.id === id);
    }

    /*
       Add a new Addon Group / Update an existing Addon Group to API
    */
    public upsertAddonGroupAPI$(data: AddonGroupResponse): Observable<boolean> {
      // We identify by "id" if it was already created in the past or not.
      if (!!data.id) {
        // PATCH
        return this.patchAddonGroupAPI$(data);
      } else {
        // POST (new)
        return this.addAddonGroupAPI$(data);
      }
    }


  /*
    Update an existing Addon Group to API -
 */
  public patchAddonGroupAPI$(data: AddonGroupResponse): Observable<boolean> {
    // sanitizing data:
    const requestData = this.clearEmptyAddonId(data);
    // PATCH
    const apiCall = this.apiService.patch(environment.API_GET_MENU_ADDON_LIST + "/" + requestData.id, requestData);
    return apiCall.pipe(
      map((res) => {
        const resAddonGroup = Object.assign(new AddonGroupResponse(), res);
        this.restaurantMenuState.updateAddonGroup(resAddonGroup);
        this.toasterService.showSuccess("", this.localeService.translate("menu_toaster_success_addon_group_update"));
        return true;
      }),
      catchError((err: any, caught: Observable<any>): Observable<any> => {
        this.toasterService.showError("Error", err?.error?.message);
        return of(false);
      })
    );
  }


  /*
      Add new Addon Group to API (it is created from UI for the first time)
   */
  public addAddonGroupAPI$(data: AddonGroupResponse): Observable<boolean> {
    // sanitizing data:
    const requestData = this.clearEmptyAddonId(data);
    // POST
    const apiCall = this.apiService.post(environment.API_GET_MENU_ADDON_LIST, requestData);
    return apiCall.pipe(
      map((res) => {
        const resAddonGroup = Object.assign(new AddonGroupResponse(), res);
        this.restaurantMenuState.addAddonGroup(resAddonGroup);
        this.toasterService.showSuccess("", this.localeService.translate("menu_toaster_success_addon_group_add"));
        return true;
      }),
      catchError((err: any, caught: Observable<any>): Observable<any> => {
        this.toasterService.showError("Error", err?.error?.message);
        return of(false);
      })
    );
  }


  /*
        Delete an existing Addon Group to API
     */
  public deleteAddonGroupAPI$(data: AddonGroupResponse): Observable<boolean> {
    // DELETE
    let apiCall = this.apiService.delete(environment.API_GET_MENU_ADDON_LIST + "/" + data.id);
    return apiCall.pipe(
      map((res) => {
        this.restaurantMenuState.deleteAddonGroup(data);
        this.toasterService.showSuccess("", this.localeService.translate("menu_toaster_success_addon_group_delete"));
        return true;
      }),
      catchError((err: any, caught: Observable<any>): Observable<any> => {
        this.toasterService.showError("Error", err?.error?.message);
        return of(false);
      })
    );
  }

  // Hack for API - as it doesn't accept empty ID string. So we need to remove the property from the model in case the id is empty before we send it to API
  private clearEmptyAddonId(group: AddonGroupResponse): AddonGroupRequest {
    const newObj: AddonGroupRequest = Object.assign(new AddonGroupRequest(), group);
    newObj.addonList = [...newObj.addonList].map((a) =>{
      const addon: AddonRequest = Object.assign(new AddonRequest(), a);
      if (!addon.id) {
        delete addon['id'];
      }
      return addon;
    });
    return newObj;
  }


  /*
      Individual addons
   */

  public upsertAddonAPI$(data: AddonLocalResponse): Observable<boolean> {
    // We identify by "id" if it was already created in the past or not.
    if (!!data.id) {
      // PATCH

      // find all the groups where the addon was already present
      const groupsContainAddon: AddonGroupResponse[] = this.restaurantMenuState.getAddonsGroupList().addonGroupList.filter((g) => !!g.addonList.find((a) => a.id === data.id));

      let groupsList = []; // this will be sent to API to update changes (with the only those groups which have changed)

      // Check if the addon exists in multiple groups (normally, not possible, but just a failsafe check)
      if (groupsContainAddon.length > 1) {
          // delete all of them
          groupsList = groupsContainAddon.map((g) => {
              const addonsList = [...g.addonList].filter((a) => a.id !== data.id);
              g.addonList = addonsList;
              return g;
          })

          // add it in the corresponding category
          const group = this.restaurantMenuState.getAddonsGroupList().addonGroupList.find((g) => g.id === data.addonGroupId);
          if (!!group) {
            group.addonList = [...group.addonList];
            group.addonList.push(data);
            groupsList.push(group);
          }
      } else {
        // Check if the group remained the same (addon in the same group as before - only attributes changed)
        const group = groupsContainAddon[0];
        if (group.id === data.addonGroupId) {
            let list = [...group.addonList];
            list = list.map((a) => a.id === data.id ? data : a);
            group.addonList = list;
            groupsList.push(group);
        } else {
          // means the addon group changed
          // delete it from the old one
          groupsList = groupsContainAddon.map((g) => {
            let list = [...group.addonList];
            list = list.filter((a) => a.id !== data.id);
            group.addonList = list;
            groupsList.push(group);
          })

          // add it to the new category
          const newGroup = this.restaurantMenuState.getAddonsGroupList().addonGroupList.find((g) => g.id === data.addonGroupId);
          if (!!newGroup) {
            newGroup.addonList = [...newGroup.addonList];
            newGroup.addonList.push(data);
            // @ts-ignore
            groupsList.push(newGroup);
          }
        }
      }

      // create the observables (api) for all groups which need changes
      const listObs = groupsList.map((g) => {
        if (!!g) {
          return this.patchAddonGroupAPI$(g);
        }
        return of(false);
      })

      // wait for all results and return true/false in case all succeeded or something failed
      const obs = forkJoin(listObs)
        .pipe(
          map((args) => {
            if (args.every((b) => !!b)) {
              return true;
            } else {
              return false;
            }
          })
        );
      return obs;

    } else {
      // POST (new)
      // We update the addon group (which contains the addon item) and not the addon item itself.  That's what we send to API
      const group = this.getAddonsGroupById(data.addonGroupId);
      if (!!group) {
          const list = [...group.addonList];
          const newData =   Object.assign(new AddonResponse(), data);
          list.push(newData);
          group.addonList = list;
          return this.patchAddonGroupAPI$(group);
      } else {
        return of(false);
      }
    }
  }

  public deleteAddonAPI$(data: AddonLocalResponse): Observable<boolean> {
    const group = this.getAddonsGroupById(data.addonGroupId);
    if (!!group) {
      let list = [...group.addonList];
      list = list.filter((a) => a.id !== data.id);
      group.addonList = list;
      return this.patchAddonGroupAPI$(group);
    } else {
      return of(false);
    }
  }


  public uploadFileAPI$(data: CustomUpload, state: UploadFileState, restaurantId: string): Observable<BlobResponse | boolean> {
      // POST
      const url = environment.API_UPLOAD_FILE + "?usageType=" + data.imageData.usageType + "&owningEntityId=" + restaurantId;
      let tempHeaders: HttpHeaders = new HttpHeaders();

      // some mapping (eg: image/jpg doesn't exist)
      let contentType = "";
      switch (state.file?.type) {
        case UploadFileAccept.JPG: 
        case UploadFileAccept.JPEG: 
          contentType = UploadFileAccept.JPEG;
          break;
        case UploadFileAccept.PNG:
          contentType = UploadFileAccept.PNG;
          break;
        case UploadFileAccept.PDF: 
          contentType = UploadFileAccept.PDF;
          break;
        case UploadFileAccept.WEBP: 
          contentType = UploadFileAccept.WEBP;
          break;
      }
      tempHeaders = tempHeaders.set("Content-Type", contentType);      

      const apiCall = this.apiService.post(url, state.file, { 
        headers: tempHeaders,
        // reportProgress: true
      });

      return apiCall.pipe(
          map((res) => {
              const blob: BlobResponse = Object.assign(new BlobResponse(), res);              
              this.toasterService.showSuccess("", this.localeService.translate("menu_upload_modal_image_upload_success"));
              return blob;
          }),
          catchError((err: any, caught: Observable<any>): Observable<any> => {
              this.toasterService.showError("Error", err?.error?.message);
              return of(false);
          })
      );
  }

}

