import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ModalService} from "libs/shared-ui/src/lib/modal-service/modal-service.service";
import {BehaviorSubject, map, Observable, of} from "rxjs";
import {LocaleService} from "libs/shared-services/src/lib/locale.service";
import { CustomBusinessHours } from 'apps/restaurant/src/app/models/custom-business-hours';
import { RestaurantInfoService } from 'apps/restaurant/src/app/services/restaurant-info/restaurant-info.service';
import { ToasterService } from 'libs/shared-services/src/lib/toaster.service';
import { BusinessHoursPeriod } from 'libs/shared-models/src/lib/restaurant/business-hours';
import { TimezonePipe } from 'libs/shared-services/src/lib/timezone.pipe';

@Component({
  selector: 'business-hours-modal',
  templateUrl: './business-hours-modal.component.html',
  styleUrls: ['./business-hours-modal.component.scss'],
})
export class BusinessHoursModalComponent implements OnInit {

    /*
        Local data
     */
    public tempData$: BehaviorSubject<CustomBusinessHours> = new BehaviorSubject<CustomBusinessHours>(new CustomBusinessHours());
    public pickupSliderValue$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public deliverySliderValue$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public pickupDropdownList$: BehaviorSubject<RestaurantAvailabilityHour> = new BehaviorSubject<RestaurantAvailabilityHour>(new RestaurantAvailabilityHour());
    public deliveryDropdownList$: BehaviorSubject<RestaurantAvailabilityHour> = new BehaviorSubject<RestaurantAvailabilityHour>(new RestaurantAvailabilityHour());
    

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: CustomBusinessHours,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<BusinessHoursModalComponent>,
        private modalService: ModalService,
        private restaurantInfoService: RestaurantInfoService,
        private localeService: LocaleService,
        private toasterService: ToasterService
    ) {
    }

    public ngOnInit() {

        // Assign the data received on Modal creation
        const newHours = JSON.parse(JSON.stringify(this.getDialogData()));
              
        this.tempData$.next(newHours);

        this.tempData$.subscribe((val) => {
            this.pickupSliderValue$.next(val.businessHoursPickup.length > 0);
            this.deliverySliderValue$.next(val.businessHoursDelivery.length > 0);
        })

        // Listen to UI modal backdrop:
        this.dialogRef.backdropClick().subscribe(() => {
            this.onCancel();
        });
    }

    private getData(): CustomBusinessHours {
        return this.tempData$.getValue();
    }

    private setData(val: CustomBusinessHours) {
        this.tempData$.next(val);
    }

    // The data added on Modal creation (injected in the dialog from parent call)
    private getDialogData(): CustomBusinessHours {
      return this.data;
    }

    // Popup cancel
    public onCancel() {
        const newHours = Object.assign(new CustomBusinessHours(), this.getData());

        const isSameContent: boolean = JSON.stringify(newHours) === JSON.stringify(this.getDialogData()); // compare to the initial one before the popup was opened

        if (!isSameContent) {
            // Notify the user that he'll lose the current changes
            this.modalService.openConfirmDialog$().subscribe((response) => {
                if (response) { // true
                    this.dialogRef.close();
                }
            });
        } else {
            this.dialogRef.close();
        }
    }


    public onPickupSlideToggle(value: any) {

        if (value.checked) {
            if (this.getDialogData()?.businessHoursPickup?.length > 0) {
                // reset to the initial one
                const newHours = Object.assign(new CustomBusinessHours(), this.getData()); // new instance
                newHours.businessHoursPickup = [...this.getDialogData().businessHoursPickup]; // new instance
                newHours.businessHoursDelivery = [...this.getData().businessHoursDelivery]; // new instance        
                this.setData(newHours);
            } else {
                // create new empty / default fields with dropdowns
                const newPeriod = new BusinessHoursPeriod();
                newPeriod.openTime = CUSTOM_HOUR_START;
                newPeriod.closeTime = CUSTOM_HOUR_END;
                const newPickupBusiness: BusinessHoursPeriod[] = [newPeriod];
                
                const newHours = Object.assign(new CustomBusinessHours(), this.getData()); // new instance
                newHours.businessHoursDelivery = [...this.getData().businessHoursDelivery]; // new instance
                newHours.businessHoursPickup = newPickupBusiness;

                this.setData(newHours);
            }

        } else {
            // reset it, make empty array
            const newHours = Object.assign(new CustomBusinessHours(), this.getData()); // new instance
            newHours.businessHoursPickup = [];
            this.setData(newHours);
        }        
    }

    public onDeliverySlideToggle(value: any) {

        if (value.checked) {
            if (this.getDialogData()?.businessHoursDelivery?.length > 0) {
                // reset to the initial one
                const newHours = Object.assign(new CustomBusinessHours(), this.getData()); // new instance
                newHours.businessHoursPickup = [...this.getData().businessHoursPickup]; // new instance
                newHours.businessHoursDelivery = [...this.getDialogData().businessHoursDelivery]; // new instance        
                this.setData(newHours);
            } else {
                // create new empty / default fields with dropdowns
                const newPeriod = new BusinessHoursPeriod();
                newPeriod.openTime = CUSTOM_HOUR_START;
                newPeriod.closeTime = CUSTOM_HOUR_END;
                const newDeliveryBusiness: BusinessHoursPeriod[] = [newPeriod];
                
                const newHours = Object.assign(new CustomBusinessHours(), this.getData()); // new instance
                newHours.businessHoursPickup = [...this.getData().businessHoursPickup]; // new instance
                newHours.businessHoursDelivery = newDeliveryBusiness;
                this.setData(newHours);
            }

        } else {
            // reset it, make empty array
            const newHours = Object.assign(new CustomBusinessHours(), this.getData()); // new instance
            newHours.businessHoursDelivery = [];
            this.setData(newHours);
        }    
    }


    public onHoursChange(selectedIndex: number, type: string, usage: string, businessHoursIndex: number) {

        // string value in hh:mm format
        const hour = this.pickupDropdownList$.getValue().options[selectedIndex];

        const newHours = Object.assign(new CustomBusinessHours(), this.getData()); // new instance   

        newHours.businessHoursPickup = [...this.getData().businessHoursPickup]; // new instance
        newHours.businessHoursDelivery = [...this.getData().businessHoursDelivery]; // new instance     

        if (type === 'pickup') {
            if (usage === 'start') {
                newHours.businessHoursPickup[businessHoursIndex].openTime = hour;
            } else if (usage === 'end') {
                newHours.businessHoursPickup[businessHoursIndex].closeTime = hour;
            }
        } else if (type === 'delivery') {
            if (usage === 'start') {
                newHours.businessHoursDelivery[businessHoursIndex].openTime = hour;
            } else if (usage === 'end') {
                newHours.businessHoursDelivery[businessHoursIndex].closeTime = hour;
            }
        }
        
        this.setData(newHours);
    }

    public onSaveClick() {

        // check pickup overlaps
        if (this.hasOverlap(this.getData().businessHoursPickup)) {
            this.toasterService.showWarning(this.localeService.translate("restaurant_info_hours_error_overlap_title"), this.localeService.translate("restaurant_info_hours_error_overlap_pickup"));
            return;
        }   
        
        // check delivery overlaps
        if (this.hasOverlap(this.getData().businessHoursDelivery)) { 
            this.toasterService.showWarning(this.localeService.translate("restaurant_info_hours_error_overlap_title"), this.localeService.translate("restaurant_info_hours_error_overlap_delivery"));
            return;
        }

        // Transform from restaurant timezone (currently displayed) to UTC (as it is stored both on BE / FE)
        const dataToConvert = JSON.parse(JSON.stringify(this.getData())); // make a copy to not alter popup's data
        dataToConvert.businessHoursPickup = this.convertTimezoneToUTC(dataToConvert.businessHoursPickup);
        dataToConvert.businessHoursDelivery = this.convertTimezoneToUTC(dataToConvert.businessHoursDelivery);

        this.restaurantInfoService.updateBusinessHours$(dataToConvert).subscribe((result) => {
            if (result) {
                this.dialogRef.close();
            }
        })
    }    

    // Do the inverse now. Convert from current popup data (Restaurant timezone) to UTC so it can be sent to local storage service / backend in UTC
    private convertTimezoneToUTC(hours: BusinessHoursPeriod[]): BusinessHoursPeriod[] {
        hours = hours.map((item: BusinessHoursPeriod) => {
            item.openTime = new TimezonePipe().reverseTransform(item.openTime, this.getData().timezone);
            item.closeTime = new TimezonePipe().reverseTransform(item.closeTime, this.getData().timezone);
            return item;
        });        
        return hours;
    }

    private hasOverlap(list: BusinessHoursPeriod[]): boolean {
        const hours = [...list];

        // skip all the checks
        if (hours.length === 0) {
            return false;
        }

        const times = hours.map(slot => {
            const start = this.convertTimeToMinutes(slot.openTime);
            let end = this.convertTimeToMinutes(slot.closeTime);
            // If the end time is less than the start time, the slot spans past midnight
            if (end <= start) {
              end += 24 * 60; // Adjust for crossing midnight by adding 24 hours in minutes
            }
            return { start, end };
          });
      
          // Sort by start time to simplify overlap detection
          times.sort((a, b) => a.start - b.start);
      
          for (let i = 0; i < times.length - 1; i++) {
            if (times[i].end > times[i + 1].start) {
              return true; // Overlap detected
            }
          }
      
          // Additionally, check if the last time slot overlaps with the first in the next day scenario
          if (times[times.length - 1].end > 24 * 60) {
            if (times[times.length - 1].end - 24 * 60 > times[0].start) {
              return true; // Overlap detected with the slot crossing to the next day
            }
          }
      
          return false; // No overlap found
      }
      
      private convertTimeToMinutes(time: string): number {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
      }


    public isButtonDisabled$(): Observable<boolean> {
        return this.tempData$.pipe(
            map(data => {

                const newHours = Object.assign(new CustomBusinessHours(), data);
                newHours.businessHoursPickup = [...data.businessHoursPickup]; // new instance
                newHours.businessHoursDelivery = [...data.businessHoursDelivery]; // new instance     

                return JSON.stringify(newHours) === JSON.stringify(this.getDialogData());
            })
        );
    }

    public onAddMorePickup() {   
        const newHours = Object.assign(new CustomBusinessHours(), this.getData());    
        newHours.businessHoursPickup.push(this.createEmptyHours());
        this.setData(newHours);

    }

    public onAddMoreDelivery() {
        const newHours = Object.assign(new CustomBusinessHours(), this.getData()); 
        newHours.businessHoursDelivery.push(this.createEmptyHours());
        this.setData(newHours);
    }

    private createEmptyHours(): BusinessHoursPeriod {
        const period = new BusinessHoursPeriod();
        period.openTime = CUSTOM_HOUR_START;
        period.closeTime = CUSTOM_HOUR_END;
        return period;
    }


    public onDeletePickup($event: any, index: number) {
        const newHours = Object.assign(new CustomBusinessHours(), this.getData()); // new instance
        newHours.businessHoursPickup = [...this.getData().businessHoursPickup]; // new instance
        newHours.businessHoursDelivery = [...this.getData().businessHoursDelivery]; // new instance
        newHours.businessHoursPickup.splice(index, 1);
        this.setData(newHours);
    }

    public onDeleteDelivery($event: any, index: number) {
        const newHours = Object.assign(new CustomBusinessHours(), this.getData()); // new instance
        newHours.businessHoursPickup = [...this.getData().businessHoursPickup]; // new instance
        newHours.businessHoursDelivery = [...this.getData().businessHoursDelivery]; // new instance
        newHours.businessHoursDelivery.splice(index, 1);
        this.setData(newHours);
    }


    public getHourImage(value: string) {
        return `apps/restaurant/src/assets/delivery-hour-${this.categorizeTime(value)}.svg`;
    }

    private categorizeTime(time: string): TimeCategory {
        const [hours, minutes] = time.split(':').map(Number); // Convert to numbers
        const totalMinutes = hours * 60 + minutes; // Convert time to minutes for easier comparison
      
        if (totalMinutes >= 0 && totalMinutes < 360) { // 00:00 - 05:59
          return TimeCategory.NIGHT;
        } else if (totalMinutes >= 360 && totalMinutes < 660) { // 06:00 - 10:59
          return TimeCategory.MORNING;
        } else if (totalMinutes >= 660 && totalMinutes < 1020) { // 11:00 - 16:59
          return TimeCategory.DAY;
        } else if (totalMinutes >= 1020 && totalMinutes < 1200) { // 16:00 - 19:59
          return TimeCategory.SUNSET;
        } else { // 20:00 - 23:59
          return TimeCategory.EVENING;
        }
      }

}



export const MIDNIGHT = "00:00";
export const CUSTOM_HOUR_START = "10:00";
export const CUSTOM_HOUR_END = "22:00";

// Used in the dropdowns
export class RestaurantAvailabilityHour {

  options: string[] = [];

  constructor() {
      this.populateHours();
  }

  private populateHours() {
      const hoursNr = 24;
      const minutes = ["00", "15", "30", "45"];

      for (let i = 0; i < hoursNr; i++) {
          const hour = i <= 9 ? "0" + i : i;
          minutes.forEach((m) => {
              let time = "" + hour + ":" + m;
              this.options.push(time);
          })
      }
      this.options.push(MIDNIGHT);
  }
}

enum TimeCategory {
    NIGHT = "night",
    MORNING = "morning",
    DAY = "day",
    SUNSET = "sunset",
    EVENING = "evening",
}