import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { FormatCentsToFloatPipe } from "libs/shared-services/src/lib/cents-to-float-pipe";
import { LocaleTranslatePipe } from "libs/shared-services/src/lib/locale-pipe";
import { LocaleService } from "libs/shared-services/src/lib/locale.service";
import { ModalService } from "libs/shared-ui/src/lib/modal-service/modal-service.service";
import { BehaviorSubject, Observable, combineLatest, map, of } from "rxjs";
import { AddonGroupResponse } from "libs/shared-models/src/lib/restaurant/addon-group-response";
import { MenuCategoryListResponse } from "../../../../../services/restaurant-menu/data-store/model/menu-category-list-response";
import { MenuCategoryResponse } from "../../../../../services/restaurant-menu/data-store/model/menu-category-response";
import { ProductResponse } from "../../../../../services/restaurant-menu/data-store/model/product-response";
import { RestaurantMenuService } from "../../../../../services/restaurant-menu/restaurant-menu.service";
import { UploadImageModal } from '../../../../general/upload-image-modal/upload-image-modal.component';
import { BlobResponse, BlobUsageTypeEnum, CustomUpload } from 'apps/restaurant/src/app/models/custom-upload';
import { RestaurantInfoService } from 'apps/restaurant/src/app/services/restaurant-info/restaurant-info.service';
import { RestaurantResponse } from "libs/shared-models/src/lib/restaurant/restaurant-response";
import { environment } from 'apps/restaurant/src/environments/environment';
import { AllergenEnum } from 'libs/shared-models/src/lib/restaurant/AllergenEnum';

@Component({
  selector: 'product-modal',
  templateUrl: './product-modal.component.html',
  styleUrls: ['./product-modal.component.scss'],
})
export class ProductModalComponent implements OnInit {

    public categoryList$: Observable<MenuCategoryListResponse> = this.menuService.getCategoryListState$();
    public tempData$: BehaviorSubject<ProductResponse> = new BehaviorSubject<ProductResponse>(new ProductResponse());
    private PERCENT_RECOMMENDED_DELIVERY_DIFFERENCE: number = 25; //%

    public allAddonGroups: Observable<AddonGroupResponse[]> = combineLatest([this.menuService.getAddonsGroupListState$(), this.getData$()]).pipe(map((args) => {
      return args[0].addonGroupList.filter((group) => !args[1].addonGroupIds.find((g) => g == group.id));
    }));

    public currentTab: BehaviorSubject<string> = new BehaviorSubject<string>('product-main-details');

    public externalAllergenURL = environment.URL_ALLERGEN_EXTERNAL;

    public isPriceDeliveryEdited$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public showWarningCalculation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public restaurantData$: Observable<RestaurantResponse> = this.restaurantInfoService.getState$();

    public isActiveDuringHours$: Observable<boolean> = this.restaurantInfoService.checkIsActiveDuringHoursPickup$();

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: ProductResponse,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<ProductModalComponent>,
        private modalService: ModalService,
        private menuService: RestaurantMenuService,
        private restaurantInfoService: RestaurantInfoService,
        private localeService: LocaleService
    ) {
    }

    public ngOnInit() {

        // Assign the data received on Modal creation

        let newProd = Object.assign(new ProductResponse(), this.data); // new instance
        newProd.allergenList = Object.assign([], this.data.allergenList); // new instance
        newProd.addonGroupIds = Object.assign([], this.data.addonGroupIds); // new instance

        // Assign the price with decimals (for inputs). From API it comes in cents
        if (!!newProd.priceCents) {
            newProd.price = +(new FormatCentsToFloatPipe().transform(newProd.priceCents));
        }
        if (!!newProd.deliveryPriceCents) {
            newProd.deliveryPrice = +(new FormatCentsToFloatPipe().transform(newProd.deliveryPriceCents));
        }

        this.tempData$.next(Object.assign(new ProductResponse(), newProd));


        // Listen to UI modal backdrop:
        this.dialogRef.backdropClick().subscribe(() => {
            this.onCancel();
        });
    }

    private getData(): ProductResponse {
        return this.tempData$.getValue();
    }

    private getData$(): Observable<ProductResponse> {
      return this.tempData$;
    }

    private setData(val: ProductResponse) {
        this.tempData$.next(val);
    }

    // Popup cancel
    public onCancel() {

        let newProd = Object.assign(new ProductResponse(), this.getData());
        // make sure that the initial changes done inside this componnent don't affect the comparison. So make them as they were initially
        newProd.price = 0;
        newProd.deliveryPrice = 0;

        let isSameContent: boolean = JSON.stringify(newProd) === JSON.stringify(this.data); // compare to the initial one before the popup was opened
        if (!isSameContent) {
            // Notify the user that he'll lose the current changes
            this.modalService.openConfirmDialog$().subscribe((response) => {
                if (response) { // true
                    this.dialogRef.close();
                }
            });
        } else {
            this.dialogRef.close();
        }
    }

    // (Deutsch) Input Name + Description
    public nameUpdate(event: any) {
        let currentData = this.getData();
        currentData.name = event;
        this.setData(currentData);
    }

    public descriptionUpdate(event: any) {
        let currentData = this.getData();
        currentData.description = event;
        this.setData(currentData);
    }

    // (English) Input Name + Description
    public nameEnUpdate(event: any) {
        let currentData = this.getData();
        currentData.nameEn = event;
        this.setData(currentData);
    }

    public descriptionEnUpdate(event: any) {
        let currentData = this.getData();
        currentData.descriptionEn = event;
        this.setData(currentData);
    }

    // Price
    public priceUpdate(event: any) {
        let currentData = this.getData();

        // Make it input-friendly
        currentData.price = +parseFloat("" + event).toFixed(2);
    
        if (this.data.priceCents !== this.getData().priceCents) {
            currentData.deliveryPrice = +parseFloat("" + currentData.price * (1 + (this.PERCENT_RECOMMENDED_DELIVERY_DIFFERENCE / 100))).toFixed(2);
        }

        // Store it for the future (api) + the continuous check of the isDisabled button
        currentData.priceCents = +(new FormatCentsToFloatPipe().transformReverse(currentData.price)) || 0;
        currentData.deliveryPriceCents = +(new FormatCentsToFloatPipe().transformReverse(currentData.deliveryPrice)) || 0;
        this.setData(currentData);
    }

    public priceDeliveryUpdate(event: any) {
        let currentData = this.getData();

        // Make it input-friendly
        currentData.deliveryPrice = +(parseFloat("" + event).toFixed(2));

        // Store it for the future (api) + the continuous check of the isDisabled button
        currentData.deliveryPriceCents = +(new FormatCentsToFloatPipe().transformReverse(currentData.deliveryPrice)) || 0;
        this.setData(currentData);
    }

    /*
        Warning modal: Not allowed to edit price because the restaurant is open
    */

    public onEditDeliveryPricePress() {
        if (this.restaurantInfoService.checkIsActiveDuringHoursPickup() || this.restaurantInfoService.checkIsActiveDuringHoursDelivery()) {
            this.openWarningPopup();
        } else {
            this.askIfYouReallyWantToEdit();
        }        
    }

    public onEditPickupPricePress() {
        this.openWarningPopup();     
    }
    
    private openWarningPopup() {
        const config = this.modalService.getDefaultDialogConfig();
        config.width = "500px";
        config.data = {
            title: new LocaleTranslatePipe(this.localeService).transform("menu_price_edit_hours_unavailable_title"),
            midContent: new LocaleTranslatePipe(this.localeService).transform("menu_price_edit_hours_unavailable_body"),
            leftButtonContent: new LocaleTranslatePipe(this.localeService).transform("menu_price_edit_hours_unavailable_button"),
            preselectedButton: "left",
            oneButtonOnly: true
        }
        this.modalService.openConfirmDialog$(config).subscribe((response) => {
            if (response) { // true             
            }
        });
    }    

    private askIfYouReallyWantToEdit() {
        const config = this.modalService.getDefaultDialogConfig();
        config.width = "500px";
        config.data = {
            title: new LocaleTranslatePipe(this.localeService).transform("restaurant_delivery_popup_edit_title"),
            midContent: new LocaleTranslatePipe(this.localeService).transform("restaurant_delivery_popup_edit_body"),
            leftButtonContent: new LocaleTranslatePipe(this.localeService).transform("restaurant_delivery_popup_edit_left"),
            rightButtonContent: new LocaleTranslatePipe(this.localeService).transform("restaurant_delivery_popup_edit_right"),
            preselectedButton: "left",
        }

        // ask if you're sure
        this.modalService.openConfirmDialog$(config).subscribe((response) => {
            if (response) { // true     
                this.isPriceDeliveryEdited$.next(true);            
            }
        });
    }
    
    public onKeyUpDeliveryPrice(event: any) {
        let temp = this.getData();
        // make sure the user has at least the min order value when using Foodis delivery
        if (this.restaurantInfoService.getState().hasFoodisDelivery && temp.deliveryPriceCents < temp.priceCents * 1.25) {
            temp.deliveryPrice = +(parseFloat("" + temp.price * 1.25).toFixed(2));
            temp.deliveryPriceCents = temp.priceCents * 1.25;            
            // this is a hack just to have a nice UI and autocomplete with a small break
            this.showWarningCalculation$.next(true);
            setTimeout(() => {
                this.showWarningCalculation$.next(false);
                this.setData(temp);
            }, 200);
        }  
    }

    // Allergens
    public getAllAllergenList(): string[] {
        return Object.keys(AllergenEnum);
    }

    public onAllergenChange(event: any, selectedItem: string) {
        let letter = selectedItem as AllergenEnum;
        let enabled = event.selected;
        let currentData = this.getData();

        if (enabled) {
           // make sure we don't duplicate - so do a check before pushing it
           if (!currentData.allergenList.find((a) => a === letter)) {
               currentData.allergenList.push(letter);
           }
        } else {
            currentData.allergenList = currentData.allergenList.filter((item) => item !== letter)
        }
        currentData.allergenList.sort((a,b) => a > b ? 1 : b > a ? -1 : 0);
        this.setData(currentData);
    }

    // Availability
    public onAvailabilityToggleClick(event: any) {
        let currentData = this.getData();
        currentData.available = event.checked;
        this.setData(currentData);
    }

    // Category
    public onSelectCategory(event: any) {
        let currentData = this.getData();
        currentData.menuCategoryId = event.id;
        // TODO: multiple menus in the future. Currently it adds them to all menus. Currently, we don't have an assignment (category per menu id)
        currentData.menuIds = this.menuService.getMenuListState().menuList.map((m) => m.id);
        this.setData(currentData);
    }

    // Button add
    public onAddButtonClick() {
        // Transform to cents
        let currData = this.tempData$.getValue();
        currData.priceCents = +(new FormatCentsToFloatPipe().transformReverse(currData.price)) || 0;
        currData.deliveryPriceCents = +(new FormatCentsToFloatPipe().transformReverse(currData.deliveryPrice)) || 0;
        currData.imageUrl = !!currData.imageUrl ? currData.imageUrl : '';

        this.menuService.upsertProductAPI$(currData).subscribe((value) => {
            if (value) {
                // close the popup
                this.dialogRef.close();
            }
        })
    }

    public getCategoryById$(categoryId: any): Observable<MenuCategoryResponse | null> {
        if (!categoryId) {
            return of(null);
        }
        return this.categoryList$.pipe(
            map(list => {
                let category = list.menuCategoryList.find(c => c.id === categoryId);
                if (!!category) {
                    return category;
                }
                return null;
            })
        );
    }

    public isAllergenSelected$(allergen: string): Observable<boolean> {
        if (!allergen) {
            return of(false);
        }
        return of(!!this.getData().allergenList.find((a) => a === allergen));
    }

    public isButtonDisabled$(): Observable<boolean> {
        return this.tempData$.pipe(
            map(data => {
                // came from API
                if (!!data.id) {

                    // make sure that the initial changes done inside this componnent don't affect the comparison. So make them as they were initially
                    const newProd = Object.assign(new ProductResponse(), data);
                    newProd.price = 0;
                    newProd.deliveryPrice = 0;

                    return JSON.stringify(newProd) === JSON.stringify(this.data); // compare to the initial one before the popup was opened
                } else {
                    // it was a temp one
                    return !data.name || !data.price || !data.deliveryPrice || !data.menuCategoryId || data.menuIds.length === 0;
                }
            })
        );
    }

    // Delete button
    public onDeleteClick() {
        let config = this.modalService.getDefaultDialogConfig();
        config.width = "500px";
        config.data = {
            title: new LocaleTranslatePipe(this.localeService).transform("menu_product_modal_delete_confirm_title"),
            midContent: new LocaleTranslatePipe(this.localeService).transform("menu_product_modal_delete_confirm_body"),
            leftButtonContent: new LocaleTranslatePipe(this.localeService).transform("generic_modal_default_left_action"),
            rightButtonContent: new LocaleTranslatePipe(this.localeService).transform("generic_modal_default_right_action"),
            preselectedButton: "left"
        }

        this.modalService.openConfirmDialog$(config).subscribe((response) => {
            if (response) { // YES pressed in the confirm modal
                this.menuService.deleteProductAPI$(this.getData()).subscribe((value) => {
                    if (value) {
                        // close the popup
                        this.dialogRef.close();
                    }
                })
            }
        });
    }

    // Main vs Extra tabs
    public onTabChange(event: MatTabChangeEvent) {
      this.currentTab.next(event.tab.ariaLabel)
    }

    public onAddonGroupSelect(event: any, addonGroup: AddonGroupResponse) {
      const currentData = this.getData();
      currentData.addonGroupIds.push(addonGroup.id);
      this.setData(currentData);
    }

    public getFullAddonGroupById$(id: string): Observable<AddonGroupResponse | undefined> {
      return this.menuService.getAddonsGroupListState$().pipe(map((l) => l.addonGroupList.find((i) => i.id === id)));
    }

    // Remove addon
    public onRemoveAddonGroup(groupId: string) {
      const currentData = this.getData();
      currentData.addonGroupIds = [...currentData.addonGroupIds].filter((id) => id !== groupId);
      this.setData(currentData);
    }


    // Image upload
    public onImagePress() {
        this.openUploadImageModal();
    }

    private openUploadImageModal() {
        // Modal config
        const modalConfig = this.modalService.getDefaultDialogConfig();
        modalConfig.width = "700px";
        modalConfig.height = "650px";
        modalConfig.disableClose = true;

        // Data config        
        const blob: BlobResponse = new BlobResponse();
        blob.usageType = BlobUsageTypeEnum.PRODUCT_IMAGE;
        blob.id = this.getData()?.imageBlobId || '';
        blob.url = this.getData().imageUrl;

        const uploadConfig: CustomUpload = new CustomUpload();
        uploadConfig.imageData = blob;
        uploadConfig.usageName = this.getData().name;
        modalConfig.data = uploadConfig;
        
        this.modalService.openCustomDialog$(UploadImageModal, modalConfig).subscribe((callbackResponse) => {
            if (!!callbackResponse) {
                let currentData = this.getData();

                currentData.imageBlobId = callbackResponse.data.imageData.id;
                currentData.imageUrl = callbackResponse.data.imageData.url;
                this.setData(currentData);
            }
        });
    }
    
}
