import {Component, Input} from '@angular/core';
import {SideMenuItem} from "../side-menu/side-menu.component";

@Component({
  selector: 'item-side-menu',
  templateUrl: './item-side-menu.component.html',
  styleUrls: ['./item-side-menu.component.scss'],
})
export class ItemSideMenuComponent {

    @Input() public item: SideMenuItem = new SideMenuItem();

}
