import {Component, Input} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent {
    public menuList: BehaviorSubject<SideMenuItem[]> = new BehaviorSubject<SideMenuItem[]>(MENU_LIST);

    @Input() set selected(value: string) {
        if (!!value) {
            let list = this.menuList.getValue();
            let foundItem = list.find((i) => i.id === value);
            if (!!foundItem) {
                list = list.map((i) => {
                    i.selected = i.id === value;
                    return i;
                })
                this.menuList.next(list);
            }
        }
    }
}

export class SideMenuItem {
    public id: string = "";
    public key: string = "";
    public selected: boolean = false;
    public icon: string = "";
    public navRoute: string = "";
}

export const MENU_LIST: SideMenuItem[] = [ {
    id: "DASHBOARD",
    key: "side_nav_dashboard",
    selected: false,
    icon: "dashboard",
    navRoute: "/dashboard"
}, {
    id: "ORDERS",
    key: "side_nav_orders",
    selected: false,
    icon: "orders",
    navRoute: "/orders"
}, {
    id: "MENU",
    key: "side_nav_menu",
    selected: false,
    icon: "menu",
    navRoute: "/menu"
// }, {
//     id: "REPORTS",
//     key: "side_nav_reports",
//     selected: false,
//     icon: "reports",
//     navRoute: "/reports"
// }, {
//     id: "INVOICES",
//     key: "side_nav_invoices",
//     selected: false,
//     icon: "invoices",
//     navRoute: "/invoices"
}, {
        id: "REVIEWS",
        key: "side_nav_reviews",
        selected: false,
        icon: "reviews",
        navRoute: "/reviews"
}, {
    id: "RESTAURANT_INFO",
    key: "side_nav_restaurant_info",
    selected: false,
    icon: "restaurant_info",
    navRoute: "/settings"
}, {
    id: "FINANCE",
    key: "side_nav_restaurant_finance",
    selected: false,
    icon: "finance",
    navRoute: "/finance"
}, {
    id: "ACCOUNTS",
    key: "side_nav_restaurant_accounts",
    selected: false,
    icon: "accounts",
    navRoute: "/accounts"
}
];