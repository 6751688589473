<div class="modal-top-bar">
    <div class="modal-top-left-action"></div>

    <!-- Title -->
    <div class="modal-top-title">
      {{ "restaurant_info_hours_edit" | translate }}
    </div>

    <!-- Cancel button -->
    <div class="modal-top-right-action" >
        <div class="action-item" (click)="onCancel()">
            {{ 'menu_product_modal_cancel' | translate }}
        </div>
    </div>
</div>

<div class="modal-content">

    <div class="flex justify-center mb-5">
      <span class="subtitle-text">{{ 'restaurant_info_hours_day' | translate }}</span>: <span class="ml-3 font-medium">{{ ("restaurant_info_availability_" + (tempData$ | async)?.day) | translate }}</span>
    </div>

    <div class="container-boxes">

      <div class="left-box">

          <!-- Pickup -->
          <div class="hours-title-container">
            <!-- Title & icon -->
            <div class="hours-name">
              <div class="hours-icon">
                  <img [src]="'apps/restaurant/src/assets/delivery-type-pick-up-hand-selected.svg'"/>
              </div>
              {{ "restaurant_info_hours_pickup" | translate }}
            </div>
            <!-- Toggle -->
            <div class="status-toggle">
              <div class="status-toggle-text" [ngClass]="{'open': (pickupSliderValue$ | async) }">
                <ng-container *ngIf="(pickupSliderValue$ | async); else statusClosed">
                    {{ 'top_nav_status_open' | translate }}
                </ng-container>
                <ng-template #statusClosed>
                    {{ 'top_nav_status_closed' | translate }}
                </ng-template>

              </div>
              <div class="status-toggle-switch">
                  <mat-slide-toggle [color]="'primary'" [disableRipple]="false" [checked]="(pickupSliderValue$ | async)"  (change)="onPickupSlideToggle($event)"></mat-slide-toggle>
              </div>
            </div>
          </div>

          <!-- Pickup options / dropdowns -->
          <div class="options-container">

              <!-- Existing time intervals -->
              <ng-container *ngIf="(pickupSliderValue$ | async)">            
                  <ng-container *ngFor="let time of (tempData$ | async)?.businessHoursPickup; index as i">

                    <div class="r-info-availability-custom-hours">
                      <div class="r-info-availability-custom-hours-start">
                          <ng-container *ngIf="i === 0">
                            <div class="mb-2">{{ 'restaurant_info_hours_open_from' | translate }}</div>
                          </ng-container>
                          <fs-menu-simple
                                  [itemsList]='(pickupDropdownList$ | async)?.options || []'
                                  [itemValue]="time.openTime"
                                  [leadingIcon]="getHourImage(time.openTime) || './libs/shared-ui/assets/icons/clock.svg'"
                                  [smallHeight]="true"
                                  (onSelect)="onHoursChange($event, 'pickup', 'start', i)">
                          </fs-menu-simple>
                      </div>
                      <div class="r-info-availability-custom-hours-end">
                          <ng-container *ngIf="i === 0">
                            <div class="mb-2">{{ 'restaurant_info_hours_open_until' | translate }}</div>
                          </ng-container>
                          <fs-menu-simple
                                  [itemsList]='(pickupDropdownList$ | async)?.options || []'
                                  [itemValue]="time.closeTime"
                                  [leadingIcon]="getHourImage(time.closeTime) || './libs/shared-ui/assets/icons/clock.svg'"
                                  [smallHeight]="true"
                                  (onSelect)="onHoursChange($event, 'pickup', 'end', i)">
                          </fs-menu-simple>
                      </div>
                      <div class="delete-bin" (mousedown)="onDeletePickup($event, i)">
                        <img [src]="'apps/restaurant/src/assets/delete-bin-gray.svg'"/>
                      </div>
                    </div>
                  </ng-container>
                  
                  <!-- Add more -->
                  <div class="add-more-button" (mousedown)="onAddMorePickup()">
                     + {{ "restaurant_info_hours_add_interval" | translate }}
                  </div>

              </ng-container>    
          </div>
        </div>

      <div class="right-box">       

          <!-- Delivery -->

          <div class="hours-title-container">
            <!-- Title & icon -->
            <div class="hours-name">
                <div class="hours-icon">
                    <img [src]="'apps/restaurant/src/assets/delivery-type-bicycle-selected.svg'"/>
                </div>
                {{ "restaurant_info_hours_delivery" | translate }}
            </div>
            <!-- Toggle -->
            <div class="status-toggle">
              <div class="status-toggle-text" [ngClass]="{'open': (deliverySliderValue$ | async) }">
                <ng-container *ngIf="(deliverySliderValue$ | async); else statusClosed">
                    {{ 'top_nav_status_open' | translate }}
                </ng-container>
                <ng-template #statusClosed>
                    {{ 'top_nav_status_closed' | translate }}
                </ng-template>
              </div>
              <div class="status-toggle-switch">
                  <mat-slide-toggle [color]="'primary'" [disableRipple]="false" [checked]="(deliverySliderValue$ | async)"  (change)="onDeliverySlideToggle($event)"></mat-slide-toggle>
              </div>
            </div>
          </div>

          <!-- Delivery options / dropdowns -->
          <div class="options-container">
            
            <!-- Existing time intervals -->
            <ng-container *ngIf="(deliverySliderValue$ | async)">            
              <ng-container *ngFor="let time of (tempData$ | async)?.businessHoursDelivery; index as i">

                <div class="r-info-availability-custom-hours">
                  <div class="r-info-availability-custom-hours-start">
                      
                      <ng-container *ngIf="i === 0">
                        <div class="mb-2">{{ 'restaurant_info_hours_open_from' | translate }}</div>
                      </ng-container>
                      
                      <fs-menu-simple
                              [itemsList]='(deliveryDropdownList$ | async)?.options || []'
                              [itemValue]="time.openTime"
                              [leadingIcon]="getHourImage(time.openTime) || './libs/shared-ui/assets/icons/clock.svg'"
                              [smallHeight]="true"
                              (onSelect)="onHoursChange($event, 'delivery', 'start', i)">
                      </fs-menu-simple>
                  </div>
                  <div class="r-info-availability-custom-hours-end">
                      
                      <ng-container *ngIf="i === 0">
                        <div class="mb-2">{{ 'restaurant_info_hours_open_until' | translate }}</div>
                      </ng-container>
                      
                      <fs-menu-simple
                              [itemsList]='(deliveryDropdownList$ | async)?.options || []'
                              [itemValue]="time.closeTime"
                              [leadingIcon]="getHourImage(time.closeTime) || './libs/shared-ui/assets/icons/clock.svg'"
                              [smallHeight]="true"
                              (onSelect)="onHoursChange($event, 'delivery', 'end', i)">
                      </fs-menu-simple>
                  </div>      
                  <div class="delete-bin" (mousedown)="onDeleteDelivery($event, i)">
                    <img [src]="'apps/restaurant/src/assets/delete-bin-gray.svg'"/>
                  </div>            
                </div>                
              </ng-container>

              <!-- Add more -->
              <div class="add-more-button" (mousedown)="onAddMoreDelivery()">
                + {{ "restaurant_info_hours_add_interval" | translate }}
              </div>

            </ng-container>    
          </div>
      </div>
    </div>

    <!-- Add or Save changes button -->
    <div class="modal-bottom-action">
        <fs-button [text]="'menu_addon_modal_create_button_save' | translate" [style]="'full-width-large-height'"  [type]="'raised-primary'" 
                   (onPress)="onSaveClick()" [disabled]="(isButtonDisabled$() | async) || false">
        ></fs-button>
    </div>
</div>

