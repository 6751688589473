import { BehaviorSubject, Observable} from "rxjs";
import { Injectable} from "@angular/core";
import { StripeAccountSessionResponse } from "../model/stripe-account-session-response";

@Injectable({
    providedIn: 'root',
})
export class RestaurantFinanceStateService {

    private state$: BehaviorSubject<StripeAccountSessionResponse> = new BehaviorSubject<StripeAccountSessionResponse>(new StripeAccountSessionResponse());

    constructor(
    ) {
    }

    public getData$(): Observable<StripeAccountSessionResponse> {
        return this.state$.asObservable();
    }

    public getData(): StripeAccountSessionResponse {
        return this.state$.getValue();
    }

    public setData(value: StripeAccountSessionResponse): void {
        this.state$.next(value);
    }
}
