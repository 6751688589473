import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestaurantInfoService } from '../../../services/restaurant-info/restaurant-info.service';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { RestaurantVerificationEnum } from "libs/shared-models/src/lib/restaurant/restaurant-response";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RestaurantOrdersService } from '../../../services/restaurant-orders/restaurant-orders.service';
import { OrderResponse, OrderStatus } from 'libs/shared-models/src/lib/order/order-response';
import { ApiBaseService } from 'libs/shared-services/src/lib/api-base.service';
import { environment } from 'apps/restaurant/src/environments/environment';
import { ToasterService } from 'libs/shared-services/src/lib/toaster.service';
import { LocaleService } from 'libs/shared-services/src/lib/locale.service';
import { DeliveryOption } from 'libs/shared-models/src/lib/delivery-option';
import { LOADING_STYLE } from 'libs/shared-ui/src/lib/fs-loading-spinner/fs-loading-spinner.component';
import { AddressResponse } from 'libs/shared-models/src/lib/address-response';

@UntilDestroy()
@Component({
  selector: 'web-foodis-section-orders',
  templateUrl: './section-orders.component.html',
  styleUrls: ['./section-orders.component.scss'],
})
export class SectionOrdersComponent implements OnInit {

  public isVerified$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public ordersList$: Observable<OrderResponse[]> = this.restaurantOrdersService.ordersList$();
  public currentSelectedOrder$: Observable<OrderResponse | null> = this.restaurantOrdersService.getCurrentSelectedOrder$();

  public isLoadingOrdersAPI$: Observable<boolean> = this.restaurantOrdersService.isLoadingOrdersAPI$();

  public DeliveryOption: typeof DeliveryOption = DeliveryOption;
  public PaymentOption: typeof PaymentOption = PaymentOption; 
  public OrderStatus: typeof OrderStatus = OrderStatus;
  public LOADING_STYLE: typeof LOADING_STYLE = LOADING_STYLE;
  
  constructor(
    private router: Router,
    private restaurantInfoService: RestaurantInfoService,
    private restaurantOrdersService: RestaurantOrdersService,
    private apiService: ApiBaseService,
    private toasterService: ToasterService,
    private localeService: LocaleService
  ) {
    
  }

  public ngOnInit() {
    const status = this.restaurantInfoService.getState$().pipe(untilDestroyed(this)).subscribe((res) => {
      this.isVerified$.next(res.verificationStatus === RestaurantVerificationEnum.VERIFIED);
    })   
    this.restaurantOrdersService.fetchOrdersListAPI();
  }

  public onPressVerify() {
    this.router.navigateByUrl("/dashboard");
  }

  public onOpenOrder(order: OrderResponse) {
    this.restaurantOrdersService.setCurrentSelectedOrder(order);
  }

  public pressStateChange(status: string) {

    let currentOrder = this.restaurantOrdersService.getCurrentSelectedOrder();
    
    if (currentOrder?.id) {

      let requestBody;

      if (status === "ACCEPTED_BY_RESTAURANT") {
        requestBody = {
          status: status,
          estimatedTotalTimeInMinutes: 30
        }
      } else {
        requestBody = {
          status: status
        }
      }

      let apiCall = this.apiService.patch(environment.API_GET_ORDERS_SET_STATUS.replace('{orderId}', currentOrder.id), requestBody);
      apiCall.pipe(
        map((res) => {
          //this.toasterService.showSuccess("", this.localeService.translate("accounts_invitation_removed_successfully"));

          this.toasterService.showSuccess("", "Order status updated successfully to " + status);

          // refetch orders:
          this.restaurantOrdersService.fetchOrdersListAPI();
        
          return true;
        }),
        catchError((err: any, caught: Observable<any>): Observable<any> => {
          this.toasterService.showError("Error", err?.error?.message);
          return of(false);
        })
      ).subscribe((res) => {
        console.log("==res: ", res);
      })
    }    
  } 


  /*
    Copy to clipboard
  */
    public onCopyClick() {
      const address = this.restaurantOrdersService.getCurrentSelectedOrder()?.userAddress;
      const coppiedAddress = address?.streetName + " " + address?.streetNumber + ", " + address?.district + " " + address?.postalCode + ", " + address?.city;
      // Copy the text inside the text field
      navigator.clipboard.writeText(coppiedAddress);
  
      this.toasterService.showInfo("",this.localeService.translate("orders_page_address_coppied"));
    }
  
    /*
      Open External Google maps
    */
    public onExternalMapsClick() {
      const address = this.restaurantOrdersService.getCurrentSelectedOrder()?.userAddress;
      const url = "https://www.google.com/maps/search/?api=1&query=" + encodeURIComponent(address?.latitude + "," + address?.longitude); // + data.restaurantInfo.name
      window.open(url, '_blank');
    }
  
}


export enum PaymentOption {
  CASH = "CASH",
  ONLINE = "ONLINE"
}
