<main-layout [selected]="'FINANCE'">
    <content class="w-full flex overflow-scroll">
        <div class="finance-main-container">
            <div class="title-text">
                {{ "Stripe account" | translate }}
            </div>
            
            <div class="pt-5 pb-1">
                Stripe account ID: {{ (restaurantData$ | async)?.stripeAccountId || "Not created yet"}}
            </div>

            <div class="pt-3">
                <ng-container *ngIf="!(restaurantData$ | async)?.stripeAccountId; else accountExistsShowSession">
                    <fs-button [text]="'Create Stripe payment Account' | translate" 
                        [type]="'raised-primary'" [leadingIcon]="'add'"
                        [disabled]="(isLoadingCreateStripeID$ | async) || false"
                        (onPress)="onPressCreate()">
                    </fs-button>
                </ng-container>

                <ng-template #accountExistsShowSession>
                    Session secret should be loaded here:  {{ (sessionData$ | async)?.clientSecret }}
                </ng-template>

                <div id="stripe-session-load-container"></div>
                
            </div>            
        </div>
    </content>
</main-layout>
