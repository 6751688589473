import { Product } from "libs/shared-models/src/lib/product-type";

export class UserSelfResponse {
    public id: string = "";
    public email: string = "";
    public name: string = "";
    public companyName: string = "";
    public phoneNumber: string = "";
    public userLanguage: string = "";
    public product: Product = Product.UNDEFINED;
}