<div class="card-container" [ngClass]="{'container-completed': completed}">
    <div class="card-child">
        <ng-container *ngIf="!!numberLabel">
            <div class="number-label-container">
                <div class="number-label" [ngClass]="{'number-completed': completed}">
                    {{ numberLabel }}
                </div>
                <ng-container *ngIf="completed">
                    <div class="completed-label-container">                        
                        <div class="completed-label">      
                            <mat-icon class="completed-icon">check</mat-icon>                      
                            {{ 'onboarding_step_completed' | translate }}
                        </div>
                    </div>            
                </ng-container> 
            </div>            
        </ng-container>    
    
        <div class="card-icon" [ngClass]="{'extra-icon-space': !numberLabel}">
            <ng-container *ngIf="!!icon">
                <img class="src-icon" [src]="'apps/restaurant/src/assets/' + icon + '.svg'"/>
            </ng-container>    
        </div>
        <div class="card-text">
            {{ key | translate }}
        </div>        
    </div>
</div>
