import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, map, Observable, Subscription} from "rxjs";
import { combineLatest } from "rxjs";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { DocumentResponse, RestaurantResponse, RestaurantVerificationEnum } from 'libs/shared-models/src/lib/restaurant/restaurant-response';
import { RestaurantInfoService } from "../../../services/restaurant-info/restaurant-info.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SelfDataService} from "../../../services/self/self.service";
import { ModalService } from 'libs/shared-ui/src/lib/modal-service/modal-service.service';
import { BlobResponse, BlobUsageTypeEnum, CustomUpload, VERIFICATION_DOCUMENTS_FILE_TYPES } from '../../../models/custom-upload';
import { UploadImageModal } from '../../general/upload-image-modal/upload-image-modal.component';
import { LocaleService } from 'libs/shared-services/src/lib/locale.service';
import { IFsRadioButton } from 'libs/shared-ui/src/lib/fs-radio-button/fs-radio-button.component';
import { BusinessHours, BusinessHoursNameEnum, BusinessHoursPeriod, DAYS_LIST } from 'libs/shared-models/src/lib/restaurant/business-hours';
import { BusinessHoursModalComponent } from './modals/business-hours-modal/business-hours-modal.component';
import { CustomBusinessHours } from '../../../models/custom-business-hours';
import { IFsChipsItem } from 'libs/shared-ui/src/lib/fs-chip-list/fs-chip-list.component';
import { RestaurantAddressEditorComponent } from './modals/restaurant-address-editor/restaurant-address-editor.component';
import { AddressResponse } from 'libs/shared-models/src/lib/address-response';
import { LocaleTranslatePipe } from 'libs/shared-services/src/lib/locale-pipe';
import { NavigationEnd,Router } from '@angular/router';
import { WrapperAddressResponse, WrapperAddressUsageEnum } from '../../../models/wrapper-adress-response';
import { UserSelfResponse } from '../../../models/user-response';
import { RestaurantCategoryEnum } from 'libs/shared-models/src/lib/restaurant/restaurant-category-enum';
import { DeliveryTimeRangeEnum } from 'libs/shared-models/src/lib/restaurant/delivery-time-range-enum';
import { TimezoneService } from 'libs/shared-services/src/lib/timezone.service';
import { TimezonePipe } from 'libs/shared-services/src/lib/timezone.pipe';

@UntilDestroy()
@Component({
  selector: 'web-foodis-section-restaurant-info',
  templateUrl: './section-restaurant-info.component.html',
  styleUrls: ['./section-restaurant-info.component.scss'],
})
export class SectionRestaurantInfoComponent implements OnInit, OnDestroy {

    public currentTab: BehaviorSubject<string> = new BehaviorSubject<string>('public');
    public currentTabIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);


    // Public profile data. This is temporary, inside this component. Listens to the general one
    public restaurantData$: BehaviorSubject<RestaurantResponse> = new BehaviorSubject<RestaurantResponse>(new RestaurantResponse());
    public selfData$: BehaviorSubject<UserSelfResponse> = new BehaviorSubject<UserSelfResponse>(new UserSelfResponse());

    public saveButtonDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private initialState: string = "";
    private initialSelfState: string = "";

    public deliveryTimeOptions: IFsRadioButton[] = [];

    public RestaurantInfoTab: typeof RestaurantInfoTab = RestaurantInfoTab;
    public RestaurantVerificationEnum: typeof RestaurantVerificationEnum = RestaurantVerificationEnum;

    private routeSub: Subscription;

    private readonly USER_MIN_AMOUNT_WITH_FOODIS_DELIVERY: number = 10; // 10 euro

    public showTimezoneEdit: boolean = false;
    public showTimezoneDropdown: boolean = false;
    public DAYS_LIST = DAYS_LIST;

    constructor(
        private restaurantInfoService: RestaurantInfoService,
        private selfDataService: SelfDataService,
        private modalService: ModalService,
        private localeService: LocaleService,
        private router: Router,
        private cdRef: ChangeDetectorRef,
        private timezoneService: TimezoneService
    ) {
        
    }

    public ngOnInit(): void {

        this.checkRouteParams();

        combineLatest([
            this.restaurantInfoService.getState$(),
            this.selfDataService.getSelfData$()]).pipe(untilDestroyed(this)).subscribe(
            ([infoData, selfData]) => {

                // already auto-complete with some data
                if (infoData.id === "") {
                    infoData.name = selfData.companyName;
                    infoData.companyName = selfData.companyName;
                }
                
                this.initialState = JSON.stringify(infoData);
                this.initialSelfState = JSON.stringify(selfData);
                
                this.selfData$.next(selfData);
                this.restaurantData$.next(infoData);
            }
        );


        // checking for disable button
        combineLatest([
            this.restaurantData$,
            this.selfData$]).pipe(untilDestroyed(this)).subscribe(
            ([resData, selfData]) => {

                // restaurant object
                const newObject = Object.assign(resData);
                const stringified = JSON.stringify(newObject);

                // self object
                const newSelfObject = Object.assign(selfData);
                const stringifiedSelf = JSON.stringify(newSelfObject);
                
                this.saveButtonDisabled$.next((stringified === this.initialState) && (stringifiedSelf === this.initialSelfState));
                this.cdRef.detectChanges();
            }
        );

        this.deliveryTimeOptions = [
            {
                id: DeliveryTimeRangeEnum.D15_30_MIN,
                value: DeliveryTimeRangeEnum.D15_30_MIN,
                text: this.localeService.translate("restaurant_info_delivery_time_option_1")
            },
            {
                id: DeliveryTimeRangeEnum.D30_45_MIN,
                value: DeliveryTimeRangeEnum.D30_45_MIN,
                text: this.localeService.translate("restaurant_info_delivery_time_option_2")
            },
            {
                id: DeliveryTimeRangeEnum.D45_60_MIN,
                value: DeliveryTimeRangeEnum.D45_60_MIN,
                text: this.localeService.translate("restaurant_info_delivery_time_option_3")
            }
        ]        
    }

    /*
        This makes sure we also change the tab (for the mat-tab component) if the url changes
    */
    private checkRouteParams() {
        this.checkRouteForTab(this.router.url);

        this.routeSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              this.checkRouteForTab(event.url);
            }
        });
    }

    private checkRouteForTab(url: string) {
        switch (url) {
            case RestaurantTabRoute.PUBLIC: {
                this.changeTab(RestaurantInfoTab.PUBLIC, 0);
                break;    
            }
            case RestaurantTabRoute.OPENING_HOURS: {
                this.changeTab(RestaurantInfoTab.OPENING_HOURS, 1);
                break;    
            }
            case RestaurantTabRoute.DELIVERY: {
                this.changeTab(RestaurantInfoTab.DELIVERY, 2);
                break;    
            }
            case RestaurantTabRoute.PRIVATE: {
                this.changeTab(RestaurantInfoTab.PRIVATE, 3);
                break;    
            }
        }
    }

    private getRestaurantData(): RestaurantResponse {
        return this.restaurantData$.getValue();
    }

    private setRestaurantData(data: RestaurantResponse) {
        return this.restaurantData$.next(data);
    }


    /*
        Public profile (tab)
     */


    // Logo & Background images
    // Image upload
    public onBackgroundImagePress() {
        // don't allow to add hours if the restaurant was not created yet
        if (!this.getRestaurantData().id) {
            return;
        }
        this.openUploadBackgroundImageModal();
    }

    public onLogoImagePress() {
        // don't allow to add hours if the restaurant was not created yet
        if (!this.getRestaurantData().id) {
            return;
        }
        if (this.editBlocked()) {
            return;
        }
        this.openUploadLogoImageModal();
    }

    public editBlocked$(): Observable<boolean> {
        return this.restaurantData$.pipe(
            map(data => {
                return data.verificationStatus === RestaurantVerificationEnum.VERIFIED ||
                data.verificationStatus === RestaurantVerificationEnum.VERIFICATION_REQUESTED
            })
        );
    }

    private editBlocked(): boolean {
        return this.restaurantData$.getValue().verificationStatus === RestaurantVerificationEnum.VERIFIED || 
                this.restaurantData$.getValue().verificationStatus === RestaurantVerificationEnum.VERIFICATION_REQUESTED;
    }

    private openUploadBackgroundImageModal() {
        // Modal config
        const modalConfig = this.modalService.getDefaultDialogConfig();
        modalConfig.width = "700px";
        modalConfig.height = "650px";
        modalConfig.disableClose = true;

        // Data config        
        const blob: BlobResponse = new BlobResponse();
        blob.usageType = BlobUsageTypeEnum.RESTAURANT_PROFILE_IMAGE; // MAIN IMAGE
        blob.id = this.getRestaurantData().mainImageBlobId || ''; // background blob
        blob.url = this.getRestaurantData().mainImageUrl || ''; // background image

        const uploadConfig: CustomUpload = new CustomUpload();
        uploadConfig.imageData = blob;
        uploadConfig.usageName = this.localeService.translate("restaurant_info_main_image_tooltip"); // usage
        modalConfig.data = uploadConfig;
        
        this.modalService.openCustomDialog(UploadImageModal, modalConfig);
    }


    private openUploadLogoImageModal() {
        // Modal config
        const modalConfig = this.modalService.getDefaultDialogConfig();
        modalConfig.width = "700px";
        modalConfig.height = "650px";
        modalConfig.disableClose = true;

        // Data config        
        const blob: BlobResponse = new BlobResponse();
        blob.usageType = BlobUsageTypeEnum.RESTAURANT_LOGO_IMAGE; // LOGO
        blob.id = this.getRestaurantData().logoImageBlobId || ''; // logo blob
        blob.url = this.getRestaurantData().logoImageUrl || ''; // logo image

        const uploadConfig: CustomUpload = new CustomUpload();
        uploadConfig.imageData = blob;
        uploadConfig.usageName = this.localeService.translate("restaurant_info_logo_tooltip"); // usage
        modalConfig.data = uploadConfig;
        
        this.modalService.openCustomDialog(UploadImageModal, modalConfig);
    }

    // Name update
    public onNameUpdate(val: string) {
        if (val !== this.getRestaurantData().name) {
            const temp = this.getRestaurantData();
            temp.name = val;
            this.setRestaurantData(temp);
        }
    }

    // Description update
    public onDescriptionUpdate(val: string) {
        if (val !== this.getRestaurantData().description) {
            const temp = this.getRestaurantData();
            temp.description = val;
            this.setRestaurantData(temp);
        }
    }

    // Email update
    public onEmailUpdate(val: string) {
        if (val !== this.getRestaurantData().email) {
            const temp = this.getRestaurantData();
            temp.email = val === "" ? null : val;
            this.setRestaurantData(temp);
        }
    }

    // Website update
    public onWebsiteUpdate(val: string) {
        if (val !== this.getRestaurantData().website) {
            const temp = this.getRestaurantData();
            temp.website = val;
            this.setRestaurantData(temp);
        }
    }

    // Public phone update
    public onPhoneUpdate(val: string) {
        if (val !== this.getRestaurantData().phoneNumber) {
            const temp = this.getRestaurantData();
            temp.phoneNumber = val;
            this.setRestaurantData(temp);
        }
    }

    // Emergency phone update (part of self endpoin)   
    public onEmergencyPhoneUpdate(val: string) {
        if (val !== this.selfData$.getValue().phoneNumber) {
            const temp = this.selfData$.getValue();
            temp.phoneNumber = val;
            this.selfData$.next(temp);
        }
    }

    // IBAN update
    public onIbanUpdate(val: string) {
        if (val !== this.getRestaurantData().ibanAccount) {
            const temp = this.getRestaurantData();
            temp.ibanAccount = val === "" ? null : val;
            this.setRestaurantData(temp);
        }
    }

    public onAddressPress() {        
        if (this.editBlocked()) {
            return;
        }
        this.openAddressPopup(this.getRestaurantData().address, WrapperAddressUsageEnum.ADDRESS);
    }

    public onInvoiceAddressPress() {   
        if (this.editBlocked()) {
            return;
        } 
        this.openAddressPopup(this.getRestaurantData().invoiceAddress, WrapperAddressUsageEnum.INVOICE_ADDRESS);
    }

    private openAddressPopup(a: AddressResponse, usage: WrapperAddressUsageEnum) {
        if (!this.getRestaurantData().id) {
            return;
        }
        let address = a;

        if (!address) {
            address = new AddressResponse();
        }
        address["isDefault"] = true; // fix this bad value assignement when deep copy will be added

        const modalConfig = this.modalService.getDefaultDialogConfig();
        const modalData = new WrapperAddressResponse();
        modalData.address = address;
        modalData.popupUsage = usage;
        modalConfig.data = modalData;
        modalConfig.width = "1000px";
        modalConfig.height = "650px";
        modalConfig.disableClose = true;
        this.modalService.openCustomDialog$(RestaurantAddressEditorComponent, modalConfig).subscribe((callbackResponse) => {
            // do nothing
        })
    }

    public getAddressCombined$(): Observable<string> {
        return this.restaurantData$.pipe(
            map((value) => {
                const address = Object.assign(new AddressResponse(), value.address);
                if (address.isEmpty()) {
                    return "";
                }
                return address.getStreetName() + " " + address.getStreetNumber() + ", " + address.getDistrict() + " " + address.getPostalCode() + ", " + address.getCity();
            })
        );
    }

    public getInvoiceAddressCombined$(): Observable<string> {
        return this.restaurantData$.pipe(
            map((value) => {
                const address = Object.assign(new AddressResponse(), value.invoiceAddress);
                if (address.isEmpty()) {
                    return "";
                }
                return address.getStreetName() + " " + address.getStreetNumber() + ", " + address.getDistrict() + " " + address.getPostalCode() + ", " + address.getCity();
            })
        );
    }

    public submitData() {     
        // Restaurant object
        const newObject = Object.assign(this.getRestaurantData());
        const stringified = JSON.stringify(newObject);
        // check if any changes exist before submittint it to API

        if (stringified !== this.initialState || !this.getRestaurantData().id) {
            this.restaurantInfoService.updateRestaurantInfoAPI(this.getRestaurantData());
        }

        // Self object
        const newSelfObject = Object.assign(this.selfData$.getValue());
        const stringifiedSelf = JSON.stringify(newSelfObject);
        // check if any changes exist before submitting it to API
        if (stringifiedSelf !== this.initialSelfState) {
            this.selfDataService.setPhoneNumberApi(newSelfObject.phoneNumber);
        }            
    }

    public getSelectedCuisinesList$(): Observable<string[]> {
        return this.restaurantData$.pipe(
                    map((data) => {
                        return data.categoryList.map((item) => item)
                    }));
    }

    public getAllCuisinesList$(): Observable<IFsChipsItem[]> {
        return this.localeService.getLanguage$()
                .pipe(
                    map((lang) => {
                        return Object.keys(RestaurantCategoryEnum).map((item)=> {
                            return {
                                id: item,
                                text: this.localeService.translate("restaurant_info_category_" + item.toLocaleLowerCase())
                            } as IFsChipsItem
                        })
                    })
                );
    }

    public onRestaurantCuisineUpdate(list: RestaurantCategoryEnum[]) {
        const data = this.getRestaurantData();
        data.categoryList = list;
        this.setRestaurantData(data);
    }

    /*
        Opening hours (tab)
    */

    public onPressHours(event: any, day: string) {
        // don't allow to add hours if the restaurant was not created yet
        if (!this.getRestaurantData().id) {
            return;
        }

        if (this.restaurantInfoService.isToday(day) &&
            (this.restaurantInfoService.checkIsActiveDuringHoursPickup() || this.restaurantInfoService.checkIsActiveDuringHoursDelivery())) {
            this.openWarningPopup();
        } else {
            const data = this.getCustomBusinessHours(day);
            this.openBusinessHoursModal(data);
        }   
    }

    private openWarningPopup() {
        const config = this.modalService.getDefaultDialogConfig();
        config.width = "500px";
        config.data = {
            title: new LocaleTranslatePipe(this.localeService).transform("res_info_edit_hours_unavailable_title"),
            midContent: new LocaleTranslatePipe(this.localeService).transform("res_info_edit_hours_unavailable_body"),
            leftButtonContent: new LocaleTranslatePipe(this.localeService).transform("res_info_edit_hours_unavailable_button"),
            preselectedButton: "left",
            oneButtonOnly: true
        }
        this.modalService.openConfirmDialog$(config).subscribe((response) => {
            if (response) { // true             
            }
        });
    }  

    private getCustomBusinessHours(day: string): CustomBusinessHours {
        const data = new CustomBusinessHours();
        data.day = day as BusinessHoursNameEnum;
        data.timezone = this.getRestaurantData().timeZone;

        const pickupHours = Object.assign(new BusinessHours(), this.getRestaurantData().businessHoursPickup);
        const deliveryHours = Object.assign(new BusinessHours(), this.getRestaurantData().businessHoursDelivery);

        // I hope we can get improve this on BE/FE in terms of structure so I don't need to c/p it this ugly way:
        // mid-term, maybe I can add some deep-clone object function
        switch (day) {
            case BusinessHoursNameEnum.MONDAY: {
                data.businessHoursPickup = [...(pickupHours?.mon || []).map((i) => Object.assign(new BusinessHoursPeriod(), i))];
                data.businessHoursDelivery = [...(deliveryHours?.mon || []).map((i) => Object.assign(new BusinessHoursPeriod(), i))];
                break;
            }
            case BusinessHoursNameEnum.TUESDAY: {
                data.businessHoursPickup = [...(pickupHours?.tue || []).map((i) => Object.assign(new BusinessHoursPeriod(), i))];
                data.businessHoursDelivery = [...(deliveryHours?.tue || []).map((i) => Object.assign(new BusinessHoursPeriod(), i))];
                break;
            }
            case BusinessHoursNameEnum.WEDNESDAY: {
                data.businessHoursPickup = [...(pickupHours?.wed || []).map((i) => Object.assign(new BusinessHoursPeriod(), i))];
                data.businessHoursDelivery = [...(deliveryHours?.wed || []).map((i) => Object.assign(new BusinessHoursPeriod(), i))];
                break;
            }
            case BusinessHoursNameEnum.THURSDAY: {
                data.businessHoursPickup = [...(pickupHours?.thu || []).map((i) => Object.assign(new BusinessHoursPeriod(), i))];
                data.businessHoursDelivery = [...(deliveryHours?.thu || []).map((i) => Object.assign(new BusinessHoursPeriod(), i))];
                break;
            }
            case BusinessHoursNameEnum.FRIDAY: {
                data.businessHoursPickup = [...(pickupHours?.fri || []).map((i) => Object.assign(new BusinessHoursPeriod(), i))];
                data.businessHoursDelivery = [...(deliveryHours?.fri || []).map((i) => Object.assign(new BusinessHoursPeriod(), i))];
                break;
            }
            case BusinessHoursNameEnum.SATURDAY: {
                data.businessHoursPickup = [...(pickupHours?.sat || []).map((i) => Object.assign(new BusinessHoursPeriod(), i))];
                data.businessHoursDelivery = [...(deliveryHours?.sat || []).map((i) => Object.assign(new BusinessHoursPeriod(), i))];
                break;
            }
            case BusinessHoursNameEnum.SUNDAY: {
                data.businessHoursPickup = [...(pickupHours?.sun || []).map((i) => Object.assign(new BusinessHoursPeriod(), i))];
                data.businessHoursDelivery = [...(deliveryHours?.sun || []).map((i) => Object.assign(new BusinessHoursPeriod(), i))];
                break;
            }
        }

        // transform from the UTC to restaurant's timezone before using this data inside the popup:
        data.businessHoursPickup = this.convertUTCtoTimezone(data.businessHoursPickup);
        data.businessHoursDelivery = this.convertUTCtoTimezone(data.businessHoursDelivery);

        return data;
    }

    private convertUTCtoTimezone(hours: BusinessHoursPeriod[]): BusinessHoursPeriod[] {
        hours = hours.map((item: BusinessHoursPeriod) => {
            const timezone = this.getRestaurantData().timeZone;
            item.openTime = new TimezonePipe().transform(item.openTime, timezone);
            item.closeTime = new TimezonePipe().transform(item.closeTime, timezone);
            return item;
        });        
        
        return hours;
    }

    private openBusinessHoursModal(data: CustomBusinessHours) {
        const modalConfig = this.modalService.getDefaultDialogConfig();
        modalConfig.data = data;
        modalConfig.width = "710px";
        modalConfig.disableClose = true;
        this.modalService.openCustomDialog$(BusinessHoursModalComponent, modalConfig).subscribe((callbackResponse) => {
        //   const data = Object.assign(new AddonGroupResponse(), this.getData());
        //   data.addonList.push(callbackResponse.data);
        //   this.setData(data);
        })
    }

    /*
        Delivery (tab)
     */

    public onFoodisDeliveryPress(val: any) {
        const value = val.checked;
        if (value !== this.getRestaurantData().hasFoodisDelivery) {
            const temp = this.getRestaurantData();
            temp.hasFoodisDelivery = value;
            // make sure the user has at least the min order value when using Foodis delivery
            if (temp.minOrderAmount < this.USER_MIN_AMOUNT_WITH_FOODIS_DELIVERY) {
                temp.minOrderAmount = this.USER_MIN_AMOUNT_WITH_FOODIS_DELIVERY;
            }
            this.setRestaurantData(temp);
        }
    }

    public onRestaurantDeliveryPress(val: any) {
        const value = val.checked;
        if (value !== this.getRestaurantData().hasOwnDelivery) {
            const temp = this.getRestaurantData();
            temp.hasOwnDelivery = value;
            this.setRestaurantData(temp);
        }
    }

    public onPickupDeliveryPress(val: any) {
        const value = val.checked;
        if (value !== this.getRestaurantData().hasPickup) {
            const temp = this.getRestaurantData();
            temp.hasPickup = value;
            this.setRestaurantData(temp);
        }
    }

    // Delivery time
    public deliveryTimeChange(event: any) {
        const temp = this.getRestaurantData();
        temp.deliveryTimeRange = event.value;
        this.setRestaurantData(temp);
    }    

    // Delivery min amount   
    public onMinAmountUpdate(event: string) {
        let value = +event;
        if (!value) { 
            value = 0;
        }
        const temp = this.getRestaurantData();              
        temp.minOrderAmount = value;
        this.setRestaurantData(temp);
    }

    public onKeyUpDeliveryAmount($event: any) {
        const temp = Object.assign(new RestaurantResponse(),this.getRestaurantData());
        // make sure the user has at least the min order value when using Foodis delivery
        if (temp.hasFoodisDelivery && temp.minOrderAmount < this.USER_MIN_AMOUNT_WITH_FOODIS_DELIVERY) {
            temp.minOrderAmount = this.USER_MIN_AMOUNT_WITH_FOODIS_DELIVERY;
            // this is a hack just to have a nice UI and autocomplete with a small break
            setTimeout(() => {
                this.setRestaurantData(temp);
            }, 200);
        }     
    }
    
    // Delivery radius
    public onDeliveryRadiusUpdate(val: string) {
        if (!!val && +val !== this.getRestaurantData().deliveryRadiusMeters) {
            const temp = this.getRestaurantData();
            temp.deliveryRadiusMeters = +val;
            this.setRestaurantData(temp);
        }
    }

    public onRadiusSliderChange(val: any) {
        this.onDeliveryRadiusUpdate(val + "");
    }


    /*
        Private (tab)
     */

    // Company name update
    public onCompanyNameUpdate(val: string) {
        if (!!val && val !== this.getRestaurantData().companyName) {
            const temp = this.getRestaurantData();
            temp.companyName = val;
            this.setRestaurantData(temp);
        }
    }

    // VAT number
    public onVatUpdate(val: string) {
        if (!!val && val !== this.getRestaurantData().vatNumber) {
            const temp = this.getRestaurantData();
            temp.vatNumber = val;
            this.setRestaurantData(temp);
        }
    }

    public onUploadVerificationFilePress() {
        // don't allow to add hours if the restaurant was not created yet
        if (!this.getRestaurantData().id) {
            return;
        }

        // Modal config
        const modalConfig = this.modalService.getDefaultDialogConfig();
        modalConfig.width = "700px";
        modalConfig.height = "650px";
        modalConfig.disableClose = true;

        // Data config        
        const blob: BlobResponse = new BlobResponse();
        blob.usageType = BlobUsageTypeEnum.RESTAURANT_DOCUMENT; // This goes to private folder on backend

        const uploadConfig: CustomUpload = new CustomUpload();
        uploadConfig.imageData = blob;
        uploadConfig.usageName = this.localeService.translate("restaurant_info_documents_upload_tooltip"); // usage
        uploadConfig.supportedFiles = VERIFICATION_DOCUMENTS_FILE_TYPES;
        modalConfig.data = uploadConfig;

        this.modalService.openCustomDialog(UploadImageModal, modalConfig);
    }

    public onDeleteDocument(doc: DocumentResponse) {       

        const config = this.modalService.getDefaultDialogConfig();
        config.width = "500px";
        config.data = {
            title: new LocaleTranslatePipe(this.localeService).transform("menu_verification_document_modal_delete_title"),
            midContent: new LocaleTranslatePipe(this.localeService).transform("menu_verification_document_modal_delete_body"),
            leftButtonContent: new LocaleTranslatePipe(this.localeService).transform("generic_modal_default_left_action"),
            rightButtonContent: new LocaleTranslatePipe(this.localeService).transform("generic_modal_default_right_action"),
            preselectedButton: "left"
        }

        // ask if you're sure
        this.modalService.openConfirmDialog$(config).subscribe((response) => {
            if (response) { // true
                this.restaurantInfoService.deleteVerificationDocument(doc);
            }
        });
    }

    public onTabChange(event: MatTabChangeEvent) {        
        this.changeTab(event.tab.ariaLabel, event.index);        
    } 

    private changeTab(tabName: string, index: number) {
        this.currentTab.next(tabName);
        this.currentTabIndex$.next(index);

        switch (tabName) {
            case RestaurantInfoTab.PUBLIC: {
                this.router.navigate([RestaurantTabRoute.PUBLIC]);
                break;
            }
            case RestaurantInfoTab.OPENING_HOURS: {
                this.router.navigate([RestaurantTabRoute.OPENING_HOURS]);
                break;
            }
            case RestaurantInfoTab.DELIVERY: {
                this.router.navigate([RestaurantTabRoute.DELIVERY]);
                break;
            }
            case RestaurantInfoTab.PRIVATE: {
                this.router.navigate([RestaurantTabRoute.PRIVATE]);
                break;
            }
        }        
    }

    /*
        Timezone logic
    */

    public getTimezoneText(timezone: string): string {    
        if (!timezone && !this.getRestaurantData().timeZone) {
            const temp = this.getRestaurantData();
            temp.timeZone = "+01:00"; // default Vienna
            this.setRestaurantData(temp);
        }
        return "(" + timezone + ") " + this.timezoneService.getTimezoneCity(timezone);
    }

    public getAllTimezones(): string[] {  
        return this.timezoneService.getAllTimezonesFull();
    }

    public onMouseHoverTimezone(event: any) {
        this.showTimezoneEdit = true;
    }

    public onMouseLeaveTimezone(event: any) {
        this.showTimezoneEdit = false;
    }

    public onClickTimezoneEdit(event: any) {
        this.showTimezoneDropdown = true;
    }

    public onSelectTimezone($event: any) {
        const selectedIndex = $event;
        let selected = this.getAllTimezones()[selectedIndex];
        let newOffset = this.timezoneService.getOffsetByTimezoneFull(selected);
        // check if it changed compared to the already stored one
        const temp = this.getRestaurantData();
        if (temp.timeZone !== newOffset) {
            temp.timeZone = newOffset;
            this.setRestaurantData(temp);
        }
    }

    public ngOnDestroy(): void {
        if (this.routeSub) {
          this.routeSub.unsubscribe();
        }
    }
}

export enum RestaurantInfoTab {
    PUBLIC          = "public",
    OPENING_HOURS   = "opening_hours",
    DELIVERY        = "delivery",
    PRIVATE         = "private"
}

export enum RestaurantTabRoute {
    PUBLIC          = "/settings/public",
    OPENING_HOURS   = "/settings/opening-hours",
    DELIVERY        = "/settings/delivery",
    PRIVATE         = "/settings/private"
}