import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { SelfDataService } from './self/self.service';
import { RestaurantInfoService } from './restaurant-info/restaurant-info.service';
import { RestaurantMenuService } from './restaurant-menu/restaurant-menu.service';
import { VerificationService } from './verification-status/verification.service';

@Injectable({
  providedIn: 'root'
})
export class RestaurantServicesInit {

    private allLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
      private selfDataService: SelfDataService,
      private restaurantInfoService: RestaurantInfoService,
      private restaurantMenuService: RestaurantMenuService,
      private verificationService: VerificationService
    ) {
    }

    public initServices() {

        // self
        this.selfDataService.fetchSelfDataAPI();
        // settings
        this.restaurantInfoService.fetchRestaurantAPI();
        // menu
        this.restaurantMenuService.fetchMenuAPI();
        this.restaurantMenuService.fetchCategoriesAPI();
        this.restaurantMenuService.fetchProductsGroupedAPI();
        this.restaurantMenuService.fetchAddonsListAPI();

        combineLatest([
            this.selfDataService.isFinished$(),
            this.restaurantInfoService.isFinished$(),
            this.restaurantMenuService.isFinished$(),
            this.verificationService.isFinished$()
        ]).subscribe(
            ([self, settings, menu, verification]) => {

                // if already loaded before and some service emits again, ignore it
                if (this.allLoaded$.getValue()) {
                    return; 
                }

                // make sure all are loaded
                if (self && settings && menu && verification) {
                    this.allLoaded$.next(true);
                }            
            }
        );
    }

    public allServicesLoaded$(): Observable<boolean> {
        return this.allLoaded$.asObservable();
    }
}
