import { BusinessHoursNameEnum, BusinessHoursPeriod } from "libs/shared-models/src/lib/restaurant/business-hours";

export class CustomBusinessHours {
    
    public businessHoursDelivery: BusinessHoursPeriod[] = [];
    public businessHoursPickup: BusinessHoursPeriod[] = []
    public day: BusinessHoursNameEnum = BusinessHoursNameEnum.NONE;
    public timezone: string = "";

    constructor() {
    }
}