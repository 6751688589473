import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestaurantInfoService } from '../../../services/restaurant-info/restaurant-info.service';
import { BehaviorSubject } from 'rxjs';
import { RestaurantVerificationEnum } from "libs/shared-models/src/lib/restaurant/restaurant-response";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RestaurantOrdersService } from '../../../services/restaurant-orders/restaurant-orders.service';

@UntilDestroy()
@Component({
  selector: 'web-foodis-section-orders',
  templateUrl: './section-orders.component.html',
  styleUrls: ['./section-orders.component.scss'],
})
export class SectionOrdersComponent implements OnInit {

  public isVerified$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  
  constructor(
    private router: Router,
    private restaurantInfoService: RestaurantInfoService,
    private restaurantOrdersService: RestaurantOrdersService
  ) {
    
  }

  public ngOnInit() {
    const status = this.restaurantInfoService.getState$().pipe(untilDestroyed(this)).subscribe((res) => {
      this.isVerified$.next(res.verificationStatus === RestaurantVerificationEnum.VERIFIED);
    })   

    this.restaurantOrdersService.fetchOrdersListAPI();
  }

  public onPressVerify() {
    this.router.navigateByUrl("/dashboard");
  }

}
