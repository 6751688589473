import {Inject, Injectable} from '@angular/core';
import {ApiBaseService} from "libs/shared-services/src/lib/api-base.service";
import {ToasterService} from "libs/shared-services/src/lib/toaster.service";

import {BehaviorSubject, catchError, map, Observable, of} from "rxjs";
import { LocaleService } from 'libs/shared-services/src/lib/locale.service';
import { BaseLoadingService } from 'libs/shared-services/src/lib/base-loading';
import { environment } from 'apps/restaurant/src/environments/environment';
import { StripeAccountSessionResponse } from '../model/stripe-account-session-response';
import { RestaurantFinanceStateService } from '../data-store/restaurant-finance.state';

@Injectable({
    providedIn: 'root',
})
export class RestaurantFinanceService extends BaseLoadingService {

    constructor(
        private apiService: ApiBaseService,
        private toasterService: ToasterService,
        private localeService: LocaleService,
        private financeStateService: RestaurantFinanceStateService
    ) {
        super();
        this.setFinished();
    }

    public requestStripeAccountCreation$(): Observable<boolean> {
        const apiCall = this.apiService.post(environment.API_POST_STRIPE_CREATE_ACCOUNT, {
            // empty request
        });

        return apiCall.pipe(
            map((res) => {
                //const resParsed = Object.assign(new Something(), res);
                this.toasterService.showSuccess("",this.localeService.translate("Stripe Account Successfully created"));
                return true;
            }),
            catchError((err: any, caught: Observable<any>): Observable<any> => {
                this.toasterService.showError("Error", err?.error?.message);
                return of(false);
            })
       );
    }

    public requestStripeAccountSession$(): Observable<boolean> {
        const apiCall = this.apiService.post(environment.API_POST_STRIPE_CREATE_ACCOUNT_SESSION, {
            // empty request
        });

        return apiCall.pipe(
            map((res) => {
                const parsed = Object.assign(new StripeAccountSessionResponse(), res);
                this.financeStateService.setData(parsed);
                this.toasterService.showSuccess("",this.localeService.translate("Received client secret"));
                return true;
            }),
            catchError((err: any, caught: Observable<any>): Observable<any> => {
                this.toasterService.showError("Error", err?.error?.message);
                return of(false);
            })
       );
    }

    public getSessionData$(): Observable<StripeAccountSessionResponse> {
        return this.financeStateService.getData$();
    }

    public getSessionData(): StripeAccountSessionResponse {
        return this.financeStateService.getData();
    }

    public resetSessionData() {
        this.financeStateService.setData(new StripeAccountSessionResponse());
    }
}

