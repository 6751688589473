<div class="main-container">
    <div class="left-menu">
        <side-menu [selected]="selected"></side-menu>
    </div>

    <div class="mid-container">
        <div class="top-navbar-container">
            <nav-bar></nav-bar>
        </div>

        <div class="mid-content">
            <ng-content select="content" class="w-full"></ng-content>
        </div>
    </div>
</div>