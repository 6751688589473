import {BehaviorSubject, find, map, Observable} from "rxjs";
import {Injectable} from "@angular/core";
import { RestaurantResponse } from "libs/shared-models/src/lib/restaurant/restaurant-response";

@Injectable({
    providedIn: 'root',
})
export class RestaurantInfoState {

    private state$: BehaviorSubject<RestaurantResponse> = new BehaviorSubject<RestaurantResponse>(new RestaurantResponse());

    constructor(
    ) {
    }

    public getData$(): Observable<RestaurantResponse> {
        return this.state$.asObservable();
    }

    public getData(): RestaurantResponse {
        return this.state$.getValue();
    }

    public setData(value: RestaurantResponse): void {
        this.state$.next(value); // immutable
    }
}
