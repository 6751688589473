<div class="main-side-container">
    <div class="logo-main-container">
        <div class="logo-container">
            <restaurant-logo></restaurant-logo>
        </div>
    </div>

    <div class="menu-items-container">
        <ng-container *ngFor="let item of menuList | async; index as i">
            <div class="menu-item">
                <a [routerLink]="item.navRoute">
                    <item-side-menu [item]="item"></item-side-menu>
                </a>
            </div>
        </ng-container>
    </div>
</div>