<div class="modal-top-bar">
    <div class="modal-top-left-action"></div>

    <!-- Title -->
    <div class="modal-top-title">
      {{ "restaurant_info_address_edit" | translate }}
    </div>

    <!-- Cancel button -->
    <div class="modal-top-right-action" >
        <div class="action-item" (click)="onCancel()">
            {{ 'menu_product_modal_cancel' | translate }}
        </div>
    </div>
</div>

<div class="modal-content">

    <fs-base-address-editor
        [translations]="(addressTranslationsList$ | async)"
        [addressList]="(getAddresses$() | async) || []"
        (onConfirmAddress)="confirmAddressClick($event)"
        (onBrowserBlockedError)="onBrowserBlockedError()"
        (onBrowserNotSupportedError)="onBrowserNotSupportedError()"
        [usage]="AddressUsageEnum.RESTAURANT"
    ></fs-base-address-editor>

</div>

