import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedUiModule } from '@web-foodis/shared-ui';
import { SharedServicesModule } from '@web-foodis/shared-services';
import { environment } from '../environments/environment';
import { LoginRedirectComponent } from './components/pages/login-redirect/login-redirect.component';
import { CardButtonComponent } from './components/general/card-button/card-button.component';
import { ItemSideMenuComponent } from './components/general/item-side-menu/item-side-menu.component';
import { MainLayoutComponent } from './components/general/main-layout/main-layout.component';
import { NavBarComponent } from './components/general/nav-bar/nav-bar.component';
import { RestaurantLogoComponent } from './components/general/restaurant-logo/restaurant-logo.component';
import { SideMenuComponent } from './components/general/side-menu/side-menu.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { SectionOrdersComponent } from './components/pages/section-orders/section-orders.component';
import { SectionReportsComponent } from './components/pages/section-reports/section-reports.component';
import { SectionInvoicesComponent } from './components/pages/section-invoices/section-invoices.component';
import { SectionMenuComponent } from './components/pages/section-menu/section-menu.component';
import { SectionRestaurantInfoComponent } from './components/pages/section-restaurant-info/section-restaurant-info.component';
import { ProductModalComponent } from './components/pages/section-menu/modals/product-modal/product-modal.component';
import { AddonGroupModalComponent } from "./components/pages/section-menu/modals/addon-group-modal/addon-group-modal.component";
import { AddonItemModalComponent } from "./components/pages/section-menu/modals/addon-item-modal/addon-item-modal.component";
import { CategoryProductModalComponent } from "./components/pages/section-menu/modals/category-product-modal/category-product-modal.component";
import { UploadImageModal } from './components/general/upload-image-modal/upload-image-modal.component';
import { SectionReviewsComponent } from './components/pages/section-reviews/section-reviews.component';
import { BusinessHoursModalComponent } from './components/pages/section-restaurant-info/modals/business-hours-modal/business-hours-modal.component';
import { RestaurantAddressEditorComponent } from './components/pages/section-restaurant-info/modals/restaurant-address-editor/restaurant-address-editor.component';
import { UnverifiedDashboardComponent } from './components/pages/dashboard/unverified-dashboard/unverified-dashboard.component';
import { InfoStructureModal } from './components/pages/section-menu/modals/info-structure-modal/info-structure-modal.component';
import { SectionAccountsComponent } from './components/pages/section-accounts/section-accounts.component';
import { SectionFinanceComponent } from './components/pages/section-finance/section-finance.component';

// TODO:
// These will need to be moved in a shared components moudle inside Restaurant
const restaurantSharedComponents = [
  CardButtonComponent,
  ItemSideMenuComponent,
  MainLayoutComponent,
  NavBarComponent,
  RestaurantLogoComponent,
  SideMenuComponent,
];

// TODO:
// These will need to be moved with lazy loading when a solution is found
const moduleRoutesComponents = [
  DashboardComponent,
  UnverifiedDashboardComponent,
  SectionOrdersComponent,
  SectionReportsComponent,
  SectionInvoicesComponent,
  SectionMenuComponent,
  SectionRestaurantInfoComponent,
  SectionReviewsComponent,
  SectionFinanceComponent,
  SectionAccountsComponent
];

@NgModule({
  declarations: [
    AppComponent,
    LoginRedirectComponent,
    ...restaurantSharedComponents,
    ...moduleRoutesComponents,
    ProductModalComponent,
    AddonGroupModalComponent,
    AddonItemModalComponent,
    CategoryProductModalComponent,
    UploadImageModal,
    BusinessHoursModalComponent,    
    RestaurantAddressEditorComponent,
    InfoStructureModal
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    SharedServicesModule.forRoot(environment),
    SharedUiModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
