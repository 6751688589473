import {Inject, Injectable} from '@angular/core';
import {ApiBaseService} from "libs/shared-services/src/lib/api-base.service";
import {ToasterService} from "libs/shared-services/src/lib/toaster.service";

import {BehaviorSubject, catchError, map, Observable, of} from "rxjs";
import { LocaleService } from 'libs/shared-services/src/lib/locale.service';
import { BaseLoadingService } from 'libs/shared-services/src/lib/base-loading';
import { InvitationListResponse, InvitationResponse } from '../models/invitation-response';
import { RestaurantTeamUsersResponse, UserResponse } from '../models/user-response';
import { environment } from 'apps/restaurant/src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class RestaurantAccounts extends BaseLoadingService {

    private invitations$: BehaviorSubject<InvitationListResponse> = new BehaviorSubject<InvitationListResponse>(new InvitationListResponse());

    private usersList$: BehaviorSubject<RestaurantTeamUsersResponse> = new BehaviorSubject<RestaurantTeamUsersResponse>(new RestaurantTeamUsersResponse());

    constructor(
        private apiService: ApiBaseService,
        private toasterService: ToasterService,
        private localeService: LocaleService
    ) {
        super();
        this.setFinished();
    }

    public getUsersList$(): Observable<RestaurantTeamUsersResponse> {
        return this.usersList$.asObservable();
    }

    public fetchInvitations() {

        this.apiService.get(environment.API_GET_POST_KITCHEN_INVITATION).subscribe({
            next: (res: any) => {
                let data = Object.assign(new InvitationListResponse(), res);
                this.invitations$.next(data);                
            },
            error: (err) => {
                this.toasterService.showError("Error", err?.error?.message);
            }
        });
    }

    public requestInvitationApi$(kitchenEmail: string): Observable<boolean> {
        const apiCall = this.apiService.post(environment.API_GET_POST_KITCHEN_INVITATION, {
            email: kitchenEmail
        });

        return apiCall.pipe(
            map((res) => {
                //const ordersRes = Object.assign(new OrderListResponse(), res);
                this.toasterService.showSuccess("",this.localeService.translate("accounts_kitchen_invite_successful"));
                // reload invitations
                this.fetchInvitations();
                return true;
            }),
            catchError((err: any, caught: Observable<any>): Observable<any> => {
                this.toasterService.showError("Error", err?.error?.message);
                return of(false);
            })
       );
    }


    public fetchUsersListApi() {
        this.apiService.get(environment.API_GET_TEAM_USERS_LIST).subscribe({
            next: (res: any) => {
                let data = Object.assign(new RestaurantTeamUsersResponse(), res);
                this.usersList$.next(data);                
            },
            error: (err) => {
                this.toasterService.showError("Error", err?.error?.message);
            }
        });
    }

    public getInvitations$(): Observable<InvitationListResponse> {
        return this.invitations$.asObservable();
    }

    public deleteUser$(userId: string): Observable<boolean> {
        // DELETE
        let apiCall = this.apiService.delete(environment.API_GET_DELETE_TEAM_USER.replace('{userId}', userId));
        return apiCall.pipe(
          map((res) => {
            this.toasterService.showSuccess("", this.localeService.translate("accounts_user_removed_successfully"));
            return true;
          }),
          catchError((err: any, caught: Observable<any>): Observable<any> => {
            this.toasterService.showError("Error", err?.error?.message);
            return of(false);
          })
        );
    }
    
}

