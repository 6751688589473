import { Component, OnInit } from '@angular/core';
import { AuthService } from 'libs/shared-services/src/lib/auth.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { CurrentProductService } from 'libs/shared-services/src/lib/current-product.service';
import { Product } from 'libs/shared-models/src/lib/product-type';

@Component({
  selector: 'login-redirect',
  templateUrl: './login-redirect.component.html',
  styleUrls: ['./login-redirect.component.scss'],
})
export class LoginRedirectComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private currentProductService: CurrentProductService
  ) {}

  public ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this.router.navigateByUrl('dashboard');
    } else {
      this.currentProductService.setProduct(Product.RESTAURANT);
      window.location.href = environment.PRODUCT_URLS.ACCOUNT + '/login';
    }
  }
}
