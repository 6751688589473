<div class="modal-top-bar">
    <div class="modal-top-left-action"></div>

    <!-- Title -->
    <div class="modal-top-title">
      <mat-icon class="button-info-icon">info</mat-icon>{{ 'menu_info_modal_button' | translate }}
    </div>

    <!-- Cancel button -->
    <div class="modal-top-right-action" >
        <div class="action-item" (click)="onCancel()">
            {{ 'menu_product_modal_cancel' | translate }}
        </div>
    </div>
</div>

<div class="modal-content">
    
    <mat-tab-group (selectedTabChange)="onTabChange($event)" [selectedIndex]="(currentTabIndex$ | async) || 0" class="h-full">
      
      <!-- Products & categories -->
      <mat-tab [aria-label]="'products'">
        <!-- Tab and title -->
        <ng-template mat-tab-label>  
          <div class="pl-2">
            {{ 'menu_info_modal_image_products' | translate }}
          </div>
        </ng-template>

        <!-- Content -->
        <div class="image-container">
          <img [src]="'apps/restaurant/src/assets/explanation-product-structure-' 
            + ((isGermanLanguage$ | async) ? 'de' : 'en')
            + '.jpg'"/>
        </div>
      </mat-tab>

      <!-- Addons & groups -->
      <mat-tab [aria-label]="'addons'">
        <!-- Tab and title -->
        <ng-template mat-tab-label>  
          <div class="pl-2">
            {{ 'menu_info_modal_image_addons' | translate }}
          </div>
        </ng-template>

        <!-- Content -->
        <div class="image-container">
          <img [src]="'apps/restaurant/src/assets/explanation-addon-structure-' 
            + ((isGermanLanguage$ | async) ? 'de' : 'en')
            + '.jpg'"/>
        </div>
      </mat-tab>
    </mat-tab-group>
</div>

