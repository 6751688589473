import { Injectable} from '@angular/core';
import { ApiBaseService } from 'libs/shared-services/src/lib/api-base.service';
import { ToasterService } from 'libs/shared-services/src/lib/toaster.service';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';
import { OrderListResponse, OrderResponse } from 'libs/shared-models/src/lib/order/order-response';

import { RestaurantOrdersStateService } from './data-store/restaurant-orders.state';
import { environment } from 'apps/restaurant/src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class RestaurantOrdersService {

    private isLoadingOrdersFromAPI$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    // TODO: maybe add loading state for this one too (restaurant init services)
    constructor(
        private apiService: ApiBaseService,
        private toasterService: ToasterService,
        private ordersStateService: RestaurantOrdersStateService
    ) {
    }

    public fetchOrdersListAPI() {
        this.isLoadingOrdersFromAPI$.next(true);
        this.fetchOrdersListAPI$().subscribe((res) => {
            if (res) {
                this.ordersStateService.setData(res.results);

                // (re)select the opened order
                this.refreshCurrentSelectedOrder();
            }
            this.isLoadingOrdersFromAPI$.next(false);
        })
    }

    public isLoadingOrdersAPI$(): Observable<boolean> {
        return this.isLoadingOrdersFromAPI$.asObservable();
    }

    /*
        Fetch all orders list (for the Orders section)
    */
    private fetchOrdersListAPI$(): Observable<OrderListResponse> {
        const url = environment.API_GET_ORDERS_LIST + "?pageSize=100";
        const apiCall = this.apiService.get(url);
        return apiCall.pipe(
            map((res) => {
                const ordersRes = Object.assign(new OrderListResponse(), res);                
                return ordersRes;
            }),
            catchError((err: any, caught: Observable<any>): Observable<any> => {
                this.toasterService.showError("Error", err?.error?.message);
                return of({});
            })
       );
    }

    public ordersList$(): Observable<OrderResponse[]> {
        return this.ordersStateService.getData$();
    }


    public getCurrentSelectedOrder(): OrderResponse | null {
        return this.ordersStateService.getCurrentSelectedOrder();
    }

    public getCurrentSelectedOrder$(): Observable<OrderResponse | null> {
        return this.ordersStateService.getCurrentSelectedOrder$();
    }

    public setCurrentSelectedOrder(order: OrderResponse) {
        this.ordersStateService.setCurrentSelectedOrder(order); 
    }

    public refreshCurrentSelectedOrder() {
        const list = this.ordersStateService.getData();
        const alreadyPreselected = this.ordersStateService.getCurrentSelectedOrder();
        const preselectedElement = list.find((item) => item.id === alreadyPreselected?.id);

        if (!!preselectedElement) {
            this.ordersStateService.setCurrentSelectedOrder(preselectedElement); 
        } else {
            this.preselectFirstOrder();
        }
    }

    public preselectFirstOrder() {
        if (this.ordersStateService.getData()[0]) {
            this.ordersStateService.setCurrentSelectedOrder(this.ordersStateService.getData()[0]);
            return;
        }
        // default
        this.ordersStateService.setCurrentSelectedOrder(null);        
    }
}