<main-layout [selected]="'RESTAURANT_INFO'">
    <content class="w-full">
        <div class="menu-main-container">
            <!-- Section -->
            <ng-container>
                <div class="r-info-container">

                    <mat-tab-group (selectedTabChange)="onTabChange($event)" [selectedIndex]="(currentTabIndex$ | async)" class="h-full">

                        <!--
                            ******************  Public Profile tab  ******************
                        -->
                        <mat-tab [aria-label]="'public'">
                            <!-- Tab and title -->
                            <ng-template mat-tab-label>
                                <img class="r-info-tab-icon"
                                     [src]="'apps/restaurant/src/assets/res-info-public-profile' + ((currentTab | async) === RestaurantInfoTab.PUBLIC ? '-selected' : '') + '.svg'"
                                />
                                <div class="pl-2">
                                    {{ 'restaurant_info_tab_public_profile' | translate }}
                                </div>
                            </ng-template>

                            <!-- Content -->
                            <div class="r-info-availability">
                                <div class="r-info-availability-scrollable">

                                <!-- Logo & Background image -->
                                <div class="r-info-logo-background">
                                    
                                     <!-- Logo -->
                                     <div class="r-info-logo-container" (mousedown)="onLogoImagePress()"
                                        #tooltip="matTooltip"
                                        [matTooltip]="'restaurant_interdiction_logo_tooltip' | translate"
                                        [matTooltipPosition]="'below'"
                                        [matTooltipDisabled]="!!(restaurantData$ | async)?.id"
                                        [matTooltipClass]="'custom-interdiction-tooltip'">
                                        
                                        <!-- Logo Hover tooltip -->
                                        <div class="r-info-background-tooltip">
                                            {{ 'restaurant_info_logo_tooltip' | translate }}
                                        </div>

                                        <!-- Logo - The (real) image -->
                                        <ng-container *ngIf="!!(restaurantData$ | async)?.logoImageUrl; else defaultLogoImage">
                                            <div class="restaurant-logo-image-filled">
                                                <img class="product-image-src" [src]="(restaurantData$ | async)?.logoImageUrl || 'apps/restaurant/src/assets/add-image-default.svg'"/>
                                            </div>
                                        </ng-container>

                                        <!-- Logo - Default (not set) image -->
                                        <ng-template #defaultLogoImage>
                                            <div class="r-default-logo-main-container">
                                                <div class="r-default-logo-image-container">
                                                    <!-- Image -->
                                                    <div class="r-restaurant-logo-image-default">
                                                        <div class="r-restaurant-logo-icon-default">
                                                            <img [src]="'apps/restaurant/src/assets/add-image-default.svg'"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                    
                                    <!-- Background -->
                                    <div class="r-info-background-container" (mousedown)="onBackgroundImagePress()"
                                        #tooltip="matTooltip"
                                        [matTooltip]="'restaurant_interdiction_logo_tooltip' | translate"
                                        [matTooltipPosition]="'below'"
                                        [matTooltipDisabled]="!!(restaurantData$ | async)?.id"
                                        [matTooltipClass]="'custom-interdiction-tooltip'">

                                        <!-- Background Hover tooltip -->
                                        <div class="r-info-background-tooltip">
                                            {{ 'restaurant_info_main_image_tooltip' | translate }}
                                        </div>

                                        <!-- Background -  The (real) image -->
                                        <ng-container *ngIf="!!(restaurantData$ | async)?.mainImageUrl; else defaultBackgroundImage">
                                            <div class="restaurant-image-filled">
                                                <img class="product-image-src" [src]="(restaurantData$ | async)?.mainImageUrl || 'apps/restaurant/src/assets/add-image-default.svg'"/>
                                            </div>
                                        </ng-container>
                                
                                        <!-- Background - Default (not set) image -->
                                        <ng-template #defaultBackgroundImage>
                                            <div class="r-default-background-main-container">
                                                <div class="r-default-background-image-container">
                                                    <!-- Image -->
                                                    <div class="r-restaurant-background-image-default">
                                                        <div class="r-restaurant-background-icon-default">
                                                            <img [src]="'apps/restaurant/src/assets/add-image-default.svg'"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>

                                <!-- Name -->
                                <div class="r-info-public-name">

                                    <!-- Input name -->
                                    <fs-input [label]="'menu_add_section_details_name' | translate" [style]="'full-width'" class="mr-6 r-info-public-name-input"
                                              [inputValue]="(restaurantData$ | async)?.name || ''"
                                              (valueUpdate)="onNameUpdate($event)" 
                                              [disabled]="(editBlocked$() | async) || false">
                                    </fs-input>

                                    <!-- Info blue box -->
                                    <div class="r-info-name-default-box">
                                        <!-- Info icon -->
                                        <div class="r-info-name-default-box-icon">
                                            <img [src]="'apps/restaurant/src/assets/add-menu-blue-info.svg'"/>
                                        </div>
                                        <!-- Default text -->
                                        <div class="r-info-name-default-box-text">
                                            <div class="r-info-name-box-title">{{ 'restaurant_info_name_info_default_1' | translate }}</div>
                                            <div class="r-info-name-box-subtitle">{{ 'restaurant_info_name_info_default_2' | translate }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="container-decription-categories">
                                    <!-- Description -->
                                    <div class="container-description">
                                        <fs-textarea [label]="'restaurant_info_description' | translate"
                                                    [inputValue]="(restaurantData$ | async)?.description || ''"
                                                    (valueUpdate)="onDescriptionUpdate($event)"
                                        ></fs-textarea>
                                    </div>

                                    <!-- Restaurant categories-->
                                    <div class="container-categories">
                                        <fs-chip-list
                                            [title]="'restaurant_info_category_title' | translate"
                                            [searchPlaceholder]="'restaurant_info_category_more' | translate"
                                            [limitReached]="'restaurant_info_category_limit' | translate"
                                            [preselectedList]="(getSelectedCuisinesList$() | async) || []"
                                            [options]="(getAllCuisinesList$() | async) || []"
                                            [leadingIcon]="'restaurant'"
                                            [trailingIcon]="'keyboard_arrow_down'"
                                            [maxSelections]="3"
                                            (onListChange)="onRestaurantCuisineUpdate($event)"
                                        ></fs-chip-list>
                                    </div>
                                </div>                                

                                <div class="r-info-public-name">
                                    <!-- Email -->
                                    <fs-input [label]="'restaurant_info_email' | translate" [style]="'full-width'" class="mr-6 r-info-public-name-input"
                                              [inputValue]="(restaurantData$ | async)?.email || ''"
                                              (valueUpdate)="onEmailUpdate($event)"></fs-input>
                                    <!-- Website -->
                                    <fs-input [label]="'restaurant_info_website' | translate" [style]="'full-width'" class="mr-6 r-info-public-name-input"
                                              [inputValue]="(restaurantData$ | async)?.website || ''"
                                              (valueUpdate)="onWebsiteUpdate($event)"></fs-input>
                                </div>

                                <div class="r-info-public-name">
                                    <!-- Contact number -->
                                    <fs-input [label]="'restaurant_info_phone' | translate" [style]="'full-width'" class="mr-6 r-info-public-name-input"
                                              (valueUpdate)="onPhoneUpdate($event)"
                                              [inputValue]="(restaurantData$ | async)?.phoneNumber || ''"></fs-input>
                                    <!-- Address -->
                                    <fs-input [label]="'restaurant_info_address' | translate" [style]="'full-width'" class="mr-6 r-info-public-name-input r-info-address-action"
                                              [inputValue]="(getAddressCombined$() | async) || ''"
                                              (mousedown)="onAddressPress()"    
                                              [disabled]="!(restaurantData$ | async)?.id"                                          
                                                #tooltip="matTooltip"
                                                [matTooltip]="'restaurant_interdiction_address_tooltip' | translate"
                                                [matTooltipPosition]="'below'"
                                                [matTooltipDisabled]="!!(restaurantData$ | async)?.id"
                                                [matTooltipClass]="'custom-interdiction-tooltip'"                                              
                                                [disabled]="(editBlocked$() | async) || !(restaurantData$ | async)?.id || false"
                                              ></fs-input>
                                    <!-- Search Address -->
                                    <div *ngIf="(editBlocked$() | async) === false" class="r-info-name-address-icon r-info-address-action" (mousedown)="onAddressPress()"                                            
                                            #tooltip="matTooltip"
                                            [matTooltip]="'restaurant_interdiction_address_tooltip' | translate"
                                            [matTooltipPosition]="'below'"
                                            [matTooltipDisabled]="!!(restaurantData$ | async)?.id"
                                            [matTooltipClass]="'custom-interdiction-tooltip'">
                                        <img [src]="'apps/restaurant/src/assets/location-pin-main.svg'"/>
                                    </div>
                                </div>


                                <!-- Save button -->
                                <div class="add-new-create">
                                    <fs-button [text]="((!!(restaurantData$ | async)?.id) ? 'restaurant_info_availability_save' : 'restaurant_info_availability_create') | translate" [style]="'full-width-large-height'"
                                               [type]="'raised-primary'" (onPress)="submitData()"
                                               [disabled]="(!!(restaurantData$ | async)?.id && (saveButtonDisabled$ | async)) || false"
                                    ></fs-button>
                                </div>
                                </div>
                            </div>
                        </mat-tab>

                        <!--
                            ******************  Opening hours tab  ******************
                        -->
                        <mat-tab [aria-label]="'opening_hours'">
                            <!-- Tab icon and title -->
                            <ng-template mat-tab-label>
                                <img class="r-info-tab-icon"
                                     [src]="'apps/restaurant/src/assets/res-info-opening-hours' + ((currentTab | async) === RestaurantInfoTab.OPENING_HOURS ? '-selected' : '') + '.svg'"
                                />
                                <div class="pl-2">
                                    {{ 'restaurant_info_tab_opening_hours' | translate }}
                                </div>
                            </ng-template>

                            <!-- Content -->

                            <!-- Availability -->
                            <div class="r-info-availability">
                                <div class="r-info-availability-scrollable">
                                    <div class="r-info-availability-text">
                                        {{ "restaurant_info_regular_hours" | translate }}
                                    </div>
    
                                    <!-- Type -->
                                    <div class="r-info-hours-container"                                    
                                            #tooltip="matTooltip"
                                            [matTooltip]="'restaurant_interdiction_hours_tooltip' | translate"
                                            [matTooltipPosition]="'above'"
                                            [matTooltipDisabled]="!!(restaurantData$ | async)?.id"
                                            (mousedown)="tooltip.toggle()"
                                            [matTooltipClass]="'custom-interdiction-tooltip'">
                                        <div class="r-info-hours-row header">
                                            <div class="r-info-hours-col-1">{{ "restaurant_info_hours_day" | translate }}</div>
                                            <div class="r-info-hours-col-2 row">
                                                <div class="r-info-hours-item-icon">
                                                    <img [src]="'apps/restaurant/src/assets/delivery-type-pick-up-hand-selected.svg'"/>
                                                </div>
                                                {{ "restaurant_info_hours_pickup" | translate }}
                                            </div>
                                            <div class="r-info-hours-col-3 row">
                                                <div class="r-info-hours-item-icon">
                                                    <img [src]="'apps/restaurant/src/assets/delivery-type-bicycle-selected.svg'"/>
                                                </div>
                                                {{ "restaurant_info_hours_delivery" | translate }}
                                            </div>
                                        </div>

                                        <!-- Loop through all business days -->
                                        <ng-container *ngFor="let day of DAYS_LIST">
                                            <div class="r-info-hours-row" (mousedown)="onPressHours($event, day)">                       
                                                <div class="r-info-hours-col-1"> {{ ("restaurant_info_availability_" + day) | translate }}</div>
                                                <!-- Pick-up hours -->
                                                <div class="r-info-hours-col-2">                                                     
                                                    <ng-container *ngIf="((restaurantData$ | async)?.businessHoursPickup?.[day] || []).length === 0">
                                                        <ng-container *ngTemplateOutlet="closedHours"></ng-container>
                                                    </ng-container>
                                                    <ng-container *ngFor="let item of ((restaurantData$ | async)?.businessHoursPickup?.[day] || [])">
                                                        <div>{{ 
                                                            (item.openTime | timezone_utc_to_zone: ((restaurantData$ | async)?.timeZone || '')) + " - " + 
                                                            (item.closeTime | timezone_utc_to_zone: ((restaurantData$ | async)?.timeZone || ''))
                                                        }}</div>
                                                    </ng-container>
                                                </div>
                                                <!-- Delivery hours -->
                                                <div class="r-info-hours-col-3">
                                                    <ng-container *ngIf="((restaurantData$ | async)?.businessHoursDelivery?.[day] || []).length === 0">
                                                        <ng-container *ngTemplateOutlet="closedHours"></ng-container>
                                                    </ng-container>
                                                    <ng-container *ngFor="let item of ((restaurantData$ | async)?.businessHoursDelivery?.[day] || [])">
                                                        <div>{{ 
                                                            (item.openTime | timezone_utc_to_zone: ((restaurantData$ | async)?.timeZone || '')) + " - " + 
                                                            (item.closeTime | timezone_utc_to_zone: ((restaurantData$ | async)?.timeZone || ''))
                                                        }}</div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </ng-container>    
                                    </div>
    
                                    <!-- Closed red button state template -->
                                    <ng-template #closedHours>
                                        <div class="flex items-center">
                                            <img class="closedHoursImage" [src]="'apps/restaurant/src/assets/status-unavailable-red.svg'" [title]="'menu_product_modal_unavailable' | translate"/> {{ "restaurant_info_hours_closed" | translate }}
                                        </div>                                        
                                    </ng-template>    

                                    <!-- Timezone text -->
                                    <div class="r-info-availability-utc-text" (mouseenter)="onMouseHoverTimezone($event)"  (mouseleave)="onMouseLeaveTimezone($event)">
                                        {{ "restaurant_info_hours_info" | translate }} 

                                        <!-- Dropdown -->
                                        <ng-container *ngIf="showTimezoneDropdown; else timezoneNotEditable">
                                            <fs-menu-simple
                                                [itemsList]='getAllTimezones() || []'
                                                [itemValue]="getTimezoneText((restaurantData$ | async)?.timeZone || '')"
                                                [smallHeight]="true"
                                                (onSelect)="onSelectTimezone($event)">
                                            </fs-menu-simple>
                                        </ng-container>         

                                        <!-- current timezone text -->
                                        <ng-template #timezoneNotEditable>
                                            {{ getTimezoneText((restaurantData$ | async)?.timeZone || '')}}
                                        </ng-template>
                                        
                                        <!-- Edit timezone button -->
                                        <ng-container *ngIf="showTimezoneEdit && !showTimezoneDropdown">
                                            <div class="addon-edit-area" (mousedown)="onClickTimezoneEdit($event)">
                                            <span class="addon-edit-image">
                                                <img [src]="'apps/restaurant/src/assets/edit-pencil.svg'"/>
                                            </span>
                                            {{ "menu_addon_section_edit_button" | translate }}
                                            </div>
                                        </ng-container>                                                                       
                                    </div>
    
                                    <!-- Save button -->
                                    <div class="add-new-create">
                                        <fs-button [text]="((!!(restaurantData$ | async)?.id) ? 'restaurant_info_availability_save' : 'restaurant_info_availability_create') | translate" [style]="'full-width-large-height'"
                                                   [type]="'raised-primary'" (onPress)="submitData()"
                                                   [disabled]="(!!(restaurantData$ | async)?.id && (saveButtonDisabled$ | async)) || false"
                                        ></fs-button>
                                    </div>

                                </div>                                
                            </div>
                        </mat-tab>


                        <!--
                            ******************  Delivery tab  ******************
                        -->
                        <mat-tab [aria-label]="'delivery'">
                            <!-- Tab icon and title -->
                            <ng-template mat-tab-label>
                                <img class="r-info-tab-icon"
                                     [src]="'apps/restaurant/src/assets/res-info-delivery' + ((currentTab | async) === RestaurantInfoTab.DELIVERY ? '-selected' : '') + '.svg'"
                                />
                                <div class="pl-2">
                                    {{ 'restaurant_info_tab_delivery' | translate }}
                                </div>
                            </ng-template>


                            <!-- Content -->
                            <!-- Delivery type -->
                            <div class="r-info-availability">
                                <div class="r-info-availability-scrollable">
                                    <div class="flex">
                                        <div class="r-info-delivery-left-side">
                                            <div class="r-info-delivery">
                                                <div class="r-info-delivery-text">
                                                    {{ "restaurant_info_delivery_type" | translate }}
                                                </div>
                                                <div class="r-info-delivery-options">
                                                    <!-- Pick-up -->
                                                    <div class="r-info-delivery-item">
                                                        <div class="r-info-delivery-item-icon">
                                                            <img [src]="'apps/restaurant/src/assets/delivery-type-pick-up-hand-selected.svg'"/>
                                                        </div>
                                                        <fs-checkbox [text]="'restaurant_info_type_pickup' | translate" [checked]="(restaurantData$ | async)?.hasPickup || false" (valueChanged)="onPickupDeliveryPress($event)"></fs-checkbox>
                                                    </div>
                                                    <div class="r-info-delivery-item">
                                                        <div class="r-info-delivery-item-icon">
                                                            <img [src]="'apps/restaurant/src/assets/delivery-type-bicycle-selected.svg'"/>
                                                        </div>
                                                        <!-- Own driver delivery -->
                                                        <div class="r-info-delivery-options-driver">
                                                            <fs-checkbox [text]="'restaurant_info_type_delivery_restaurant' | translate"
                                                                        (valueChanged)="onRestaurantDeliveryPress($event)"
                                                                        [checked]="(restaurantData$ | async)?.hasOwnDelivery || false"
                                                            ></fs-checkbox>
                                                            <!-- Display tooltip if own delivery is enabled -->
                                                            <ng-container *ngIf="(restaurantData$ | async)?.hasOwnDelivery">
                                                                <div class="tooltip-delivery-foodis ml-2"
                                                                    #tooltip="matTooltip"
                                                                    [matTooltip]="'restaurant_info_delivery_hint' | translate"
                                                                    [matTooltipPosition]="'right'"
                                                                    [matTooltipClass]="'custom-selection-tooltip'"
                                                                    (mousedown)="tooltip.toggle()">

                                                                    <img [src]="'apps/restaurant/src/assets/add-menu-blue-info.svg'"/>                                                            
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                    <div class="r-info-delivery-item">
                                                        <div class="r-info-delivery-item-icon">
                                                            <img [src]="'apps/restaurant/src/assets/delivery-type-bicycle-selected.svg'"/>
                                                        </div>
                                                        <!-- Foodis delivery -->
                                                        <div class="r-info-delivery-options-driver">
                                                            <fs-checkbox [text]="'restaurant_info_type_delivery_foodis' | translate" class="mr-3"
                                                                        (valueChanged)="onFoodisDeliveryPress($event)"
                                                                        [checked]="(restaurantData$ | async)?.hasFoodisDelivery || false"
                                                            ></fs-checkbox>
                                                            <!-- Display tooltip if Foodis delivery is enabled -->
                                                            <ng-container *ngIf="(restaurantData$ | async)?.hasFoodisDelivery">
                                                                <div class="tooltip-delivery-foodis"
                                                                    #tooltip="matTooltip"
                                                                    [matTooltip]="'restaurant_info_delivery_hint' | translate"
                                                                    [matTooltipPosition]="'right'"
                                                                    [matTooltipClass]="'custom-selection-tooltip'"
                                                                    (mousedown)="tooltip.toggle()">

                                                                    <img [src]="'apps/restaurant/src/assets/add-menu-blue-info.svg'"/>                                                            
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>   
                                                </div>
                                            </div>
                
                                            <!-- Delivery time -->
                                            <div class="r-info-delivery small-margin">
                                                <div class="r-info-delivery-text">
                                                    {{ "restaurant_info_delivery_time_title" | translate }}
                                                    <div class="tooltip-delivery-time"
                                                        #tooltip="matTooltip"
                                                        [matTooltip]="'restaurant_info_delivery_time_subtitle' | translate"
                                                        [matTooltipPosition]="'right'"
                                                        [matTooltipClass]="'custom-selection-tooltip'"
                                                        (mousedown)="tooltip.toggle()">
                                                        <img [src]="'apps/restaurant/src/assets/add-menu-blue-info.svg'"/>
                                                    </div>
                                                </div>
                                                <div class="r-info-delivery-options">                                    
                                                    <fs-radio-button [isVertical]="true"
                                                        [options]="deliveryTimeOptions"
                                                        (valueChanged)="deliveryTimeChange($event)"
                                                        [selectedValue]="(restaurantData$ | async)?.deliveryTimeRange">
                                                    </fs-radio-button>
                                                </div>
                                            </div>

                                            <!-- Amount (min)-->
                                            <div class="r-info-delivery small-margin">
                                                <div class="r-info-delivery-text">
                                                    {{ "restaurant_info_delivery_min_amount_title" | translate }}
                                                </div>
                                                <div class="flex mt-4">
                                                    <div class="icon-price-container">
                                                        <img class="icon-price" [src]="'apps/restaurant/src/assets/euro-sign-round.svg'"/>
                                                    </div>
                                                    <fs-input [label]="'restaurant_info_delivery_min_amount_amount' | translate" [style]="'full-width'" class="delivery-amount-input" [type]="'number'"
                                                            [inputValue]="((restaurantData$ | async)?.minOrderAmount || 0) + ''" (valueUpdate)="onMinAmountUpdate($event)" (onKeyUp)="onKeyUpDeliveryAmount($event)">
                                                    </fs-input>
                                                    <!-- Display tooltip if Foodis delivery is enabled -->
                                                    <ng-container *ngIf="(restaurantData$ | async)?.hasFoodisDelivery">
                                                        <div class="tooltip-delivery-time mt-4"
                                                            #tooltip="matTooltip"
                                                            [matTooltip]="'restaurant_info_delivery_min_amount_hint' | translate"
                                                            [matTooltipPosition]="'right'"
                                                            [matTooltipClass]="'custom-selection-tooltip'"
                                                            (mousedown)="tooltip.toggle()">

                                                            <ng-container *ngIf="((restaurantData$ | async)?.minOrderAmount || 0) < 10; else regularAmountInfo">
                                                                <mat-icon class="warning-icon">warning</mat-icon>
                                                            </ng-container>
                                                            <ng-template #regularAmountInfo>
                                                                <img [src]="'apps/restaurant/src/assets/add-menu-blue-info.svg'"/>
                                                            </ng-template>
                                                            
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
            
                                        </div>
            
                                        <div class="r-info-delivery-right-side">
                                            <!-- Delivery range -->
                                            <div class="flex flex-col">
                                                <div class="r-info-delivery-text">
                                                    {{ "restaurant_info_delivery_radius_title" | translate }}
                                                </div>     
                                                <div class="r-info-private-row-1 mt-4">
                                                    <fs-input [label]="'restaurant_info_delivery_radius_label' | translate" [style]="'full-width'" class="mr-6 delivery-radius-input"
                                                        [inputValue]="'' + (restaurantData$ | async)?.deliveryRadiusMeters  || ''"
                                                        (valueUpdate)="onDeliveryRadiusUpdate($event)">
                                                    </fs-input>
                                                    <fs-slider
                                                        class="radius-slider"
                                                        [minValue]="100"
                                                        [maxValue]="2500"
                                                        [step]="100"
                                                        [inputValue]="(restaurantData$ | async)?.deliveryRadiusMeters || 0"
                                                        (onValueChange)="onRadiusSliderChange($event)">
                                                    </fs-slider>
                                                </div>                                           
                                                <div class="radius-map">
                                                    <!-- Map with circle -->
                                                    <ng-container *ngIf="!!(restaurantData$ | async)?.address?.latitude && !!(restaurantData$ | async)?.address?.longitude; else missingAddress">
                                                        <fs-map-lite 
                                                            [address]="(restaurantData$ | async)?.address || null"
                                                            [width]="430"
                                                            [height]="350"
                                                            [radius]="(restaurantData$ | async)?.deliveryRadiusMeters || 0">
                                                        </fs-map-lite>
                                                    </ng-container>

                                                    <!-- Address missing - so the map cannot be drawn -->
                                                    <ng-template #missingAddress>
                                                        <!-- Info blue box -->
                                                        <div class="r-menu-name-default-box">
                                                            <!-- Info icon -->
                                                            <div class="r-menu-name-default-box-icon">
                                                                <img [src]="'apps/restaurant/src/assets/add-menu-blue-info.svg'"/>
                                                            </div>
                                                            <!-- Default text -->
                                                            <div class="r-menu-name-default-box-text">
                                                                <div class="r-menu-name-box-title">{{ 'restaurant_range_address_missing_title' | translate }}</div>
                                                                <div class="r-menu-name-box-subtitle">{{ 'restaurant_range_address_missing_description' | translate }}</div>
                                                            </div>
                                                        </div>
                                                    </ng-template>                                            
                                                </div>
                                            </div>        
                                        </div>
                                    </div>

                                    <!-- Save button -->
                                    <div class="add-new-create small-top">
                                        <fs-button [text]="((!!(restaurantData$ | async)?.id) ? 'restaurant_info_availability_save' : 'restaurant_info_availability_create') | translate" [style]="'full-width-large-height'"
                                            [type]="'raised-primary'" (onPress)="submitData()"
                                            [disabled]="(!!(restaurantData$ | async)?.id && (saveButtonDisabled$ | async)) || false"
                                        ></fs-button>
                                    </div>
                                </div>
                            </div>

                        </mat-tab>

                        <!--
                            ******************  Private tab  ******************
                        -->
                        <mat-tab [aria-label]="'private'">
                            <ng-template mat-tab-label>
                                <img class="r-info-tab-icon"
                                     [src]="'apps/restaurant/src/assets/res-info-private'+ ((currentTab | async) === RestaurantInfoTab.PRIVATE ? '-selected' : '') + '.svg'"
                                />
                                <div class="pl-2">
                                    {{ 'restaurant_info_tab_private' | translate }}
                                </div>
                            </ng-template>
                            <div class="r-info-private">

                                <div class="r-info-private-scrollable">

                                    <ng-container *ngIf="(restaurantData$ | async)?.verificationStatus !== 'VERIFIED'">
                                        <!-- Verification documents -->
                                        <div class="verification-needed-container">
                                            <div class="r-info-delivery-text">
                                                {{ "restaurant_info_private_verification_title" | translate }}
                                            </div>

                                            <!-- Bordered container and texts -->
                                            <div class="verification-documents-border">
                                                <div class="verification-documents-explanation">
                                                    <mat-icon class="warning-icon">warning</mat-icon>
                                                    {{ "restaurant_info_private_verification_subtitle" | translate }}
                                                </div>
                                                
                                                <!-- Needed documents -->
                                                <div class="flex flex-row mt-3">
                                                    <span class="flex flex-row items-center"><img class="r-info-tab-icon mr-2" [src]="'apps/restaurant/src/assets/document-outline-gray.svg'"> {{ "restaurant_info_private_verification_doc_1" |  translate }}</span>
                                                    <span class="flex flex-row items-center ml-4"><img class="r-info-tab-icon mr-2" [src]="'apps/restaurant/src/assets/document-outline-gray.svg'"> {{ "restaurant_info_private_verification_doc_2" |  translate }}</span>
                                                    <span class="flex flex-row items-center ml-4"><img class="r-info-tab-icon mr-2" [src]="'apps/restaurant/src/assets/document-outline-gray.svg'"> {{ "restaurant_info_private_verification_doc_3" |  translate }}</span>
                                                </div>                

                                                <div class="mt-5 mb-5">
                                                    <mat-divider></mat-divider>
                                                </div>
                                                

                                                <!-- Existing documents -->
                                                <div class="existing-uploaded-documents">
                                                    {{ 'restaurant_info_documents_yours' | translate }}

                                                    <ng-container *ngIf="((restaurantData$ | async)?.documents?.length || 0) > 0; else noDocumentsUploaded">
                                                        <div class="verification-documents-list">
                                                            <ng-container *ngFor="let doc of ((restaurantData$ | async)?.documents|| [])">
                                                                <div class="container-document">
                                                                    <a [href]="doc.url || '#'" target="_blank" >
                                                                        <div class="verification-document-item">
                                                                            <mat-icon>arrow_right</mat-icon>
                                                                            <img class="r-info-tab-icon mr-2" [src]="'apps/restaurant/src/assets/file-document.svg'">
                                                                            {{ doc.name }}
                                                                        </div>
                                                                    </a>    
                                                                    <div class="delete-bin" (mousedown)="onDeleteDocument(doc)">
                                                                        <img [src]="'apps/restaurant/src/assets/delete-bin-gray.svg'"/>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>

                                                    <ng-template #noDocumentsUploaded> 
                                                        <span class="verification-documents-none">
                                                            <mat-icon>arrow_right</mat-icon>
                                                            {{ 'restaurant_info_documents_none' | translate }}                                                     
                                                        </span>
                                                    </ng-template>
                                                </div>
                                                
                                                <!-- Buttons: Upload / Submit -->
                                                <div class="upload-documents-buttons">
                                                    <fs-button [text]="'restaurant_info_documents_upload_button' | translate" [style]="'full-width-large-height'"
                                                        [type]="((restaurantData$ | async)?.documents?.length || 0) < 3 ? 'raised-primary' : 'raised'" [leadingIcon]="'add'" (onPress)="onUploadVerificationFilePress()"                                                        
                                                        
                                                        #tooltip="matTooltip"
                                                        [matTooltip]="'restaurant_interdiction_files_tooltip' | translate"
                                                        [matTooltipPosition]="'below'"
                                                        [matTooltipDisabled]="!!(restaurantData$ | async)?.id"
                                                        [matTooltipClass]="'custom-interdiction-tooltip'"    
                                                        [disabled]="(editBlocked$() | async) || false"                                                    
                                                        >
                                                    </fs-button>
                                                </div>

                                            </div>
                                        </div>
                                    </ng-container>

                                <div class="r-info-private-row-1 mt-4">

                                    <!-- Input Legal company name -->
                                    <fs-input [label]="'restaurant_info_private_company' | translate" [style]="'full-width'" class="mr-6 r-info-public-name-input"
                                              [inputValue]="(restaurantData$ | async)?.companyName || ''"
                                              (valueUpdate)="onCompanyNameUpdate($event)"
                                              [disabled]="(editBlocked$() | async) || false"></fs-input>

                                    <!-- Address invoice -->
                                    <fs-input [label]="'restaurant_info_private_invoice_address' | translate" [style]="'full-width'" class="mr-6 r-info-public-name-input r-info-address-action"
                                        [inputValue]="(getInvoiceAddressCombined$() | async) || ''"
                                        (mousedown)="onInvoiceAddressPress()"    
                                        [disabled]="!(restaurantData$ | async)?.id"                                          
                                        #tooltip="matTooltip"
                                        [matTooltip]="'restaurant_interdiction_address_tooltip' | translate"
                                        [matTooltipPosition]="'below'"
                                        [matTooltipDisabled]="!!(restaurantData$ | async)?.id"
                                        [matTooltipClass]="'custom-interdiction-tooltip'"        
                                        [disabled]="(editBlocked$() | async) || !(restaurantData$ | async)?.id || false"                                      
                                        ></fs-input>
                                </div>

                                <div class="r-info-private-row-1">
                                    <!-- Contact number -->
                                    <fs-input [label]="'restaurant_info_private_emergency_phone' | translate" [style]="'full-width'" class="mr-6 r-info-public-name-input"
                                            [inputValue]="(selfData$ | async)?.phoneNumber || ''"
                                            (valueUpdate)="onEmergencyPhoneUpdate($event)"></fs-input>
                                    <!-- IBAN -->
                                    <fs-input [label]="'restaurant_info_private_iban' | translate" [style]="'full-width'" class="mr-6 r-info-public-name-input"
                                            [inputValue]="(restaurantData$ | async)?.ibanAccount || ''" (valueUpdate)="onIbanUpdate($event)"
                                            [disabled]="(editBlocked$() | async) || false"></fs-input>
                                </div>

                                <div class="r-info-private-row-1">
                                    <div class="mr-6 r-info-public-name-input">
                                        <!-- Account email -->
                                        <fs-input [label]="'restaurant_info_private_email' | translate" [style]="'full-width'" class="r-info-public-name-input"
                                                [disabled]="true"                                               
                                                [inputValue]="(selfData$ | async)?.email || ''">
                                        </fs-input>                                        
                                    </div>
                                    <!-- Input VAT number -->
                                    <fs-input [label]="'restaurant_info_private_vat' | translate" [style]="'full-width'" class="mr-6 r-info-public-name-input"
                                              [inputValue]="(restaurantData$ | async)?.vatNumber || ''"
                                              (valueUpdate)="onVatUpdate($event)"
                                              [disabled]="(editBlocked$() | async) || false"></fs-input>

                                </div>
                                <div class="r-info-private-email-disclaimer">* {{'restaurant_info_private_email_disclaimer' | translate}}</div>

                                <!-- Save button -->
                                <div class="add-new-create">
                                    <fs-button [text]="((!!(restaurantData$ | async)?.id) ? 'restaurant_info_availability_save' : 'restaurant_info_availability_create') | translate" [style]="'full-width-large-height'"
                                           [type]="'raised-primary'" (onPress)="submitData()"
                                           [disabled]="(!!(restaurantData$ | async)?.id && (saveButtonDisabled$ | async)) || false"
                                    ></fs-button>
                                </div>

                                
                                </div>
                            </div>
                        </mat-tab>

                    </mat-tab-group>
                </div>
            </ng-container>
        </div>
    </content>
</main-layout>

