import { AddressResponse } from "libs/shared-models/src/lib/address-response";

export class WrapperAddressResponse {
    public address: AddressResponse;
    public popupUsage: WrapperAddressUsageEnum;
}

export enum WrapperAddressUsageEnum {
    ADDRESS = "ADDRESS",
    INVOICE_ADDRESS = "INVOICE_ADDRESS"
}