import { Component } from '@angular/core';
import {BehaviorSubject, map, Observable} from "rxjs";
import {RestaurantMenuService} from "../../../services/restaurant-menu/restaurant-menu.service";
import {MenuListResponse} from "../../../services/restaurant-menu/data-store/model/menu-list-response";
import {MenuCategoryListResponse} from "../../../services/restaurant-menu/data-store/model/menu-category-list-response";
import {MenuCategoryResponse} from "../../../services/restaurant-menu/data-store/model/menu-category-response";
import {v4 as uuidv4} from "uuid";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import { ProductListGroupedByCategoryResponse } from "libs/shared-models/src/lib/restaurant/product-list-grouped-by-category-response";
import {ModalService} from "libs/shared-ui/src/lib/modal-service/modal-service.service";
import {ProductResponse} from "../../../services/restaurant-menu/data-store/model/product-response";
import {ProductModalComponent} from "./modals/product-modal/product-modal.component";
import {ProductSlimInfo} from "libs/shared-models/src/lib/restaurant/product-slim-info";
import {expandCategoryAnimations} from "./expand-animations";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {AddonGroupListResponse} from "../../../services/restaurant-menu/data-store/model/addon-group-list-response";
import {AddonResponse} from "libs/shared-models/src/lib/restaurant/addon-response";
import {AddonGroupResponse} from "libs/shared-models/src/lib/restaurant/addon-group-response";
import {AddonGroupModalComponent} from "./modals/addon-group-modal/addon-group-modal.component";
import {AddonItemModalComponent} from "./modals/addon-item-modal/addon-item-modal.component";
import {AddonLocalResponse} from "../../../services/restaurant-menu/data-store/model/addon-local-response";
import {CategoryLocalResponse} from "../../../services/restaurant-menu/data-store/model/category-local-response";
import {CategoryProductModalComponent} from "./modals/category-product-modal/category-product-modal.component";
import { InfoStructureModal } from './modals/info-structure-modal/info-structure-modal.component';

@Component({
  selector: 'web-foodis-section-menu',
  templateUrl: './section-menu.component.html',
  styleUrls: ['./section-menu.component.scss'],
  animations: expandCategoryAnimations
})
export class SectionMenuComponent {

    /*
        From API / State services
     */
    public menuListState$: Observable<MenuListResponse> = this.menuService.getMenuListState$();
    public menuCategoryListState$: Observable<MenuCategoryListResponse> = this.menuService.getCategoryListState$();
    public productsListState$: Observable<ProductListGroupedByCategoryResponse> = this.menuService.getProductsByCategoryListState$();
    public addonsGroupListState$: Observable<AddonGroupListResponse> = this.menuService.getAddonsGroupListState$();


    /*
        Locally in this component
     */
    public tempAddCategoryList$: BehaviorSubject<MenuCategoryResponse[]> = new BehaviorSubject<MenuCategoryResponse[]>([]);
    public tempCategoriesCollapseState$: BehaviorSubject<CollapseCategoryState[]> = new BehaviorSubject<CollapseCategoryState[]>([]);
    public tempAddonGroupCollapseState$: BehaviorSubject<CollapseCategoryState[]> = new BehaviorSubject<CollapseCategoryState[]>([]);

    public currentTab: BehaviorSubject<string> = new BehaviorSubject<string>('categories');

    // Product Categories: a simple map which keeps flag true/false for when to show "Edit" on hover/out
    public showCategoryEdit = new Map<string, boolean>();
    // Addon Groups: a simple map which keeps flag true/false for when to show "Edit" on hover/out
    public showAddonGroupEdit = new Map<string, boolean>();

    // Keeps search results visibility logic for categories and products
    public productsSearchResults = new Map<string, SearchMap>();
    // Keeps search results visibility logic for add-on groups and add-ons
    public addonsSearchResults = new Map<string, SearchMap>();

    // Category search value
    public currentCategorySearchInput: string = "";
    // Addon search value
    public currentAddonSearchInput: string = "";


    constructor(
        private menuService: RestaurantMenuService,
        private modalService: ModalService
    ) {
    }

    /*
        Add category functionality
     */
    public addCategoryPress() {
        const tempContainer = new MenuCategoryResponse();
        tempContainer.id = "temp-add-category-" + uuidv4();
        const currentList = [...this.tempAddCategoryList$.getValue()];
        currentList.push(tempContainer);
        this.updateAddCategoryState(currentList);
    }

    //  Input updates from user typing in Categories:

    // Category Name update (Add new)
    public onAddNameUpdate(event: string, item: any) {
        let currentList = [...this.tempAddCategoryList$.getValue()];
        currentList = currentList.map((category) => {
            if (category.id === item.id) {
                category.name = event;
            }
            return category;
        })
        this.updateAddCategoryState(currentList);
    }


    // Category Description update (Add new)
    public onAddDescriptionUpdate(event: string, item: any) {
        let currentList = [...this.tempAddCategoryList$.getValue()];
        currentList = currentList.map((category) => {
            if (category.id === item.id) {
                category.description = event;
            }
            return category;
        })
        this.updateAddCategoryState(currentList);
    }

    private updateAddCategoryState(value: MenuCategoryResponse[]) {
        this.tempAddCategoryList$.next(value);
    }

    // Category - Check button ✓ press on creation
    public addCategoryConfirmPress(event: any, item: any) {
        this.menuService.addNewCategoryAPI$(item).subscribe((value) => {
            if (value) {
                this.addCategoryCancelPress(event, item);
            }
        });
    }

    //  Category - Cancel button X press on creation
    public addCategoryCancelPress(event: any, item: any) {
        let currentList = [...this.tempAddCategoryList$.getValue()];
        currentList = currentList.filter((category) => category.id !== item.id)
        this.updateAddCategoryState(currentList);
    }

    // Drag and DROP between categories
    public drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.menuService.getCategoryListState().menuCategoryList, event.previousIndex, event.currentIndex);
    }


    /*
        Add product functionality
     */
    public addProductPress(event: any, categoryId?: string) {
        const tempNewProduct = new ProductResponse();
        tempNewProduct.available = true; // pre-select it in the modal
        if (!!categoryId) {
            tempNewProduct.menuCategoryId = categoryId; // pre-select it in the modal
            tempNewProduct.menuIds = this.menuService.getMenuListState().menuList.map((m) => m.id);
        }
        this.openAddProductModal(tempNewProduct);
    }

    public onProductClick(event: any, product: ProductSlimInfo) {
        this.menuService.fetchFullProductAPI$(product.id).subscribe((product) => {
            if (!!product) {
                const category = this.menuService.getProductsByCategoryListState().categories.find((c) => !!c.products.find((p) => p.id === product.id) )
                if (!!category) {
                    product.menuCategoryId = category.categoryId;
                    product.menuIds = this.menuService.getMenuListState().menuList.map((m) => m.id);
                }
                this.openAddProductModal(product);
            }
        });
    }

    private openAddProductModal(product: ProductResponse) {
        const modalConfig = this.modalService.getDefaultDialogConfig();
        modalConfig.data = product;
        modalConfig.width = "700px";
        modalConfig.height = "650px";
        modalConfig.disableClose = true;
        this.modalService.openCustomDialog(ProductModalComponent, modalConfig);
    }

    public productsPerCategory$(categoryId: any): Observable<ProductSlimInfo[]> {
        return this.productsListState$.pipe(
            map(state => {
                const category = state.categories.find(c => c.categoryId === categoryId);
                if (!!category) {
                    return category.products;
                }
                return [];
            })
        );
    }

    /*
        Category logic:
    */
    public isProductsListVisible$(categoryId: any): Observable<boolean> {
        // Check if the category has products in the linked categories slim response. Also check that even if the category is returned, to make sure products have length
        return this.productsListState$.pipe(
            map(state =>
                !!state.categories.find(c => c.categoryId === categoryId && c.products.length > 0)
            )
        );
    }

    public onClickCategory(event: any, category: MenuCategoryResponse) {
        if (event.target.id === "dragHandler") {
            return; // make sure the drag and drop handler doesn't trigger one extra click to open/close the category
        }

        this.setCategoryCollapse(category.id, true);
    }

    private setCategoryCollapse(categoryId: string, isInversed: boolean = false, value: boolean = true) {
        let list = this.tempCategoriesCollapseState$.getValue();
        let savedCategory = list.find((item) => item.categoryId === categoryId);
        if (!!savedCategory) {
            list = list.map((item) => {
                if (item.categoryId === categoryId) {
                    item.isOpen = isInversed ? !item.isOpen : value;
                }
                return item;
            });

        } else {
            const newCollapse: CollapseCategoryState = new CollapseCategoryState();
            newCollapse.categoryId = categoryId;
            newCollapse.isOpen = isInversed ? true : value;
            list.push(newCollapse);
        }
        this.tempCategoriesCollapseState$.next(list);
    }



    public isCategoryOpen$(categoryId: any): Observable<boolean> {
        return this.tempCategoriesCollapseState$.pipe(
            map(state => {
              return !!state.find(c => c.categoryId === categoryId)?.isOpen;
            })
        );
    }

    public onCategoryMouseEnter($event: any, category: MenuCategoryResponse) {
        this.showCategoryEdit.set(category.id, true);
    }

    public onCategoryMouseLeave($event: any, category: MenuCategoryResponse) {
        this.showCategoryEdit.set(category.id, false);
    }

    public onClickCategoryEdit(event: any, category: MenuCategoryResponse) {
      event.stopPropagation();

      const localResponse = new CategoryLocalResponse();
      localResponse.category = Object.assign(new MenuCategoryResponse(), category);

      const foundMatch = this.menuService.getProductsByCategoryListState().categories.find((item) => item.categoryId === category.id);
      if (foundMatch) {
        localResponse.products = [...foundMatch.products];
      } else {
        localResponse.products = [];
      }

      // expand the category
      this.setCategoryCollapse(category.id, false, true);

      // open modal
      this.openCategoryModal(localResponse);
    }

    private openCategoryModal(localCategory: CategoryLocalResponse) {
      const modalConfig = this.modalService.getDefaultDialogConfig();
      modalConfig.data = localCategory;
      modalConfig.width = "700px";
      modalConfig.disableClose = true;
      this.modalService.openCustomDialog(CategoryProductModalComponent, modalConfig);
    }

    /*
        Collapse / Expand categories (ALL) functionality for Categories
     */

    public onExpandAllCategoriesClick(event: any) {
        this.setAllCategoriesCollapseState(true);
    }

    public onCollapseAllCategoriesClick(event: any) {
        this.setAllCategoriesCollapseState(false);
    }

    private setAllCategoriesCollapseState(state: boolean) {
        let newExpandList = [];
        const categoryList = this.menuService.getCategoryListState().menuCategoryList;
        newExpandList = categoryList.map((category) => {
          const newCollapse: CollapseCategoryState = new CollapseCategoryState();
            newCollapse.categoryId = category.id;
            newCollapse.isOpen = state;
            return newCollapse;
        })
        this.tempCategoriesCollapseState$.next(newExpandList);
    }

    public onTabChange(event: MatTabChangeEvent) {
        this.currentTab.next(event.tab.ariaLabel)
    }


    /*
        Addons / extras functionality
    */

    public addAddonGroupPress() {
        const tempNewAddonGroup = new AddonGroupResponse();
        this.openAddonGroupModal(tempNewAddonGroup);
    }

    private openAddonGroupModal(addonGroup: AddonGroupResponse) {
        const modalConfig = this.modalService.getDefaultDialogConfig();
        modalConfig.data = addonGroup;
        modalConfig.width = "700px";
        modalConfig.disableClose = true;
        this.modalService.openCustomDialog(AddonGroupModalComponent, modalConfig);
    }


    public onClickAddonGroup(event: any, addonGroup: AddonGroupResponse) {
        this.setAddonGroupCollapse(addonGroup.id, true);
    }

    private setAddonGroupCollapse(groupId: string, isInversed: boolean = false, value: boolean = true) {
        let list = this.tempAddonGroupCollapseState$.getValue();
        let savedGroup = list.find((item) => item.categoryId === groupId);
        if (!!savedGroup) {
            list = list.map((item) => {
                if (item.categoryId === groupId) {
                    item.isOpen = isInversed ? !item.isOpen : value;
                }
                return item;
            });

        } else {
            const newCollapse: CollapseCategoryState = new CollapseCategoryState();
            newCollapse.categoryId = groupId;
            newCollapse.isOpen = isInversed ? true : value;
            list.push(newCollapse);
        }
        this.tempAddonGroupCollapseState$.next(list);
    }

    public isAddonGroupOpen$(id: any): Observable<boolean> {
        return this.tempAddonGroupCollapseState$.pipe(
            map(state => {
              return !!state.find(c => c.categoryId === id)?.isOpen;
            })
        );
    }

    public onExpandAllAddonGroupsClick(event: any) {
        this.setAllAddonGroupsCollapseState(true);
    }

    public onCollapseAllAddonGroupsClick(event: any) {
        this.setAllAddonGroupsCollapseState(false);
    }

    private setAllAddonGroupsCollapseState(state: boolean) {
        let newExpandList = [];
        const addonGroupList = this.menuService.getAddonsGroupListState().addonGroupList
        newExpandList = addonGroupList.map((category) => {
            const newCollapse: CollapseCategoryState = new CollapseCategoryState();
            newCollapse.categoryId = category.id;
            newCollapse.isOpen = state;
            return newCollapse;
        })
        this.tempAddonGroupCollapseState$.next(newExpandList);
    }

    public onClickAddonGroupEdit(event: any, addon: AddonGroupResponse) {
        event.stopPropagation();

        // expand the group
        this.setAddonGroupCollapse(addon.id, false, true);

        // open the modal
        this.openAddonGroupModal(addon);
    }

    public onAddonListMouseEnter($event: any, addonGroup: AddonGroupResponse) {
        this.showAddonGroupEdit.set(addonGroup.id, true);
    }

    public onAddonListMouseLeave($event: any, addonGroup: AddonGroupResponse) {
        this.showAddonGroupEdit.set(addonGroup.id, false);
    }

    public addAddonPress(addonGroup: AddonGroupResponse | null = null) {
        const tempNewAddon = new AddonLocalResponse();
        tempNewAddon.available = true; // pre-select it in the modal
        if (addonGroup) {
          tempNewAddon.addonGroupId = addonGroup.id;
        }
        this.openAddonModal(tempNewAddon);
    }

    private openAddonModal(addonGroup: AddonResponse) {
        const modalConfig = this.modalService.getDefaultDialogConfig();
        modalConfig.data = addonGroup;
        modalConfig.width = "700px";
        modalConfig.disableClose = true;
        this.modalService.openCustomDialog(AddonItemModalComponent, modalConfig);
    }

    public onAddonClick(event: any, addon: AddonResponse, addonGroup: AddonGroupResponse) {
        const tempNewAddon = Object.assign(new AddonLocalResponse(), addon); // new instance
        tempNewAddon.addonGroupId = addonGroup.id;
        this.openAddonModal(tempNewAddon);
    }


    /*
        Search functionality - categories and products
    */

    public onSearchProductUpdate(searchValue: string) {
        this.currentCategorySearchInput = searchValue;
        // Avoiding lowercase/uppercase mismatch
        searchValue = searchValue.toLowerCase();

        // Categories
        const categoryList = this.menuService.getCategoryListState().menuCategoryList;
        categoryList.forEach((c) => {

            const searchCategory = new SearchMap();
            searchCategory.id = c.id;            
            searchCategory.type = "category";                
            
            // visibility            
            if (!searchValue || searchValue === "")  {
                searchCategory.visible = true; // reset
            } else {
                if (searchValue.length === 1) {
                    // first letter only (from any word separated by space)
                    const allWords = c.name.toLocaleLowerCase().split(' ');
                    searchCategory.visible = allWords.some((w) => !!w && w.startsWith(searchValue)); 
                } else {
                    searchCategory.visible = c.name.toLocaleLowerCase().includes(searchValue); // contains the input
                }
            }            

            // updating the category
            this.productsSearchResults.set(searchCategory.id, searchCategory);


            // Products
            let atLeastOneProductFound: boolean = false;

            const categoryWithProducts = this.menuService.getProductsByCategoryListState().categories.find((cat) => cat.categoryId === c.id);                    
                categoryWithProducts?.products.forEach((p) => {
                    const searchProduct = new SearchMap();
                    searchProduct.id = p.id;
                    searchProduct.type = "product";           
                    
                    // visibility
                    if (!searchValue || searchValue === "")  {
                        searchProduct.visible = true
                    } else {
                        if (searchValue.length === 1) {
                            // first letter only (from any word separated by space)                            
                            const allWords = p.name.toLocaleLowerCase().split(' ');
                            searchProduct.visible = allWords.some((w) => !!w && w.startsWith(searchValue)); 
                        } else {
                            searchProduct.visible = p.name.toLocaleLowerCase().includes(searchValue);
                        }
                    }

                    if (!!searchValue && searchProduct.visible) {
                        // check if we need to open the category, so the product would be visible
                        const found = this.tempCategoriesCollapseState$.getValue().find((item) => item.categoryId === c.id);
                        if (!found?.isOpen && !searchCategory.expandedBySearch) {
                            // save that the category was expanded
                            searchCategory.expandedBySearch = true;                            
                            this.productsSearchResults.set(searchCategory.id, searchCategory);

                            // expand the category
                            this.setCategoryCollapse(c.id, false, true);
                        }
                        atLeastOneProductFound = true;
                    }

                    // updating the product
                    this.productsSearchResults.set(searchProduct.id, searchProduct);
                });

                // if no products found and category was opened, clear it
                const found = this.tempCategoriesCollapseState$.getValue().find((item) => item.categoryId === c.id);
                if (!atLeastOneProductFound && found?.isOpen)  {
                    searchCategory.expandedBySearch = false;
                    // collapse category
                    this.setCategoryCollapse(c.id, false, false);
                }
        });
    }

    public clearCategorySearchPress() {
        this.onSearchProductUpdate("");
    }



    /*
        Search functionality - add-on groups and add-ons
    */

    public onSearchAddonUpdate(searchValue: string) {
        this.currentAddonSearchInput = searchValue;
        // Avoiding lowercase/uppercase mismatch
        searchValue = searchValue.toLowerCase();

        // Groups
        const groupList = this.menuService.getAddonsGroupListState().addonGroupList;
        groupList.forEach((g) => {

            const searchGroup = new SearchMap();
            searchGroup.id = g.id;            
            searchGroup.type = "addon-group";                
            
            // visibility            
            if (!searchValue || searchValue === "")  {
                searchGroup.visible = true; // reset
            } else {
                if (searchValue.length === 1) {
                    // first letter only (from any word separated by space)
                    const allWords = g.name.toLocaleLowerCase().split(' ');
                    searchGroup.visible = allWords.some((w) => !!w && w.startsWith(searchValue)); 
                } else {
                    searchGroup.visible = g.name.toLocaleLowerCase().includes(searchValue); // contains the input
                }
            }            

            // updating the group
            this.addonsSearchResults.set(searchGroup.id, searchGroup);


            // Add-ons
            let atLeastOneAddonFound: boolean = false;

            g.addonList.forEach((a) => {
                const searchAddon = new SearchMap();
                searchAddon.id = a.id;
                searchAddon.type = "addon";           
                
                // visibility
                if (!searchValue || searchValue === "")  {
                    searchAddon.visible = true
                } else {
                    if (searchValue.length === 1) {
                        // first letter only (from any word separated by space)                            
                        const allWords = a.name.toLocaleLowerCase().split(' ');
                        searchAddon.visible = allWords.some((w) => !!w && w.startsWith(searchValue)); 
                    } else {
                        searchAddon.visible = a.name.toLocaleLowerCase().includes(searchValue);
                    }
                }

                if (!!searchValue && searchAddon.visible) {
                    // check if we need to open the group, so the addon would be visible
                    const found = this.tempAddonGroupCollapseState$.getValue().find((item) => item.categoryId === g.id);
                    if (!found?.isOpen && !searchGroup.expandedBySearch) {
                        // save that the group was expanded
                        searchGroup.expandedBySearch = true;                            
                        this.addonsSearchResults.set(searchGroup.id, searchGroup);

                        // expand the group
                        this.setAddonGroupCollapse(g.id, false, true);
                    }
                    atLeastOneAddonFound = true;
                }

                // updating the addon
                this.addonsSearchResults.set(searchAddon.id, searchAddon);
            });

            // if no addons found and group was opened, clear it
            const found = this.tempAddonGroupCollapseState$.getValue().find((item) => item.categoryId === g.id);
            if (!atLeastOneAddonFound && found?.isOpen)  {
                searchGroup.expandedBySearch = false;
                // collapse group
                this.setAddonGroupCollapse(g.id, false, false);
            }
        });
    }

    public clearAddonSearchPress() {
        this.onSearchAddonUpdate("");
    }

    /* 
        Info structure click 
    */
    public onInfoStructureClick(selectedTab: string) {
        const modalConfig = this.modalService.getDefaultDialogConfig();
        modalConfig.data = {selectedTab: selectedTab};
        modalConfig.width = "800px";
        modalConfig.disableClose = true;
        this.modalService.openCustomDialog(InfoStructureModal, modalConfig);
    }
}

// Keeps the state (inside this component only) of which category is opened (expanded) or not
class CollapseCategoryState {
    public categoryId: string = "";
    public isOpen: boolean = false;
}

class SearchMap {
    id: string = "";
    type: "" | "product" | "category" | "addon-group" | "addon" = "";
    expandedBySearch: boolean = false;
    visible: boolean = true;
}