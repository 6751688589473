<main-layout [selected]="'ACCOUNTS'">

    <content class="w-full flex">
        <div class="accounts-main-container">
            <div class="title-text">
                {{ "accounts_main_email" | translate }}
            </div>
            <!-- Main email value -->
             <div class="main-admin-email">
                <mat-icon class="main-admin-icon">email</mat-icon>
                {{ (selfData$ | async)?.email }}
             </div>

             <div class="invitations-section">
                <div class="title-text">
                    {{ "accounts_kitchen_title" | translate }}:
                </div>
                <!-- Existing users -->
                <div class="flex items-center pt-2">

                    <ng-container *ngIf="(usersList$ | async)?.count || 0 > 0; else noUsers">
                        <div class="invitation-table" >
                            <div class="invitation-row header">
                                <div class="invitation-col-email invitation-col-header">{{ "accounts_list_name" | translate}}</div>
                                <div class="invitation-col-email invitation-col-header">{{ "accounts_list_email" | translate}}</div>
                            </div>

                            <ng-container *ngFor="let user of ((usersList$ | async)?.users || [])">                                                                       
                                <div class="invitation-row">
                                    <div class="invitation-col-email">{{user.name}}</div>
                                    <div class="invitation-col-status">
                                        <span class="mr-12">{{user.email}}</span>
                                        <fs-button [text]="'accounts_user_remove_cta' | translate" 
                                            [type]="'raised'" [leadingIcon]="'delete'"
                                            (onPress)="removeUserPress(user.id)">
                                        </fs-button>
                                    </div>
                                    
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    
                    <ng-template #noUsers>
                        <mat-icon class="not-available-icon">restaurant_menu</mat-icon>
                        {{ "accounts_kitchen_no_accounts" | translate }}
                    </ng-template>
                    
                </div>

                <ng-container *ngIf="(showInvitationInput$ | async); else addInvitationButton">
                    <div class="input-invitation-container">
                        <!-- Email -->
                        <fs-input [label]="'accounts_kitchen_email_invite' | translate" [style]="'full-width'" class="kitchen-email-input"
                        [inputValue]="''"
                        (valueUpdate)="onEmailKitchenInputUpdate($event)"></fs-input>

                        <!-- Button - Add Category -->
                        <div class="flex button-send-kitchen-invite">
                            <fs-button [text]="'accounts_kitchen_invite' | translate" [style]="'full-width-large-height'"
                                        [type]="'raised-primary'" [leadingIcon]="'send'"
                                        (onPress)="sendKitchenEmailInvite()"
                                        >
                            </fs-button>
                        </div>
                    </div>
                    
                </ng-container>
                <ng-template #addInvitationButton>
                    <!-- Button - Add Category -->
                    <div class="flex button-add-kitchen">
                        <fs-button [text]="'accounts_kitchen_add' | translate" [style]="'full-width-large-height'"
                                    [type]="'raised-primary'" [leadingIcon]="'add'"
                                    (onPress)="addKitchenAccount()"
                                    >
                        </fs-button>
                    </div>
                </ng-template>                
             </div>


             <div class="invitations-section">
                <div class="title-text">
                    {{ "accounts_invitations" | translate }}:
                </div>
                <div class="flex items-center pt-2">                   

                    <ng-container *ngIf="(invitations$ | async)?.count || 0 > 0; else noInvitations">
                        <div class="invitation-table">

                            <div class="invitation-row header">
                                <div class="invitation-col-email invitation-col-header">{{ "accounts_invitations_col_email" | translate}}</div>
                                <div class="invitation-col-status invitation-col-header">{{ "accounts_invitations_col_status" | translate}}</div>
                                <div class="invitation-col-created invitation-col-header">{{ "accounts_invitations_col_created" | translate }}</div>
                                <div class="invitation-col-expire invitation-col-header">{{ "accounts_invitations_col_expire" | translate }}</div>
                            </div>


                            <ng-container *ngFor="let invitation of ((invitations$ | async)?.invitations || [])">                                                            
                                <div class="invitation-row">
                                    <div class="invitation-col-email">{{invitation.email}}</div>
                                    <div class="invitation-col-status">{{invitation.status}}</div>
                                    <div class="invitation-col-created">{{invitation.createdDate | date:"MMM dd, yyyy, hh:mm" }}</div>
                                    <div class="invitation-col-expire">{{invitation.expiresAt | date:"MMM dd, yyyy, hh:mm" }}</div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>

                    <ng-template #noInvitations>
                        {{ "accounts_invitations_none" | translate }}
                    </ng-template>
                    
                </div>
             </div>

            
        </div>
    </content>

</main-layout>
