import {BehaviorSubject, find, map, Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {MenuListResponse} from "./model/menu-list-response";
import {ProductListGroupedByCategoryResponse} from "libs/shared-models/src/lib/restaurant/product-list-grouped-by-category-response";
import {MenuCategoryResponse} from "./model/menu-category-response";
import {MenuCategoryListResponse} from "./model/menu-category-list-response";
import {MenuCategoryWithSlimProducts} from "libs/shared-models/src/lib/restaurant/menu-category-with-slim-products";
import {ProductSlimInfo} from "libs/shared-models/src/lib/restaurant/product-slim-info";
import {AddonGroupListResponse} from "./model/addon-group-list-response";
import {AddonGroupResponse} from "libs/shared-models/src/lib/restaurant/addon-group-response";

@Injectable({
    providedIn: 'root',
})
export class RestaurantMenuState {

    /*
        Menu list. For now, we only have 1 menu per restaurant
     */
    private menuList$: BehaviorSubject<MenuListResponse> = new BehaviorSubject<MenuListResponse>(new MenuListResponse());

    /*
        All categories list
     */
    private categoriesList$: BehaviorSubject<MenuCategoryListResponse> = new BehaviorSubject<MenuCategoryListResponse>(new MenuCategoryListResponse());

    /*
        Easy response - all grouped - needed directly for display / Menu management
     */
    private productsGroupedByCategory$: BehaviorSubject<ProductListGroupedByCategoryResponse> = new BehaviorSubject<ProductListGroupedByCategoryResponse>(new ProductListGroupedByCategoryResponse());

  /*
     All categories list
  */
  private addonsGroupList$: BehaviorSubject<AddonGroupListResponse> = new BehaviorSubject<AddonGroupListResponse>(new AddonGroupListResponse());


  constructor(
    ) {
    }

    /*
        Menu List
    */
    public getMenuList(): MenuListResponse {
        return this.menuList$.getValue();
    }

    public getMenuList$(): Observable<MenuListResponse> {
        return this.menuList$.asObservable();
    }

    public setMenuList(value: MenuListResponse): void {
        this.menuList$.next(value); // to do immutable
    }


    /*
       Categories List
    */
    public getCategoryList(): MenuCategoryListResponse {
        return this.categoriesList$.getValue();
    }

    public getCategoryList$(): Observable<MenuCategoryListResponse> {
        return this.categoriesList$.asObservable();
    }

    public setCategoryList(value: MenuCategoryListResponse): void {
        this.categoriesList$.next(value); // to do immutable
    }



    /*
       Products grouped by categories List
    */
    public getProductsByCategoryList(): ProductListGroupedByCategoryResponse {
        return this.productsGroupedByCategory$.getValue();
    }

    public getProductsByCategoryList$(): Observable<ProductListGroupedByCategoryResponse> {
        return this.productsGroupedByCategory$.asObservable();
    }

    public setProductsByCategoryList(value: ProductListGroupedByCategoryResponse): void {
        this.productsGroupedByCategory$.next(value); // to do immutable
    }


    public addCategory(value: MenuCategoryResponse) {
        let categoryList = this.getCategoryList();
        let tempList = [...categoryList.menuCategoryList];
        tempList.push(value);
        categoryList.menuCategoryList = tempList;
        this.setCategoryList(categoryList);
    }

    public addSlimProductToCategory(categoryId: string, product: ProductSlimInfo) {
        let list = this.getProductsByCategoryList();

        const categoryExists = list.categories.find((c) => c.categoryId === categoryId);
        if (!!categoryExists) {
            // Add the product inside an existing category
            list.categories = list.categories.map((c) => {
                if (c.categoryId === categoryId) {
                    // just double check so we don't duplicate the product
                    let productExists = c.products.find((p) => p.id === product.id);
                    if (!productExists) {
                        c.products = [...c.products];
                        c.products.push(product);
                    } else {
                        console.warn("[Menu] [Add product] - You're trying to add a NEW product in the list, but the product already exists");
                    }
                }
                return c;
            });
        } else {
            // Category was not even added in the Categories Slim Response, so we need to insert it
            let newCategory = new MenuCategoryWithSlimProducts();
            newCategory.categoryId = categoryId;
            newCategory.categoryName = this.categoriesList$.getValue().menuCategoryList.find((c) => c.id === categoryId)?.name || "";
            newCategory.products = [product];
            list.categories.push(newCategory);
        }

         this.setProductsByCategoryList(list);
    }


    public updateSlimProductToCategory(categoryId: string, product: ProductSlimInfo) {
        let list = this.getProductsByCategoryList();

        const categoryExists = list.categories.find((c) => c.products.find((p) => p.id === product.id));
        if (!!categoryExists) {

            // Category didn't change for the product - stays the same. So just update the existing product (other attributes) inside the category
            if (categoryExists.categoryId === categoryId) {
                list.categories = list.categories.map((c) => {
                    if (c.categoryId === categoryId) {
                        let productExists = c.products.find((p) => p.id === product.id);
                        if (!!productExists) {
                            c.products = [...c.products];
                            c.products = c.products.map(p => p.id === product.id ? product : p);
                        } else {
                            console.warn("[Menu] [Update product] - Expected for the product to exist, but it was missing");
                        }
                    }
                    return c;
                });
            } else {
                // The product has now a different category assigned to it
                // We need to delete it from the current one / add it to the new requested one

                // Delete the product from any category
                list.categories = list.categories.map((c) => {
                    c.products = [...c.products];
                    c.products = c.products.filter(p => p.id !== product.id);
                    return c;
                });

                // Add the product inside the new existing category
                list.categories = list.categories.map((c) => {
                    if (c.categoryId === categoryId) {
                        // just double check so we don't duplicate the product
                        let productExists = c.products.find((p) => p.id === product.id);
                        if (!productExists) {
                            c.products = [...c.products];
                            c.products.push(product);
                        } else {
                            console.warn("[Menu] [Add product] - You're trying to add a NEW product in the list, but the product already exists");
                        }
                    }
                    return c;
                });
            }
        } else {
            console.warn("[Menu][Update product] - Missing the category");
        }

        this.setProductsByCategoryList(list);
    }

    public deleteSlimProductToCategory(categoryId: string, product: ProductSlimInfo) {
        let list = this.getProductsByCategoryList();
        list.categories = list.categories.map((c) => {
            if (c.categoryId === categoryId) {
                c.products = [...c.products];
                c.products = c.products.filter(p => p.id !== product.id);
            }
            return c;
        });
        this.setProductsByCategoryList(list);
    }


    /*
      Delete product Category
     */
    public deleteCategory(localCategory: MenuCategoryResponse) {
      // Delete it from the first response (categories list only)
      const categoryList = this.getCategoryList();
      categoryList.menuCategoryList = [...categoryList.menuCategoryList.filter((c) => c.id !== localCategory.id)];
      this.setCategoryList(categoryList);

      // Delete it from the Slim Products category list
      const categoryWithProductsList = this.getProductsByCategoryList();
      categoryWithProductsList.categories = [...categoryWithProductsList.categories.filter((c) => c.categoryId !== localCategory.id)];
      this.setProductsByCategoryList(categoryWithProductsList);
    }

    /*
     Delete product Category
    */
    public updateCategory(localCategory: MenuCategoryResponse) {
      // Update it from the first response (categories list only)
      const categoryList = this.getCategoryList();
      categoryList.menuCategoryList = [...categoryList.menuCategoryList.map((c) => {
        if (c.id === localCategory.id) {
          return localCategory
        }  else {
          return c;
        }
      })];
      this.setCategoryList(categoryList);

      // Update the name in the slim products local list
      const categoryWithProductsList = this.getProductsByCategoryList();
      categoryWithProductsList.categories = [...categoryWithProductsList.categories.map((c) => {
        if (c.categoryId === localCategory.id) {
          c.categoryName = localCategory.name;
        }
        return c;
      })];
      this.setProductsByCategoryList(categoryWithProductsList);
    }

   /*
      Addons list state
  */

  public getAddonsGroupList(): AddonGroupListResponse {
      return this.addonsGroupList$.getValue();
  }

  public getAddonsGroupList$(): Observable<AddonGroupListResponse> {
      return this.addonsGroupList$.asObservable();
  }

  public setAddonsGroupList(value: AddonGroupListResponse): void {
    this.addonsGroupList$.next(value); // to do immutable
  }

  public addAddonGroup(value: AddonGroupResponse) {
    const addonGroupList = this.getAddonsGroupList();
    const tempList = [...this.getAddonsGroupList().addonGroupList];
    tempList.push(value);
    addonGroupList.addonGroupList = tempList;
    this.setAddonsGroupList(addonGroupList);
  }

  public updateAddonGroup(value: AddonGroupResponse) {
    const addonGroupList = this.getAddonsGroupList();
    let tempList = [...this.getAddonsGroupList().addonGroupList];
    tempList = tempList.map((a) => a.id === value.id ? value : a);
    addonGroupList.addonGroupList = tempList;
    this.setAddonsGroupList(addonGroupList);
  }

  public deleteAddonGroup(data: AddonGroupResponse) {
    const addonGroupList = this.getAddonsGroupList();
    let tempList = [...this.getAddonsGroupList().addonGroupList];
    tempList = [...tempList.filter((a) => a.id !== data.id)];
    addonGroupList.addonGroupList = tempList;
    this.setAddonsGroupList(addonGroupList);
  }

}

