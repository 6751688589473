import { Route } from '@angular/router';
import {DashboardComponent} from "./components/pages/dashboard/dashboard.component";
import {AuthGuard} from "libs/shared-services/src/lib/auth-guard.service";
import {LoginRedirectComponent} from "./components/pages/login-redirect/login-redirect.component";
import {SectionOrdersComponent} from "./components/pages/section-orders/section-orders.component";
import {SectionMenuComponent} from "./components/pages/section-menu/section-menu.component";
import {SectionReportsComponent} from "./components/pages/section-reports/section-reports.component";
import {SectionInvoicesComponent} from "./components/pages/section-invoices/section-invoices.component";
import {
    SectionRestaurantInfoComponent
} from "./components/pages/section-restaurant-info/section-restaurant-info.component";
import { SectionReviewsComponent } from './components/pages/section-reviews/section-reviews.component';
import { SectionAccountsComponent } from './components/pages/section-accounts/section-accounts.component';

export const appRoutes: Route[] = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full'},
    { path: 'login', component: LoginRedirectComponent},
    { path: 'orders',
        component: SectionOrdersComponent,
        /*loadChildren: () => import('./components/pages/section-orders/section-orders.module').then(m => m.SectionOrdersModule),*/
        canActivate: [AuthGuard]
    },
    { path: 'dashboard',
        component: DashboardComponent,
        /*loadChildren: () => import('./components/pages/dashboard/section-dashboard.module').then(m => m.SectionDashboardModule),*/
        canActivate: [AuthGuard],
        children: [
            {
              path: 'submit-verification',
              component: DashboardComponent
            }
        ]
    },
    { path: 'menu',
        component: SectionMenuComponent,
        /*loadChildren: () => import('./components/pages/section-menu/section-menu.module').then(m => m.SectionMenuModule),*/
        canActivate: [AuthGuard]
    },
    // { path: 'reports',
    //     component: SectionReportsComponent,
    //     /*loadChildren: () => import('./components/pages/section-reports/section-reports.module').then(m => m.SectionReportsModule),*/
    //     canActivate: [AuthGuard]
    // },
    // { path: 'invoices',
    //     component: SectionInvoicesComponent,
    //     /*loadChildren: () => import('./components/pages/section-invoices/section-invoices.module').then(m => m.SectionInvoicesModule),*/
    //     canActivate: [AuthGuard]
    // },
    { path: 'reviews',
        component: SectionReviewsComponent,
        /*loadChildren: () => import('./components/pages/section-reviews/section-reviews.module').then(m => m.SectionReviewsModule),*/
        canActivate: [AuthGuard]
    },
    { path: 'settings',
        component: SectionRestaurantInfoComponent,
        children: [
            {
              path: 'public',
              component: SectionRestaurantInfoComponent
            },
            {
                path: 'opening-hours',
                component: SectionRestaurantInfoComponent
            },
            {
                path: 'delivery',
                component: SectionRestaurantInfoComponent 
            },
            {
                path: 'private',
                component: SectionRestaurantInfoComponent 
            },
        ],
        /*loadChildren: () => import('./components/pages/section-restaurant-info/section-restaurant-info.module').then(m => m.SectionRestaurantInfoModule),*/
        canActivate: [AuthGuard]
    },

    { path: 'accounts',
        component: SectionAccountsComponent,
        /*loadChildren: () => import('./components/pages/section-reviews/section-reviews.module').then(m => m.SectionReviewsModule),*/
        canActivate: [AuthGuard]
    },
];
