import {
    Component,
    Inject,
    OnInit,
} from '@angular/core';

import { BehaviorSubject, Observable, map, of } from "rxjs";
import { AddressResponse } from "libs/shared-models/src/lib/address-response";
import { ToasterService } from "libs/shared-services/src/lib/toaster.service";
import { Language, LocaleService } from 'libs/shared-services/src/lib/locale.service';
import { AddressTranslations, AddressUsageEnum } from 'libs/shared-ui/src/lib/fs-base-address-editor/fs-base-address-editor.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalService } from 'libs/shared-ui/src/lib/modal-service/modal-service.service';
import { RestaurantInfoService } from 'apps/restaurant/src/app/services/restaurant-info/restaurant-info.service';
import { WrapperAddressResponse, WrapperAddressUsageEnum } from 'apps/restaurant/src/app/models/wrapper-adress-response';

@Component({
  selector: 'restaurant--address-editor',
  templateUrl: './restaurant-address-editor.component.html',
  styleUrls: ['./restaurant-address-editor.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})

export class RestaurantAddressEditorComponent implements OnInit {

    public AddressUsageEnum: typeof AddressUsageEnum = AddressUsageEnum;

    public tempData$: BehaviorSubject<AddressResponse> = new BehaviorSubject<AddressResponse>(new AddressResponse());
    
    private defaultTranslations: AddressTranslations = {
        address_image_title_back_search      : "",
        search_address_placeholder           : "",
        address_image_title_clear_input      : "",
        address_image_title_browser_location : "",
        address_stored_title                 : "",
        address_image_title_default          : "",
        address_image_title_edit             : "",
        address_image_title_delete           : "",
        address_stored_see_all               : "",
        address_extended_more                : "",
        address_extended_delete              : "",
        address_extended_button_confirm      : "",
        address_extended_button_save_changes : "",
        address_extended_instructions        : "",
        address_extended_postal_code         : "",
        address_extended_door                : "",
        address_extended_floor               : "",
        address_extended_entrance            : "",
        address_extended_street_number       : "",
        address_extended_street              : ""
    }

    public addressTranslationsList$: BehaviorSubject<AddressTranslations> = new BehaviorSubject<AddressTranslations>(this.defaultTranslations);

    public constructor(
        @Inject(MAT_DIALOG_DATA) private data: WrapperAddressResponse,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<RestaurantAddressEditorComponent>,
        private modalService: ModalService,
        private toasterService: ToasterService,
        private localeService: LocaleService,
        private restaurantInfoService: RestaurantInfoService
    ) {
    }

    public ngOnInit() {

        // Listen to UI modal backdrop:
        this.dialogRef.backdropClick().subscribe(() => {
            this.onCancel();
        });

        // Wait for languages load / change
        this.localeService.getLanguage$().subscribe((lang: Language) => {
            // wait for languages to load / react to languages load
            this.setAddressTranslations();
        })

        // Assign the data received on Modal creation
        const newAddress = Object.assign(new AddressResponse(), JSON.parse(JSON.stringify(this.getDialogData())));
              
        this.tempData$.next(newAddress);
    }

    // The data added on Modal creation (injected in the dialog from parent call)
    private getDialogData(): AddressResponse {
        return this.data.address;
    }

    private getData(): AddressResponse {
        return this.tempData$.getValue();
    }

    private setData(val: AddressResponse) {
        this.tempData$.next(val);
    }

    
    public getAddresses$(): Observable<AddressResponse[]> {
        return this.tempData$.pipe(map((a) => a.isEmpty() ? [] : [a])); // we don't want to show empty addresses (never set) by the restaurants
    }

    private setAddressTranslations() {
        const t: AddressTranslations = {
            address_image_title_back_search      : this.localeService.translate("address_image_title_back_search"),
            search_address_placeholder           : this.localeService.translate("search_address_placeholder"),
            address_image_title_clear_input      : this.localeService.translate("address_image_title_clear_input"),
            address_image_title_browser_location : this.localeService.translate("address_image_title_browser_location"),
            address_stored_title                 : this.localeService.translate("address_stored_title"),
            address_image_title_default          : this.localeService.translate("address_image_title_default"),
            address_image_title_edit             : this.localeService.translate("address_image_title_edit"),
            address_image_title_delete           : this.localeService.translate("address_image_title_delete"),
            address_stored_see_all               : this.localeService.translate("address_stored_see_all"),
            address_extended_more                : this.localeService.translate("address_extended_more"),
            address_extended_delete              : this.localeService.translate("address_extended_delete"),
            address_extended_button_confirm      : this.localeService.translate("address_extended_button_confirm"),
            address_extended_button_save_changes : this.localeService.translate("address_extended_button_save_changes"),
            address_extended_instructions        : this.localeService.translate("address_extended_instructions"), 
            address_extended_postal_code         : this.localeService.translate("address_extended_postal_code"),
            address_extended_door                : this.localeService.translate("address_extended_door"),
            address_extended_floor               : this.localeService.translate("address_extended_floor"),
            address_extended_entrance            : this.localeService.translate("address_extended_entrance"),
            address_extended_street_number       : this.localeService.translate("address_extended_street_number"),
            address_extended_street              : this.localeService.translate("address_extended_street"),
        }
        this.addressTranslationsList$.next(t);
    }
    
    /*
       Confirm button
    */
    public confirmAddressClick(address: AddressResponse) {
        
        address.setIsDefault(true); // make sure it is always default - because a restaurant can have only 1 address (compared to user app flow which has multiple AddressResponse and one is default)

        if (this.data.popupUsage === WrapperAddressUsageEnum.ADDRESS) {
            this.restaurantInfoService.updateRestaurantAddress$(address).subscribe((result) => {
                if (result) {
                    this.dialogRef.close();
                }
            })
        } else if (this.data.popupUsage === WrapperAddressUsageEnum.INVOICE_ADDRESS) {
            this.restaurantInfoService.updateRestaurantInvoiceAddress$(address).subscribe((result) => {
                if (result) {
                    this.dialogRef.close();
                }
            })
        }
    }

    /*
        Browser location not allowed by the user
    */
    public onBrowserBlockedError() {        
        this.toasterService.showWarning(this.localeService.translate("user_address_location_failed_title"), this.localeService.translate("user_address_location_failed_body"));
    }

    /*
        Browser location not supported by the browser
    */
    public onBrowserNotSupportedError() {        
        this.toasterService.showWarning(this.localeService.translate("user_address_location_geo_not_supported_title"), this.localeService.translate("user_address_location_geo_not_supported_body"));
    }



    // Popup cancel
    public onCancel() {
        this.dialogRef.close();
    }

}