import { Inject, Injectable } from "@angular/core";
import { VerificationStateService } from "./data-store/verification-state.store";
import { RestaurantResponse, RestaurantVerificationEnum } from "libs/shared-models/src/lib/restaurant/restaurant-response";
import { BehaviorSubject, Observable, combineLatest, map } from "rxjs";
import { RestaurantInfoService } from "../restaurant-info/restaurant-info.service";
import { SelfDataService } from "../self/self.service";
import { RestaurantMenuService } from "../restaurant-menu/restaurant-menu.service";
import { UserSelfResponse } from "../../models/user-response";
import { MenuListResponse } from "../restaurant-menu/data-store/model/menu-list-response";
import { MenuCategoryListResponse } from "../restaurant-menu/data-store/model/menu-category-list-response";
import { ProductListGroupedByCategoryResponse } from "libs/shared-models/src/lib/restaurant/product-list-grouped-by-category-response";
import { AddonGroupListResponse } from "../restaurant-menu/data-store/model/addon-group-list-response";
import { VerificationStateModel, VerificationStepName, VerificationSubLevel } from "./data-store/model/verification-state-model";
import { ModalService } from "libs/shared-ui/src/lib/modal-service/modal-service.service";
import { LocaleTranslatePipe } from "libs/shared-services/src/lib/locale-pipe";
import { LocaleService } from "libs/shared-services/src/lib/locale.service";
import { ApiBaseService } from "libs/shared-services/src/lib/api-base.service";
import { ToasterService } from "libs/shared-services/src/lib/toaster.service";
import { BaseLoadingService } from "libs/shared-services/src/lib/base-loading";

@Injectable({
    providedIn: 'root',
})
export class VerificationService extends BaseLoadingService {

    private needsVerification$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private canSubmit$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public verificationStatus$: BehaviorSubject<RestaurantVerificationEnum> = new BehaviorSubject<RestaurantVerificationEnum>(RestaurantVerificationEnum.NEW);
    public verificationMessage$: BehaviorSubject<string> = new BehaviorSubject<string>("");

    constructor(
        @Inject('env') private environment: any,
        private verificationState: VerificationStateService,
        private selfDataService: SelfDataService,
        private restaurantInfoService: RestaurantInfoService,
        private restaurantMenuService: RestaurantMenuService,
        private modalService: ModalService,
        private localeService: LocaleService,
        private apiService: ApiBaseService,
        private toasterService: ToasterService
        
    ) {
        super();

        this.restaurantInfoService.getState$().subscribe((resData: RestaurantResponse) => {
            // Check if restaurant is verified
            const needsVerify = resData?.verificationStatus !== RestaurantVerificationEnum.VERIFIED;
            this.verificationState.setNeedsVerification(needsVerify);
            this.verificationStatus$.next(resData.verificationStatus);
            this.verificationMessage$.next(resData.verificationMessage);

            if (this.needsVerification$.getValue() !== needsVerify) {
                this.needsVerification$.next(needsVerify);
                this.subscribeToAllStates();
            }
        })

        this.verificationState.getData$().subscribe((data) => {
            // console.log("===> Verification data Updated: ", data);
        })

        this.setFinished();
    }

    private subscribeToAllStates() {

        combineLatest([
            this.restaurantInfoService.getState$(),
            this.selfDataService.getSelfData$(),
            this.restaurantMenuService.getMenuListState$(),
            this.restaurantMenuService.getCategoryListState$(),
            this.restaurantMenuService.getProductsByCategoryListState$(),
            this.restaurantMenuService.getAddonsGroupListState$()]).subscribe(
            ([resInfo, selfData, resMenuList, resMenuCategories, resMenuProducts, resMenuAddons]) => {

                this.checkRestaurantInfoConditions(resInfo);
                this.checkSelfDataConditions(selfData);
                this.checkMenuConditions(resMenuList, resMenuCategories, resMenuProducts, resMenuAddons);
                this.checkCanSubmit();
            }
        );
    }

    private checkRestaurantInfoConditions(data: RestaurantResponse) {
        // created
        const isRestaurantCreated = !!data.name && !!data.id;
        this.verificationState.completeSubLevel(VerificationSubLevel.RESTAURANT_CREATED, isRestaurantCreated);

        // address
        let hasAddress = false;
        const address = data?.address;        
        if (!!address) {
            hasAddress = (address.streetName + address.streetNumber + address.city).length > 0 && address.latitude > 0 && address.longitude > 0;
        }
        this.verificationState.completeSubLevel(VerificationSubLevel.ADDRESS_ADDED, hasAddress);
        
        // cuisine
        const hasCuisineList = data.categoryList.length > 0;
        this.verificationState.completeSubLevel(VerificationSubLevel.CUISINE_LIST, hasCuisineList);

        // logo
        const hasLogo = !!data.logoImageUrl
        this.verificationState.completeSubLevel(VerificationSubLevel.LOGO_ADDED, hasLogo);

        // description
        const hasDescription = !!data.description;
        this.verificationState.completeSubLevel(VerificationSubLevel.DESCRIPTION_ADDED, hasDescription);

        // hours
        const hasPickupHours = 
            (data.businessHoursPickup?.mon || []).length > 0 ||
            (data.businessHoursPickup?.tue || []).length > 0 ||
            (data.businessHoursPickup?.wed || []).length > 0 ||
            (data.businessHoursPickup?.thu || []).length > 0 ||
            (data.businessHoursPickup?.thu || []).length > 0 ||
            (data.businessHoursPickup?.sat || []).length > 0 ||
            (data.businessHoursPickup?.sun || []).length > 0;
        this.verificationState.completeSubLevel(VerificationSubLevel.PICKUP_HOURS, hasPickupHours);

        const hasDeliveryHours = 
            (data.businessHoursDelivery?.mon || []).length > 0 ||
            (data.businessHoursDelivery?.tue || []).length > 0 ||
            (data.businessHoursDelivery?.wed || []).length > 0 ||
            (data.businessHoursDelivery?.thu || []).length > 0 ||
            (data.businessHoursDelivery?.thu || []).length > 0 ||
            (data.businessHoursDelivery?.sat || []).length > 0 ||
            (data.businessHoursDelivery?.sun || []).length > 0;
        this.verificationState.completeSubLevel(VerificationSubLevel.DELIVERY_HOURS, hasDeliveryHours);


        // delivery
        const hasMinDeliveryType = data.hasPickup || data.hasFoodisDelivery || data.hasOwnDelivery;
        this.verificationState.completeSubLevel(VerificationSubLevel.DELIVERY_TYPE, hasMinDeliveryType);

        const hasDeliveryTime = !!data.deliveryTimeRange;
        this.verificationState.completeSubLevel(VerificationSubLevel.DELIVERY_TIME, hasDeliveryTime);

        const hasDeliveryRadius = data.deliveryRadiusMeters > 0;
        this.verificationState.completeSubLevel(VerificationSubLevel.DELIVERY_RANGE, hasDeliveryRadius);


        // private documents
        const hasUploadedDocuments = (data.documents || []).length > 0;
        this.verificationState.completeSubLevel(VerificationSubLevel.DOCUMENTS_UPLOADED, hasUploadedDocuments);

        // vat
        const hasVat = !!data.vatNumber;
        this.verificationState.completeSubLevel(VerificationSubLevel.VAT_NUMBER, hasVat);

        // IBAN
        const hasIban = !!data.ibanAccount;
        this.verificationState.completeSubLevel(VerificationSubLevel.IBAN, hasIban);

        // invoice address
        let hasInvoiceAddress = false;
        const invoiceAddress = data?.invoiceAddress;        
        if (!!invoiceAddress) {
            hasInvoiceAddress = (invoiceAddress.streetName + invoiceAddress.streetNumber + invoiceAddress.city).length > 0 && invoiceAddress.latitude > 0 && invoiceAddress.longitude > 0;
        }
        this.verificationState.completeSubLevel(VerificationSubLevel.INVOICE_ADDRESS, hasInvoiceAddress);

        // submission state
        const isSubmitted = data.verificationStatus === RestaurantVerificationEnum.VERIFICATION_REQUESTED;
        this.verificationState.completeSubLevel(VerificationSubLevel.SUBMITTED, isSubmitted);
    }   

    private checkSelfDataConditions(data: UserSelfResponse) {
                
        const hasEmergencyNumber = !!data.phoneNumber;
        this.verificationState.completeSubLevel(VerificationSubLevel.EMEREGENCY_PHONE, hasEmergencyNumber);

        // TODO - ADd support for IBAN - also on backend
        // TODO - ADd support for Invoice Address - also on backend
    }

    private checkMenuConditions(
        menuList: MenuListResponse, 
        categoryList: MenuCategoryListResponse, 
        productList: ProductListGroupedByCategoryResponse,
        addonsList: AddonGroupListResponse
    ) {
        // category
        const hasCategory = categoryList.menuCategoryList.length >= 2;
        this.verificationState.completeSubLevel(VerificationSubLevel.CATEGORY_ADDED, hasCategory);

        // products
        let numberOfProducts = 0;
        productList.categories.forEach((c) => {
            numberOfProducts += c.products.length;
        })
        const hasMinProducts = numberOfProducts >= 5;
        this.verificationState.completeSubLevel(VerificationSubLevel.MIN_PRODUCTS, hasMinProducts);

        // addons group
        const hasAdddonGroup = addonsList.addonGroupList.length > 0;
        this.verificationState.completeSubLevel(VerificationSubLevel.ADDON_GROUP_CREATED, hasAdddonGroup);

        // addons
        let numberOfAddons = 0;
        addonsList.addonGroupList.forEach((g) => {
            numberOfAddons += g.addonList.length;
        })
        const hasMinAddons = numberOfAddons >= 1;
        this.verificationState.completeSubLevel(VerificationSubLevel.ADDONS_CREATED, hasMinAddons);
    }

    private checkCanSubmit() {
        let canSubmit = this.verificationState.getData().areMultipleStepsCompleted(
            [
                VerificationStepName.CREATE_RESTAURANT,
                VerificationStepName.ADD_MENU,
                VerificationStepName.OPENING_HOURS,
                VerificationStepName.DELIVERY_OPTIONS,
                VerificationStepName.UPLOAD_DOCUMENTS,
                VerificationStepName.BANK_DETAILS
            ]
        );

        if (this.verificationStatus$.getValue() === RestaurantVerificationEnum.VERIFICATION_REQUESTED) {
            canSubmit = false;
        }

        this.canSubmit$.next(canSubmit);
    }

    public submitVerification() {
        const status = this.restaurantInfoService.getState().verificationStatus;

        // do nothing, this shouldn't be called (no actual entry point other than deeplink in this case)
        if (status === RestaurantVerificationEnum.VERIFIED) {
            return; 
        }

        // Unverified restaurant
        if (status === RestaurantVerificationEnum.NEW ||
            status === RestaurantVerificationEnum.VERIFICATION_DECLINED) {
            // submit it to API
            if (this.canSubmit$.getValue()) {
                this.openSubmit();
            } 
            // cannot submit - not all steps completed
            else {
                this.openSubmitNotReady();
            }
        } 
        // Submission in progress
        else {
            if (status === RestaurantVerificationEnum.VERIFICATION_REQUESTED) {
                this.openSubmissionInProgress();
            }
        }
    }

    private openSubmit() {
        const config = this.modalService.getDefaultDialogConfig();
        config.width = "500px";
        config.data = {
            title: new LocaleTranslatePipe(this.localeService).transform("verify_available_popup_title"),
            midContent: new LocaleTranslatePipe(this.localeService).transform("verify_available_popup_body"),
            leftButtonContent: new LocaleTranslatePipe(this.localeService).transform("verify_available_popup_button_left"),
            rightButtonContent: new LocaleTranslatePipe(this.localeService).transform("verify_available_popup_button_right"),
            preselectedButton: "left",
        }

        // ask if you're sure
        this.modalService.openConfirmDialog$(config).subscribe((response) => {
            if (response) { // true     
                
                // Send API call        
                this.apiService.post(this.environment.API_POST_REQUEST_VERIFICATION, {
                    message: ""
                }).subscribe({
                    next: (res: any) => {
                        
                        this.restaurantInfoService.setVerificationPending();
                        this.toasterService.showSuccess("", this.localeService.translate("verify_available_popup_success"));

                    },
                    error: (err) => {
                        this.toasterService.showError("Error", err?.error?.message);
                    }
                });
            }
        });
    }

    private openSubmitNotReady() {
        const config = this.modalService.getDefaultDialogConfig();
        config.width = "500px";
        config.data = {
            title: new LocaleTranslatePipe(this.localeService).transform("verify_submit_popup_title"),
            midContent: new LocaleTranslatePipe(this.localeService).transform("verify_submit_popup_body"),
            leftButtonContent: new LocaleTranslatePipe(this.localeService).transform("verify_submit_popup_button"),
            preselectedButton: "left",
            oneButtonOnly: true
        }

        // ask if you're sure
        this.modalService.openConfirmDialog$(config).subscribe((response) => {
            if (response) { // true                
            }
        });
    }

    private openSubmissionInProgress() {
        const config = this.modalService.getDefaultDialogConfig();
        config.width = "500px";
        config.data = {
            title: new LocaleTranslatePipe(this.localeService).transform("verify_pending_popup_title"),
            midContent: new LocaleTranslatePipe(this.localeService).transform("verify_pending_popup_body"),
            leftButtonContent: new LocaleTranslatePipe(this.localeService).transform("verify_pending_popup_button"),
            preselectedButton: "left",
            oneButtonOnly: true
        }

        // ask if you're sure
        this.modalService.openConfirmDialog$(config).subscribe((response) => {
            if (response) { // true                
            }
        });
    }


    public verificationNeeded$():Observable<boolean> {
        return this.needsVerification$.asObservable();
    }

    public verificationNeeded():boolean {
        return this.needsVerification$.getValue();
    }

    public getData$(): Observable<VerificationStateModel> {
        return this.verificationState.getData$()
    }
    
    public getCanSubmit$(): Observable<boolean> {
        return this.canSubmit$.asObservable();
    }
}