import { Component, OnInit } from '@angular/core';
import { AuthService } from "libs/shared-services/src/lib/auth.service";
import { UserSelfResponse } from "../../../models/user-response";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { Observable } from "rxjs";
import { SelfDataService } from "../../../services/self/self.service";
import { RestaurantInfoService } from '../../../services/restaurant-info/restaurant-info.service';
import { ModalService } from 'libs/shared-ui/src/lib/modal-service/modal-service.service';
import { LocaleService } from 'libs/shared-services/src/lib/locale.service';
import { LocaleTranslatePipe } from 'libs/shared-services/src/lib/locale-pipe';
import { VerificationService } from '../../../services/verification-status/verification.service';
import { RestaurantResponse, RestaurantVerificationEnum } from 'libs/shared-models/src/lib/restaurant/restaurant-response';
import { Router } from '@angular/router';

@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {

    public selfData$: Observable<UserSelfResponse> = this.selfDataService.getSelfData$();
    public restaurantInfo$: Observable<RestaurantResponse> = this.restaurantInfoService.getState$();
    public needsVerification$: Observable<boolean> = this.verificationService.verificationNeeded$();
    public canSubmit$: Observable<boolean> = this.verificationService.getCanSubmit$();

    public routerUrl: string = "";

    public RestaurantVerificationEnum: typeof RestaurantVerificationEnum = RestaurantVerificationEnum;

    constructor(
        private authService: AuthService,
        private selfDataService: SelfDataService,
        private restaurantInfoService: RestaurantInfoService,
        private modalService: ModalService,
        private localeService: LocaleService,
        private verificationService: VerificationService,
        private router: Router
    ) {
    }

    public ngOnInit() {
        this.routerUrl = this.router.url;
    }

    public doLogout() {
        this.authService.doLogout();
    }

    public goToProfile() {
        this.router.navigate(["/settings/public"]);
    }

    public onStatusToggleClick(event: MatSlideToggleChange) {
        event.source.checked = !event.checked; // keep same value

        // stop going further
        if (this.verificationService.verificationNeeded()) {            
            return;
        }        
        
        // show popup
        const value = event.checked;
        this.confirmChangesPopup(value);        
    }
    
    // Delete addon
    public confirmChangesPopup(value: boolean) {

        const title = value ? "restaurant_settings_force_open_title" : "restaurant_settings_force_close_title";
        const content = value ? "restaurant_settings_force_open_description" : "restaurant_settings_force_close_description";
        const leftButton = value ? "restaurant_settings_force_open_left_action" : "restaurant_settings_force_close_left_action";
        const rightButton = value ? "restaurant_settings_force_open_right_action" : "restaurant_settings_force_close_right_action";

        const config = this.modalService.getDefaultDialogConfig();
        config.width = "500px";
        config.data = {
            title: new LocaleTranslatePipe(this.localeService).transform(title),
            midContent: new LocaleTranslatePipe(this.localeService).transform(content),
            leftButtonContent: new LocaleTranslatePipe(this.localeService).transform(leftButton),
            rightButtonContent: new LocaleTranslatePipe(this.localeService).transform(rightButton),
            preselectedButton: "left"
        }

        this.modalService.openConfirmDialog$(config).subscribe((response) => {
            if (response) { // YES pressed in the confirm modal                
                this.restaurantInfoService.setRestaurantClosed(!value);
            }
        });
    }
}
