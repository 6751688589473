<div class="main-nav-container">

    <!-- Section title -->

    <!-- Dashboard (unverified) -->
    <ng-container *ngIf="routerUrl.includes('/dashboard')">
        <ng-container *ngIf="(restaurantInfo$ | async)?.verificationStatus !== RestaurantVerificationEnum.VERIFIED">
            <div class="r-info-subtitle">
                {{ "dashboard_onboarding_title" | translate }}
            </div>
        </ng-container>

        <ng-container *ngIf="(restaurantInfo$ | async)?.verificationStatus === RestaurantVerificationEnum.VERIFIED">
            <div class="r-info-subtitle">
                {{ "side_nav_dashboard" | translate }}
            </div>
        </ng-container>
    </ng-container>

    <!-- Menu management -->
    <ng-container *ngIf="routerUrl.includes('/menu')">
        <div class="r-info-subtitle">
            {{ "menu_add_subtitle" | translate }}
        </div>
    </ng-container>

    <!-- Reviews -->
    <ng-container *ngIf="routerUrl.includes('/reviews')">
        <div class="r-info-subtitle">
            {{ "side_nav_reviews" | translate }}
        </div>
    </ng-container>

    <!-- Orders -->
    <ng-container *ngIf="routerUrl.includes('/orders')">
        <div class="r-info-subtitle">
            {{ "side_nav_orders" | translate }}
        </div>
    </ng-container>

    <!-- Restaurant settings -->
    <ng-container *ngIf="routerUrl.includes('/settings')">
        <div class="r-info-subtitle">
            {{ (!!(restaurantInfo$ | async)?.id ? "restaurant_info_add_subtitle" : "restaurant_info_add_subtitle_create") | translate }}
        </div>
    </ng-container>

    <!-- Reviews -->
    <ng-container *ngIf="routerUrl.includes('/accounts')">
        <div class="r-info-subtitle">
            {{ "side_nav_accounts" | translate }}
        </div>
    </ng-container>
    

    <!-- Submit verification -->
    <ng-container *ngIf="(needsVerification$ | async)">
        <ng-container *ngIf="(canSubmit$ | async); else verificationPending">
            <div class="submit-button">
                <fs-button [text]="'card_button_submit' | translate" [style]="'full-width'" 
                    [url]="'dashboard/submit-verification'"
                    [type]="'raised'" [leadingIcon]="'playlist_add_check'">
                </fs-button>
            </div>
        </ng-container>
        <ng-template #verificationPending>
            <ng-container *ngIf="(restaurantInfo$ | async)?.verificationStatus === RestaurantVerificationEnum.VERIFICATION_REQUESTED">
                <a [routerLink]="'/dashboard'" class="verification-status">
                    <div class="verification-status-elem-container pl-5 pr-5">
                        <div class="state-box-title">
                            {{ "verification_status_box_navbar_title" | translate }}
                        </div>
                        <div class="verification-status-box">
                            <div class="state-box-image">
                                <mat-icon class="state-img-mat">lens</mat-icon>
                            </div>
                            <div class="state-box-status">
                                {{ "verification_status_box_requested" | translate }}
                            </div>
                        </div>        
                    </div>
                </a>
            </ng-container>            
        </ng-template>
    </ng-container>


    <!-- Status -->    
    <div class="status-container" *ngIf="(restaurantInfo$ | async)">
        <div class="status-text"> {{ 'top_nav_status_title' | translate }}</div>
        <div class="status-toggle"
            #tooltip="matTooltip"
            [matTooltip]="'restaurant_interdiction_open_tooltip' | translate"
            [matTooltipPosition]="'below'"
            [matTooltipDisabled]="(!!(restaurantInfo$ | async)?.id) && (restaurantInfo$ | async)?.verificationStatus === RestaurantVerificationEnum.VERIFIED"
            [matTooltipClass]="'custom-interdiction-tooltip'"       
            (mousedown)="tooltip.toggle()" 
        >
            <div class="status-toggle-text" [ngClass]="{'open': (restaurantInfo$ | async)?.closed === false, 'closed': (restaurantInfo$ | async)?.closed}">
                <ng-container *ngIf="(restaurantInfo$ | async)?.closed; else statusOpen">
                    {{ 'top_nav_status_closed' | translate }}
                </ng-container>
                <ng-template #statusOpen>
                    {{ 'top_nav_status_open' | translate }}
                </ng-template>

            </div>
            <div class="status-toggle-switch">
                <mat-slide-toggle [color]="'primary'" [disableRipple]="false" [checked]="(restaurantInfo$ | async)?.closed === false"  (change)="onStatusToggleClick($event)"></mat-slide-toggle>
            </div>
        </div>
    </div>

    <!-- Language menu -->
    <div class="language-container">
        <nav-language-selector></nav-language-selector>
    </div>

    <!-- Profile -->
    <div class="mini-profile-container">
        <button mat-button [matMenuTriggerFor]="menu">
            <div class="logged-in-container">
                <!-- Logo -->
                <ng-container *ngIf="(restaurantInfo$ | async)?.logoImageUrl; else defaultLogo">
                    <div class="logo-container">
                        <img class="logo-image" [src]="(restaurantInfo$ | async)?.logoImageUrl || 'apps/restaurant/src/assets/navbar-restaurant-profile-house.svg'"/>
                    </div>
                </ng-container>                
                <!-- Default logo -->
                <ng-template #defaultLogo>
                    <img [src]="'apps/restaurant/src/assets/navbar-restaurant-profile-house.svg'" alt="Profile name"/>
                </ng-template>
                
                <!-- Restaurant name -->
                <div class="logged-in-text"> {{ (selfData$ | async)?.companyName }}</div>
                <img [src]="'apps/restaurant/src/assets/arrow-drop-down-address.svg'" alt="Food profile details"/>
            </div>
        </button>
        <mat-menu #menu="matMenu">            
            <button mat-menu-item (click)="goToProfile()"><mat-icon>person_outline</mat-icon>{{ 'top_nav_menu_profile' | translate }}</button>
            <button mat-menu-item (click)="doLogout()"><mat-icon>exit_to_app</mat-icon>{{ 'top_nav_menu_logout' | translate }}</button>
        </mat-menu>
    </div>
</div>