import { BehaviorSubject, Observable} from "rxjs";
import { Injectable} from "@angular/core";
import { OrderListResponse, OrderResponse } from "libs/shared-models/src/lib/order/order-response";

@Injectable({
    providedIn: 'root',
})
export class RestaurantOrdersStateService {

    private state$: BehaviorSubject<OrderResponse[]> = new BehaviorSubject<OrderResponse[]>([]);

    // Used only in the Orders List section
    private currentSelectedOrder$: BehaviorSubject<OrderResponse | null> = new BehaviorSubject<OrderResponse | null>(null);

    constructor(
    ) {
    }

    public getData$(): Observable<OrderResponse[]> {
        return this.state$.asObservable();
    }

    public getData(): OrderResponse[] {
        return this.state$.getValue();
    }

    public setData(value: OrderResponse[]): void {
        this.state$.next(value);
    }

    public getCurrentSelectedOrder(): OrderResponse | null {
        return this.currentSelectedOrder$.getValue();
    }

    public getCurrentSelectedOrder$(): Observable<OrderResponse | null> {
        return this.currentSelectedOrder$.asObservable();
    }

    public setCurrentSelectedOrder(value: OrderResponse | null) {
        return this.currentSelectedOrder$.next(value);
    }
}
