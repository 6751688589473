<div class="modal-top-bar">
    <div class="modal-top-left-action"></div>

    <!-- Title -->
    <div class="modal-top-title">
       {{ (!!(tempData$ | async)?.category?.id ? 'menu_category_modal_title_edit' : 'menu_category_modal_title_new') | translate }}
    </div>

    <!-- Cancel button -->
    <div class="modal-top-right-action" >
        <div class="action-item" (click)="onCancel()">
            {{ 'menu_product_modal_cancel' | translate }}
        </div>
    </div>
</div>

<div class="modal-content">

    <div class="modal-top-inputs">

      <!-- Left side -->
      <div class="addon-input-container-left">
        <!-- Name title -->
        <div class="subtitle-text">
          {{ 'menu_category_modal_subtitle_name' | translate }}
        </div>

        <!-- Deutsch -->
        <div class="names-column-container">
          <div class="names-column-1-container">
            <!-- Input Name -->
            <fs-input [label]="'menu_category_modal_input_name' | translate" [style]="'full-width'" class="r-info-public-name-input mr-6"
                      [inputValue]="(tempData$ | async)?.category?.name || ''" (valueUpdate)="nameUpdate($event)">
            </fs-input>
          </div>
        </div>
      </div>


      <!-- Right side -->
      <div class="addon-input-container-right">

        <!-- Selection title -->
        <div class="subtitle-text">
          {{ 'menu_category_modal_subtitle_description' | translate }}
        </div>

        <div class="names-column-container">
          <!-- Min selection -->
          <div class="names-column-1-container">
            <!-- Input Name -->
            <fs-input [label]="'menu_category_modal_input_description' | translate" [style]="'full-width'" class="r-info-public-name-input mr-6"
                      [inputValue]="(tempData$ | async)?.category?.description || ''" (valueUpdate)="descriptionUpdate($event)">
            </fs-input>
          </div>
        </div>
      </div>

    </div>


  <!-- Delete Add-on Group -->
  <ng-container *ngIf="!!(tempData$ | async)?.category?.id">
    <!-- Show only if it has an ID => meaning it was stored on API before. Otherwise, when creating a new Category, we shouldn't see the Delete option -->
    <div class="addon-delete-container">
      <div class="delete-action-area" (mousedown)="onDeleteClick()">
        <div class="flex mr-1">
          <img [src]="'apps/restaurant/src/assets/delete-bin-orange.svg'"/>
        </div>
        <div class="addon-delete-text">
          {{ 'menu_category_modal_delete' | translate }}
        </div>
      </div>
    </div>
  </ng-container>


    <!-- Products selection -->

    <div class="addons-selection-area">

      <div class="addon-title-dropdown">
        <!-- Subtitle -->
        <div class="subtitle-text">
          {{ 'menu_category_modal_products_title' | translate }}
        </div>

        <ng-container *ngIf="((tempData$ | async)?.products?.length || 0) > 0; else noProducts">
          <!-- Products list -->
          <div class="addon-list">
            <mat-chip-listbox aria-label="Addons selection">
              <ng-container *ngFor="let item of (tempData$ | async)?.products; index as i " class="category-list-item-container">
                <mat-chip-option color="primary" [id]="item.id" [selectable]="false" (removed)="onRemoveProduct(item)">
                  {{ item.name }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-option>

              </ng-container>
            </mat-chip-listbox>
          </div>
        </ng-container>

        <ng-template #noProducts>
            <div class="missing-addons">
                <div class="missing-addons-text">
                  <div>{{ 'menu_category_modal_products_missing' | translate }}</div>
                </div>
                <div class="missing-addons-button>">
                  <!--<div class="flex button-create-addon">
                    &lt;!&ndash; Button - Create addon &ndash;&gt;
                    &lt;!&ndash;<fs-button [text]="'menu_addon_create_group_popup_new_addon' | translate"
                               [type]="'raised'" [leadingIcon]="'add'"
                               (onPress)="addCreateAddonPress()">
                    </fs-button>&ndash;&gt;
                  </div>-->
                </div>
            </div>
        </ng-template>
      </div>

    </div>

    <div class="missing-addons-button>">
      <div class="flex button-create-addon">
        <!-- Button - Create product -->
        <fs-button [text]="'menu_button_add_product' | translate"
                   [type]="'raised'" [leadingIcon]="'add'"
                   (onPress)="addCreateProductPress()">
        </fs-button>
      </div>
    </div>

    <!-- Add or Save changes button -->
    <div class="modal-bottom-action">
        <fs-button [text]="(!!(tempData$ | async)?.category?.id ? 'menu_category_modal_submit_save' : 'menu_category_modal_submit_add') | translate" [style]="'full-width-large-height'"
                   [type]="'raised-primary'" (onPress)="onAddGroupClick()" [disabled]="(isButtonDisabled$() | async) || false">
        ></fs-button>
    </div>
</div>

