<main-layout [selected]="'MENU'">
    <content class="w-full flex">
        <div class="menu-main-container">

          <mat-tab-group (selectedTabChange)="onTabChange($event)" class="h-full">
            <!--<mat-tab-group (selectedTabChange)="onTabChange($event)" class="h-full">-->


            <!--
                ******************  Categories and products list  ******************
            -->

            <mat-tab [aria-label]="'categories'">
              <!-- Tab and title -->
              <ng-template mat-tab-label>  
                <img class="tab-icon"
                     [src]="'apps/restaurant/src/assets/food-burger-product' + ((currentTab | async) === 'categories' ? '-selected' : '') + '.svg'"
                />
                <div class="pl-2">
                  {{ 'menu_product_tab_title' | translate }}
                </div>
              </ng-template>

              <!-- Content -->

                <!-- Add New Menu section -->
                <ng-container>
                  <div class="add-new-container">

                    <!-- Please create a restaurant first  -->
                    <ng-container *ngIf="!(((menuListState$ | async)?.menuList?.length ?? 0) > 0); else existingMenu"
                                  [ngTemplateOutlet]="missingRestaurant">
                    </ng-container>

                    <!-- Missing Restaurant (template reused reused in Categories and Addons)-->
                    <ng-template #missingRestaurant>
                      <!-- Info blue box -->
                      <div class="r-menu-name-default-box">
                        <!-- Info icon -->
                        <div class="r-menu-name-default-box-icon">
                          <img [src]="'apps/restaurant/src/assets/add-menu-blue-info.svg'"/>
                        </div>
                        <!-- Default text -->
                        <div class="r-menu-name-default-box-text">
                          <div class="r-menu-name-box-title">{{ 'menu_no_restaurant_infobox_title' | translate }}</div>
                          <div class="r-menu-name-box-subtitle">{{ 'menu_no_restaurant_infobox_description' | translate }}</div>
                        </div>
                      </div>

                      <a [routerLink]="'/settings'" class="ml-5 mr-10 w-fit">
                        <card-button [icon]="'card-assign-restaurant'" [key]="'card_button_assign_restaurant'">
                        </card-button>
                      </a>
                    </ng-template>


                    <!-- Existing (current) menu categories -->
                    <ng-template #existingMenu>
                      
                      <!-- Top bar (search + expand)-->
                      <div class="top-bar-search-expand">
                        <!-- Search -->
                        <div class="category-search">
                          <fs-input [label]="'menu_search_bar_label' | translate" [style]="'full-width'" class="mr-4 category-search-input"
                                [appearanceStyle]="'outline'" [subscriptSizing]="'dynamic'"
                                (valueUpdate)="onSearchProductUpdate($event)" [inputValue]="currentCategorySearchInput"
                                [prefixIcon]="'search'" [trailingIcon]="!!currentCategorySearchInput ? 'close' : ''" 
                                (trailingIconPress)="clearCategorySearchPress()" [keepTrailingIconSpace]="true"
                                >
                          </fs-input>
                          <ng-container *ngIf="!!currentCategorySearchInput"> 
                            <div class="category-search-clear">
                              <fs-link [text]="'menu_search_clear' | translate" [buttonBehavior]="true" (press)="clearCategorySearchPress()"></fs-link>
                            </div>
                          </ng-container>
                        </div>

                        <!-- Expand / collapse-->
                        <div class="r-menu-category-title">
                          <ng-container *ngIf="((menuCategoryListState$ | async)?.menuCategoryList?.length || 0) > 0">
                            <div class="expand-all-container">
                              <div (mousedown)="onExpandAllCategoriesClick($event)" class="expand-all-text">{{ "menu_section_expand_all" | translate }}</div>
                              <div (mousedown)="onExpandAllCategoriesClick($event)" class="flex mr-2" >
                                <img [src]="'apps/restaurant/src/assets/arrows-expand.svg'" class="expand-all-icon" title="{{ 'menu_section_expand_all' | translate }}"/>
                              </div>
                              <div (mousedown)="onCollapseAllCategoriesClick($event)" class="flex">
                                <img [src]="'apps/restaurant/src/assets/arrows-collapse.svg'" class="expand-all-icon" title="{{ 'menu_section_collapse_all' | translate }}"/>
                              </div>
                            </div>
                          </ng-container>
                        </div>

                      </div>

                      

                      <!-- List of existing categories -->
                      <ng-container *ngIf="((menuCategoryListState$ | async)?.menuCategoryList?.length || 0) > 0; else noProductCategories">
                        <div cdkDropList class="container-category-list" (cdkDropListDropped)="drop($event)">

                          <!-- Each category -->
                          <div cdkDrag *ngFor="let category of (menuCategoryListState$ | async)?.menuCategoryList; index as i" class="category-list-item-container">
                            <div class="category-list-item"
                                 *ngIf="!productsSearchResults.get(category.id) || productsSearchResults.get(category.id)?.visible"
                                 (mousedown)="onClickCategory($event, category)"
                                 (mouseenter)="onCategoryMouseEnter($event, category)"
                                 (mouseleave)="onCategoryMouseLeave($event, category)">
                              <div cdkDragHandle class="category-list-item-drag-icon">
                                <img [id]="'dragHandler'" [src]="'apps/restaurant/src/assets/three-dots-drag-handler.svg'"/>
                              </div>
                              <div class="category-list-item-name">
                                {{ category.name }}

                                <!-- Edit category-->
                                <ng-container *ngIf="showCategoryEdit.get(category.id)">
                                  <div class="addon-edit-area" (mousedown)="onClickCategoryEdit($event, category)">
                                    <span class="addon-edit-image">
                                      <img [src]="'apps/restaurant/src/assets/edit-pencil.svg'"/>
                                    </span>
                                    {{ "menu_addon_section_edit_button" | translate }}
                                  </div>
                                </ng-container>
                              </div>

                              <div class="category-list-item-dropdown-icon">
                                <ng-container *ngIf="(isCategoryOpen$(category.id) | async); else collapsedIcon">
                                  <img [src]="'apps/restaurant/src/assets/dropdown-arrow-up.svg'"/>
                                </ng-container>
                                <ng-template #collapsedIcon>
                                  <img [src]="'apps/restaurant/src/assets/dropdown-arrow-down.svg'"/>
                                </ng-template>

                              </div>
                            </div>

                            <!-- Expanded products -->
                            <div *ngIf="(isCategoryOpen$(category.id) | async)">

                              <div [@inOutBoxAnimation]>
                                <!-- Products list -->
                                <ng-container *ngIf="(isProductsListVisible$(category.id) | async); else noProductsInCategory">

                                  <ng-container *ngFor="let product of (productsPerCategory$(category.id) | async); index as i">
                                    <div *ngIf="!productsSearchResults.get(product.id) || productsSearchResults.get(product.id)?.visible" 
                                          class="product-list-item" [ngClass]="{'unavailable': !product.available}" (mousedown)="onProductClick($event, product)">                                                                                                             
                                                                                
                                        <div class="product-logo-name-container">
                                          <!-- Logo-->
                                          <div class="product-logo">         
                                            <!-- actual image -->
                                            <ng-container *ngIf="!!product.imageUrl; else defaultImage">
                                              <div class="product-logo-real-image">
                                                <img class="product-logo-real-image-src" [src]="product?.imageUrl || 'apps/restaurant/src/assets/add-image-default.svg'"/>
                                              </div>
                                            </ng-container>
                                            <!-- default image-->
                                            <ng-template #defaultImage>
                                              <div class="product-logo-default">
                                                <div class="product-logo-default-icon">
                                                  <img [src]="'apps/restaurant/src/assets/add-image-default.svg'"/>
                                                </div>
                                              </div>
                                            </ng-template>
                                          </div>    
                                          <!-- Name -->
                                          <div class="product-list-name">
                                            {{ product.name }}
                                          </div>
                                        </div>
                                        
                                        <!-- Price -->
                                        <div class="product-list-price">
                                          {{ product.priceCents | format_cents: true }}
                                          <!-- Availability -->
                                          <div class="product-list-availability">
                                            <ng-container *ngIf="product.available; else notAvailable">
                                              <img [src]="'apps/restaurant/src/assets/status-available.svg'" [title]="'menu_product_modal_available' | translate"/>
                                            </ng-container>
                                            <ng-template #notAvailable>
                                              <img [src]="'apps/restaurant/src/assets/status-unavailable-red.svg'" [title]="'menu_product_modal_unavailable' | translate"/>
                                            </ng-template>
                                          </div>
                                        </div>
                                    </div>
                                  </ng-container>                                  
                                </ng-container>

                                <!-- No products -->
                                <ng-template #noProductsInCategory>
                                  <ng-container *ngIf="!productsSearchResults.get(category.id) || productsSearchResults.get(category.id)?.visible">
                                    <div class="product-list-no-item">
                                      <span><img [src]="'apps/restaurant/src/assets/arrow-top-to-right.svg'"/></span>
                                      <span class="pl-2 pr-2"> {{ "menu_section_no_products_in_category" | translate }} </span>
                                      <fs-link [text]="'menu_section_no_products_in_category_add' | translate" [buttonBehavior]="true" (press)="addProductPress($event, category.id)"></fs-link>
                                    </div>
                                  </ng-container>
                                </ng-template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <!-- No categories text -->
                      <ng-template #noProductCategories>
                        <div class="no-categories-text">
                          {{ "menu_no_product_categories" | translate }}
                        </div>
                      </ng-template>


                      <!-- Add new functionality list -->
                      <ng-container *ngFor="let item of tempAddCategoryList$ | async; index as i">
                        <div class="add-new-category-container">
                          <!-- Name -->
                          <fs-input [label]="'menu_add_section_details_name' | translate" [style]="'full-width'" class="mr-6 r-info-public-name-input"
                                    [inputValue]="item.name || ''"
                                    (valueUpdate)="onAddNameUpdate($event, item)">
                          </fs-input>
                          <!-- Description -->
                          <fs-input [label]="'menu_add_section_details_description' | translate" [style]="'full-width'" class="mr-6 r-info-public-name-input"
                                    [inputValue]="item.description || ''"
                                    (valueUpdate)="onAddDescriptionUpdate($event, item)">
                          </fs-input>
                          <!-- Confirm / check button -->
                          <div class="flex button-add-action-category mr-4">
                            <fs-button [style]="'full-width-large-height'"
                                       [type]="'raised-custom-icon-only'" [leadingIcon]="'check'"
                                       [leadingIconStyle]="'custom-raised-icon-valid'"
                                       (onPress)="addCategoryConfirmPress($event, item)">
                            </fs-button>
                          </div>
                          <div class="flex button-add-action-category">
                            <fs-button [style]="'full-width-large-height'"
                                       [type]="'raised-custom-icon-only'" [leadingIcon]="'close'"
                                       [leadingIconStyle]="'custom-raised-icon-cancel'"
                                       (onPress)="addCategoryCancelPress($event, item)">
                            </fs-button>
                          </div>
                          <!-- Delete category button -->
                        </div>
                      </ng-container>

                      <!-- Buttons -->
                      <div class="flex w-full flex-row bottom-addon-buttons">

                        <!-- Button - Add Category -->
                        <div class="flex button-add-category">
                          <fs-button [text]="'menu_button_add_category' | translate" [style]="'full-width-large-height'"
                                     [type]="(((menuCategoryListState$ | async)?.menuCategoryList?.length || 0) > 0)
                                                    || ((tempAddCategoryList$ | async)?.length || 0) > 0
                                           ? 'raised' : 'raised-primary'" [leadingIcon]="'add'"
                                     (onPress)="addCategoryPress()">
                          </fs-button>
                        </div>

                        <!-- Button - Add Product -->
                        <ng-container *ngIf="((menuCategoryListState$ | async)?.menuCategoryList?.length || 0) > 0">
                          <div class="flex button-add-category ml-5">
                            <fs-button [text]="'menu_button_add_product' | translate" [style]="'full-width-large-height'"
                                       [type]="(((productsListState$ | async)?.categories?.length || 0) > 0) ? 'raised' : 'raised-primary'" [leadingIcon]="'add'"
                                       (onPress)="addProductPress($event)">
                            </fs-button>
                          </div>
                        </ng-container>

                        <!-- Button - Info -->
                        <ng-container>
                          <div class="button-info-category" (click)="onInfoStructureClick('products')">
                            <mat-icon class="button-info-icon">info</mat-icon> {{ "menu_info_modal_button" | translate }}
                          </div>
                        </ng-container>

                      </div>
                    </ng-template>

                  </div>
                </ng-container>


            </mat-tab>


            <!--
                ******************  Add-ons / Extra  ******************
            -->
            <mat-tab [aria-label]="'addons'">
              <!-- Tab icon and title -->
              <ng-template mat-tab-label>
                <img class="tab-icon"
                     [src]="'apps/restaurant/src/assets/menu-addon' + ((currentTab | async) === 'addons' ? '-selected' : '') + '.svg'"
                />
                <div class="pl-2">
                  {{ 'menu_addon_tab_title' | translate }}
                </div>
              </ng-template>

              <!-- Content -->
              <div class="addon-content-container">
                <!-- Please create a restaurant first -->
                <ng-container *ngIf="!(((menuListState$ | async)?.menuList?.length ?? 0) > 0); else existingMenuAddons"
                              [ngTemplateOutlet]="missingRestaurant">
                </ng-container>

                <ng-template #existingMenuAddons>

                  <!-- Top bar (search + expand) Add-on Groups & Add-ons -->
                  <div class="top-bar-search-expand">
                    <!-- Search -->
                    <div class="category-search" >
                      <fs-input [label]="'menu_search_bar_addon_label' | translate" [style]="'full-width'" class="mr-4 category-search-input"
                            [appearanceStyle]="'outline'" [subscriptSizing]="'dynamic'" [floatLabel]="'always'"
                            (valueUpdate)="onSearchAddonUpdate($event)" [inputValue]="currentAddonSearchInput"
                            [prefixIcon]="'search'" [trailingIcon]="!!currentAddonSearchInput ? 'close' : ''" 
                            (trailingIconPress)="clearAddonSearchPress()" [keepTrailingIconSpace]="true"
                            >
                      </fs-input>
                      <ng-container *ngIf="!!currentAddonSearchInput"> 
                        <div class="category-search-clear">
                          <fs-link [text]="'menu_search_clear' | translate" [buttonBehavior]="true" (press)="clearAddonSearchPress()"></fs-link>
                        </div>
                      </ng-container>
                    </div>

                    <!-- Addon Groups Expand functionality -->
                    <div class="r-menu-category-title">
                      <ng-container *ngIf="((addonsGroupListState$ | async)?.addonGroupList?.length || 0) > 0">
                        <div class="expand-all-container">
                          <div (mousedown)="onExpandAllAddonGroupsClick($event)" class="expand-all-text">{{ "menu_section_expand_all" | translate }}</div>
                          <div (mousedown)="onExpandAllAddonGroupsClick($event)" class="flex mr-2" >
                            <img [src]="'apps/restaurant/src/assets/arrows-expand.svg'" class="expand-all-icon" title="{{ 'menu_section_expand_all' | translate }}"/>
                          </div>
                          <div (mousedown)="onCollapseAllAddonGroupsClick($event)" class="flex">
                            <img [src]="'apps/restaurant/src/assets/arrows-collapse.svg'" class="expand-all-icon" title="{{ 'menu_section_collapse_all' | translate }}"/>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>


                  <!-- List of existing Addons -->
                  <ng-container *ngIf="((addonsGroupListState$ | async)?.addonGroupList?.length || 0) > 0; else noAddonGroups">
                    <div class="container-category-list">

                      <!-- Each Addon Group -->
                      <div *ngFor="let addonGroup of (addonsGroupListState$ | async)?.addonGroupList; index as i" class="category-list-item-container">

                        <!-- Addon Group list item -->
                        <div class="category-list-item"
                            *ngIf="!addonsSearchResults.get(addonGroup.id) || addonsSearchResults.get(addonGroup.id)?.visible"
                             (mousedown)="onClickAddonGroup($event, addonGroup)"
                             (mouseenter)="onAddonListMouseEnter($event, addonGroup)"
                             (mouseleave)="onAddonListMouseLeave($event, addonGroup)">

                          <div class="category-list-item-name">
                            <div class="flex mr-3">
                              <img [src]="'apps/restaurant/src/assets/addon-group.svg'"/>
                            </div>
                            {{ addonGroup.name }}

                            <!-- Edit addon group-->
                            <ng-container *ngIf="showAddonGroupEdit.get(addonGroup.id)">
                              <div class="addon-edit-area" (mousedown)="onClickAddonGroupEdit($event, addonGroup)">
                                <span class="addon-edit-image">
                                  <img [src]="'apps/restaurant/src/assets/edit-pencil.svg'"/>
                                </span>
                                {{ "menu_addon_section_edit_button" | translate }}
                              </div>
                            </ng-container>

                          </div>

                          <!-- Up / Down arrows per addon group -->
                          <div class="category-list-item-dropdown-icon">
                            <ng-container *ngIf="(isAddonGroupOpen$(addonGroup.id) | async); else collapsedIcon">
                              <img [src]="'apps/restaurant/src/assets/dropdown-arrow-up.svg'"/>
                            </ng-container>
                            <ng-template #collapsedIcon>
                              <img [src]="'apps/restaurant/src/assets/dropdown-arrow-down.svg'"/>
                            </ng-template>
                          </div>
                        </div>

                        <!-- Expanded add-ons per group -->
                        <div *ngIf="(isAddonGroupOpen$(addonGroup.id) | async)">

                          <div [@inOutBoxAnimation]>
                            <!-- Addons list -->
                            <ng-container *ngIf="addonGroup.addonList.length > 0; else noAddonsInGroup">
                              <ng-container *ngFor="let addon of addonGroup.addonList; index as i">
                                <div class="product-list-item"
                                    *ngIf="!addonsSearchResults.get(addon.id) || addonsSearchResults.get(addon.id)?.visible" 
                                    [ngClass]="{'unavailable': !addon.available}"
                                    (mousedown)="onAddonClick($event, addon, addonGroup)">
                                
                                  <div class="product-logo-name-container">
                                    <!-- Logo-->
                                    <div class="product-logo">                                      
                                      <div class="product-logo-default">
                                          <div class="product-logo-default-icon">
                                            <img [src]="'apps/restaurant/src/assets/add-image-default.svg'"/>
                                          </div>
                                      </div>
                                    </div>    
                                    <!-- Name -->
                                    <div class="product-list-name">
                                      {{ addon.name }}
                                    </div>
                                  </div>

                                  <!-- Price -->
                                  <div class="product-list-price">
                                    {{ addon.priceCents | format_cents: true }}
                                    <!-- Availability -->
                                    <div class="product-list-availability">
                                      <ng-container *ngIf="addon.available; else notAvailable">
                                        <img [src]="'apps/restaurant/src/assets/status-available.svg'" [title]="'menu_product_modal_available' | translate"/>
                                      </ng-container>
                                      <ng-template #notAvailable>
                                        <img [src]="'apps/restaurant/src/assets/status-unavailable-red.svg'" [title]="'menu_product_modal_unavailable' | translate"/>
                                      </ng-template>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>                              
                            </ng-container>

                            <!-- No addons -->
                            <ng-template #noAddonsInGroup>
                              <div class="product-list-no-item addon">
                                <span><img [src]="'apps/restaurant/src/assets/arrow-top-to-right.svg'"/></span>
                                <span class="pl-2 pr-2"> {{ "menu_addon_section_no_addons_in_group" | translate }} </span>
                                <fs-link [text]="'menu_addon_section_no_addons_in_group_add' | translate" [buttonBehavior]="true"
                                         (press)="addAddonPress(addonGroup)">
                                </fs-link>

                              </div>
                            </ng-template>
                          </div>

                        </div>


                      </div>
                    </div>
                  </ng-container>

                  <!-- No addon groups text -->
                  <ng-template #noAddonGroups>
                    <div class="no-categories-text">
                      {{ "menu_no_addon_groups" | translate }}
                    </div>
                  </ng-template>


                  <!-- Buttons -->
                  <div class="flex w-full flex-row bottom-addon-buttons">

                    <!-- Button - Create Addon Group -->
                    <div class="flex button-add-category">
                      <fs-button [text]="'menu_addon_create_group_button' | translate" [style]="'full-width-large-height'"
                                 [type]="(((addonsGroupListState$ | async)?.addonGroupList?.length || 0) > 0)
                                           ? 'raised' : 'raised-primary'" [leadingIcon]="'add'"
                                 (onPress)="addAddonGroupPress()">
                      </fs-button>
                    </div>

                    <!-- Button - Create Addon -->
                    <ng-container *ngIf="((addonsGroupListState$ | async)?.addonGroupList?.length || 0) > 0">
                      <div class="flex button-add-category ml-5">
                        <fs-button [text]="'menu_addon_create_addon_button' | translate" [style]="'full-width-large-height'"
                                   [type]="(((addonsGroupListState$ | async)?.addonGroupList?.length || 0) > 0) ? 'raised' : 'raised-primary'" [leadingIcon]="'add'"
                                   (onPress)="addAddonPress()">
                        </fs-button>
                      </div>
                    </ng-container>

                    <!-- Button - Info -->
                    <ng-container>
                      <div class="button-info-category" (click)="onInfoStructureClick('addons')">
                        <mat-icon class="button-info-icon">info</mat-icon> {{ "menu_info_modal_button" | translate }}
                      </div>
                    </ng-container>

                  </div>
                </ng-template>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
    </content>
</main-layout>

