<div class="modal-top-bar">
    <div class="modal-top-left-action"></div>

    <!-- Title -->
    <div class="modal-top-title">
        {{ (!!(tempData$ | async)?.id ? 'menu_product_modal_title_edit' : 'menu_product_modal_title') | translate }}
    </div>

    <!-- Cancel button -->
    <div class="modal-top-right-action" >
        <div class="action-item" (click)="onCancel()">
            {{ 'menu_product_modal_cancel' | translate }}
        </div>
    </div>
</div>

<div class="modal-content">

  <!-- Tab group -->
  <mat-tab-group (selectedTabChange)="onTabChange($event)" class="h-full">
    <!--
        ******************  Tab 1 (Main details) ******************
    -->
    <mat-tab [aria-label]="'product-main-details'">
      <!-- Tab and title -->
      <ng-template mat-tab-label>
        <img class="tab-icon"
             [src]="'apps/restaurant/src/assets/menu-main-fields' + ((currentTab | async) === 'product-main-details' ? '-selected' : '') + '.svg'"
        />
        <div class="pl-2">
          {{ 'menu_add_product_tab_main' | translate }}
        </div>
      </ng-template>

      <!-- Content -->
      <ng-container>
        <div class="flex flex-col mt-7">
          <!-- Name & Description input -->
          <div class="subtitle-text">
            {{ 'menu_product_modal_name_subtitle' | translate }}
          </div>

          <!-- Deutsch -->
          <div class="names-row-container">
            <div class="icon-language-container">
              <img class="icon-language" [src]="'apps/restaurant/src/assets/flag-austria.svg'"/>
            </div>
            <div class="names-column-1-container">
              <fs-input [label]="'menu_product_modal_name_de' | translate" [style]="'full-width'" class="r-info-public-name-input mr-6"
                        [inputValue]="(tempData$ | async)?.name || ''" (valueUpdate)="nameUpdate($event)">
              </fs-input>
            </div>
            <div class="names-column-2-container">
              <fs-input [label]="'menu_product_modal_description_de' | translate" [style]="'full-width'" class="r-info-public-name-input"
                        [inputValue]="(tempData$ | async)?.description || ''" (valueUpdate)="descriptionUpdate($event)">
              </fs-input>
            </div>
          </div>

          <!-- English -->
          <div class="names-row-container">
            <div class="icon-language-container">
              <img class="icon-language" [src]="'apps/restaurant/src/assets/globe-green-round-v2.svg'"/>
            </div>
            <div class="names-column-1-container">
              <fs-input [label]="'menu_product_modal_name_en' | translate" [style]="'full-width'" class="r-info-public-name-input mr-6"
                        [inputValue]="(tempData$ | async)?.nameEn || ''" (valueUpdate)="nameEnUpdate($event)">
              </fs-input>
            </div>
            <div class="names-column-2-container">
              <fs-input [label]="'menu_product_modal_description_en' | translate" [style]="'full-width'" class="r-info-public-name-input"
                        [inputValue]="(tempData$ | async)?.descriptionEn || ''" (valueUpdate)="descriptionEnUpdate($event)">
              </fs-input>
            </div>
          </div>


          <div class="price-logo-container" [ngClass]="{'inactive': (isActiveDuringHours$ | async) === true}">

              <div class="price-main-container">
                <!-- Price -->
                <div class="subtitle-text pt-3">
                  {{ (((restaurantData$ | async)?.hasFoodisDelivery || (restaurantData$ | async)?.hasOwnDelivery) 
                        ? 'menu_product_modal_price_subtitle' : 'menu_product_modal_price_pickup_subtitle'  )| translate }}
                </div>
                <div class="prices-container">
                  <div class="prices-inputs">
                    <!-- Euro price basis -->
                    <div class="prices-column-1-container">
                      <div class="icon-price-container" [ngClass]="{'inactive': (isActiveDuringHours$ | async) === true}">
                        <img class="icon-price" [src]="'apps/restaurant/src/assets/euro-sign-round.svg'"/>
                      </div>
                      
                      <!-- Price pickup - editable input -->
                      <ng-container *ngIf="(isActiveDuringHours$ | async) === false; else pricePickupViewOnly">       
                        <fs-input [label]="'menu_product_modal_price' | translate" [style]="'full-width'" class="r-product-price-input" [type]="'number'"
                                [inputValue]="'' + ((tempData$ | async)?.price || '')" (valueUpdate)="priceUpdate($event)">
                        </fs-input>
                      </ng-container>

                      <!-- Only show the pick-up price in plain text-->
                      <ng-template #pricePickupViewOnly>
                          <div class="r-product-price-delivery-read-box">
                            <div class="r-product-price-delivery-read-title" [ngClass]="{'delivery-price-big' : !(tempData$ | async)?.price}">
                                {{ "menu_product_modal_price" |  translate }}
                            </div>
                            <div class="r-product-price-delivery-read-price" [ngClass]="{'inactive': (isActiveDuringHours$ | async) === true}">
                                {{  ((tempData$ | async)?.price || '') }} 
                                <!-- Edit button -->
                                <div (mousedown)="onEditPickupPricePress()">
                                  <img class="r-product-price-delivery-edit" [ngClass]="{'inactive': (isActiveDuringHours$ | async) === true}" [src]="'apps/restaurant/src/assets/edit-pencil.svg'"/>
                                </div>                                  
                            </div>
                          </div>
                      </ng-template>
                    </div>


                    <!-- Price with delivery -->
                    <div class="prices-column-2-container">

                      <ng-container *ngIf="(restaurantData$ | async)?.hasFoodisDelivery || (restaurantData$ | async)?.hasOwnDelivery">                    
                        <div class="icon-price-container" [ngClass]="{'inactive': (isActiveDuringHours$ | async) === true}">
                          <img class="icon-price" [src]="'apps/restaurant/src/assets/plus-delivery.svg'"/>
                        </div>

                        <!-- Price delivery - editable input -->
                        <ng-container *ngIf="(isPriceDeliveryEdited$ | async); else priceDeliveryViewOnly">       
                          <fs-input [label]="'menu_product_modal_price_delivery' | translate" [style]="'full-width'" class="r-product-price-delivery-input" [type]="'number'"
                                    [inputValue]="'' + ((tempData$ | async)?.deliveryPrice || '')" (valueUpdate)="priceDeliveryUpdate($event)"
                                    (onKeyUp)="onKeyUpDeliveryPrice($event)">
                          </fs-input>
                        </ng-container>

                        <!-- Only show the delivery price in plain text-->
                        <ng-template #priceDeliveryViewOnly>
                            <div class="r-product-price-delivery-read-box">
                              <div class="r-product-price-delivery-read-title" [ngClass]="{'delivery-price-big' : !(tempData$ | async)?.deliveryPrice}">
                                  {{ "menu_product_modal_price_delivery" |  translate }}
                              </div>
                              <div class="r-product-price-delivery-read-price" [ngClass]="{'inactive': (isActiveDuringHours$ | async) === true}">
                                  {{  ((tempData$ | async)?.deliveryPrice || '') }} 
                                  <!-- Edit button -->
                                  <ng-container *ngIf="!!(tempData$ | async)?.deliveryPrice">
                                    <div (mousedown)="onEditDeliveryPricePress()">
                                      <img class="r-product-price-delivery-edit" [ngClass]="{'inactive': (isActiveDuringHours$ | async) === true}" [src]="'apps/restaurant/src/assets/edit-pencil.svg'"/>
                                    </div>                                    
                                  </ng-container>                                  
                              </div>
                            </div>
                        </ng-template>

                        <!-- Warning calculation -->
                        <ng-container *ngIf="(showWarningCalculation$ | async); else deliveryPriceInfo">    
                          <mat-icon class="warning-icon">warning</mat-icon>     
                        </ng-container>
                        
                        <!-- Blue info icon -->
                        <ng-template #deliveryPriceInfo>
                          <div class="selection-info-container" *ngIf="(isActiveDuringHours$ | async) === false">
                            <div class="price-tooltip-container" #tooltip="matTooltip"
                                [matTooltip]="'menu_product_modal_price_info' | translate"
                                [matTooltipPosition]="'above'"
                                [matTooltipClass]="'custom-selection-tooltip'"
                                (mousedown)="tooltip.toggle()">
                              <img class="infobox-image" [src]="'apps/restaurant/src/assets/add-menu-blue-info.svg'"/>
                            </div>
                          </div>
                        </ng-template>
                        
                      </ng-container>
                      
                    </div>
                  </div>

                </div>
              </div>
            
              <div class="image-main-container">
                <!-- Product image title -->
                <div class="subtitle-text pt-3">
                  {{ 'menu_product_modal_image_subtitle' | translate }}
                </div>
                <!-- Image zone -->
                <div class="image-container" (mousedown)="onImagePress()">       
                  
                  <!-- The image -->
                  <ng-container *ngIf="!!(tempData$ | async)?.imageUrl; else defaultImage">
                      <div class="product-image-filled">
                        <img class="product-image-filled-src" [src]="(tempData$ | async)?.imageUrl || 'apps/restaurant/src/assets/add-image-default.svg'"/>
                      </div>
                  </ng-container>

                  <!-- Default (not set) image -->
                  <ng-template #defaultImage>
                    <div class="product-image-default">
                      <div class="product-image-default-icon">
                        <img [src]="'apps/restaurant/src/assets/add-image-default.svg'"/>
                      </div>
                    </div>
                  </ng-template>

                  <!-- Button name -->
                  <div class="product-image-button">
                    <fs-link [text]="(!!(tempData$ | async)?.imageUrl ? 'menu_product_modal_image_change' : 'menu_product_modal_image_add') | translate" [buttonBehavior]="true"></fs-link>
                  </div>
                  
                </div>
              </div>
          </div>
          

          <!-- Category, Availability -->
          <div class="more-product-options pt-3">

            <!-- Availability -->
            <div class="product-availability-container">
              <div class="subtitle-text availability">
                {{ 'menu_product_modal_availability_subtitle' | translate }}
              </div>

              <div class="status-toggle-switch">
                <mat-slide-toggle [color]="'primary'" [disableRipple]="false" [checked]="(tempData$ | async)?.available || false"
                                  (change)="onAvailabilityToggleClick($event)"></mat-slide-toggle>
              </div>
            </div>

            <!-- Category -->
            <div class="product-category-container">
              <div class="subtitle-text category">
                {{ 'menu_product_modal_category_subtitle' | translate }}
              </div>
              <fs-menu-advanced
                [itemsList]='(categoryList$ | async)?.menuCategoryList || []'
                [selectedItem]="(getCategoryById$((tempData$ | async)?.menuCategoryId) | async)"
                [preselectPlaceholder]="'menu_product_modal_category_pick' | translate"
                (onSelect)="onSelectCategory($event)">
              </fs-menu-advanced>
            </div>

            <!-- Delete -->
            <ng-container *ngIf="!!(tempData$ | async)?.id">
              <!-- Show only if it has an ID => meaning it was stored on API before. Otherwise, when creating a new product, we shouldn't see the Delete option -->
              <div class="product-delete-container">
                <div class="delete-action-area" (mousedown)="onDeleteClick()">
                  <div class="flex mr-1">
                    <img [src]="'apps/restaurant/src/assets/delete-bin-orange.svg'"/>
                  </div>
                  <div class="product-delete-text">
                    {{ 'menu_product_modal_delete_button' | translate }}
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </mat-tab>

    <!--
        ******************  Tab 2 (Secondary details) ******************
    -->
    <mat-tab [aria-label]="'secondary-details'">
      <!-- Tab and title -->
      <ng-template mat-tab-label>
        <img class="tab-icon"
             [src]="'apps/restaurant/src/assets/menu-secondary-fields' + ((currentTab | async) === 'secondary-details' ? '-selected' : '') + '.svg'"
        />
        <div class="pl-2">
          {{ 'menu_add_product_tab_secondary' | translate }}
        </div>
      </ng-template>

      <!-- Content -->
      <ng-container>
        <div class="flex flex-col mt-7">
          <!-- Allergens -->
          <div class="product-alergens-container">
            <div class="subtitle-text availability-allergen flex flex-row">
              {{ 'menu_product_modal_allergen_subtitle' | translate }}
              <!-- Open external link -->
              <a [href]="externalAllergenURL" target="_blank" class="external-link-container ml-2">
                <img [src]="'apps/restaurant/src/assets/external-link-file.svg'"/>
              </a>
            </div>
            <div class="flex">
              <mat-chip-listbox aria-label="Availability selection" [multiple]="true">
                <ng-container *ngFor="let item of getAllAllergenList(); index as i ">
                  <mat-chip-option [selected]="isAllergenSelected$(item) | async" color="primary" [id]="item" (selectionChange)="onAllergenChange($event, item)"
                    #tooltip="matTooltip"
                    [matTooltip]="item + ': ' + (('menu_allergen_name_' + item.toLocaleLowerCase()) | translate)"
                    [matTooltipPosition]="'above'"
                    [matTooltipClass]="'custom-selection-tooltip-small'"
                    (mousedown)="tooltip.toggle()"
                  >
                      {{ item }}
                  </mat-chip-option>
                </ng-container>
              </mat-chip-listbox>
            </div>
          </div>

          <!-- Add-on groups -->

          <div class="product-addon-groups-container">
            <div class="subtitle-text availability-allergen">
              {{ 'menu_add_product_tab_secondary_addons' | translate }}
            </div>

            <ng-container *ngIf="((tempData$ | async)?.addonGroupIds?.length || 0) === 0; else assignedAddonGroups">
              {{ 'menu_add_product_tab_secondary_addons_none' | translate }}
            </ng-container>

            <!-- Assigned add-on groups -->
            <ng-template #assignedAddonGroups>
              <div class="flex">
                <mat-chip-listbox aria-label="Assigned Addons Groups">
                  <ng-container *ngFor="let groupId of (tempData$ | async)?.addonGroupIds; index as i " class="category-list-item-container">
                    <mat-chip-option color="primary" [id]="groupId" [selectable]="false" (removed)="onRemoveAddonGroup(groupId)">
                      {{ (getFullAddonGroupById$(groupId) | async)?.name }}
                      <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip-option>
                  </ng-container>
                </mat-chip-listbox>
              </div>
            </ng-template>


            <!-- Available add-on groups -->
            <div class="available-add-ons">
              {{ 'menu_add_product_tab_secondary_addons_available' | translate }}
            </div>
            <div class="flex mt-3">

              <ng-container *ngIf="((allAddonGroups | async)?.length || 0) === 0; else availableAddongroups">
                {{ 'menu_add_product_tab_secondary_addons_none' | translate }}
              </ng-container>

              <ng-template #availableAddongroups>
                <mat-chip-listbox aria-label="Available add-ons" [multiple]="true">
                  <ng-container *ngFor="let addonGroup of ((allAddonGroups | async) || []); index as i ">
                    <mat-chip-option [selected]="false" color="primary" [id]="addonGroup.id" (selectionChange)="onAddonGroupSelect($event, addonGroup)">
                      <!--<mat-chip-option [selected]="isAllergenSelected$(item) | async" color="primary" [id]="item" (selectionChange)="onAllergenChange($event, item)">-->
                      {{ addonGroup.name }}
                    </mat-chip-option>
                  </ng-container>
                </mat-chip-listbox>
              </ng-template>
            </div>
          </div>
        </div>
      </ng-container>
    </mat-tab>

  </mat-tab-group>


    <!-- Add or Save changes button -->
    <div class="modal-bottom-action">
        <fs-button [text]="(!!(tempData$ | async)?.id ? 'menu_product_modal_add_save_changes' : 'menu_product_modal_add_button') | translate" [style]="'full-width-large-height'"
                   [type]="'raised-primary'" (onPress)="onAddButtonClick()" [disabled]="(isButtonDisabled$() | async) || false"
        ></fs-button>
    </div>
</div>

