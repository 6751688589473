export class CustomUpload {
    
    public imageData: BlobResponse = new BlobResponse();
    public usageName: string = "";
    public supportedFiles: UploadFileType[] = DEFAULT_UPLOAD_FILE_TYPES;
    public fileName: string = ""; // the one the user uploads (as it was stored on user's device)

    constructor() {
    }
}

export enum BlobUsageTypeEnum {
    RESTAURANT_DOCUMENT = "RESTAURANT_DOCUMENT",
    RESTAURANT_PROFILE_IMAGE = "RESTAURANT_PROFILE_IMAGE",
    RESTAURANT_LOGO_IMAGE = "RESTAURANT_LOGO_IMAGE",
    PRODUCT_IMAGE = "PRODUCT_IMAGE",
    USER_PROFILE_IMAGE = "USER_PROFILE_IMAGE",
    NOT_DEFINED = "NOT_DEFINED"
}

export class BlobResponse {
    public id: string = ""; // blob id
    public usageType: BlobUsageTypeEnum = BlobUsageTypeEnum.NOT_DEFINED;
    public contentType: string = "";
    public url: string = "";
}


export class UploadFileState {
    public error: UploadFileError = UploadFileError.NONE;
    public file: File | undefined;
}

export enum UploadFileError {
  NONE = "NONE",
  SIZE_EXCEEDED = "SIZE_EXCEEDED",
  DIMENSIONS_EXCEEDE = "DIMENSIONS_EXCEEDED",
  API_ERROR = "API_ERROR"
}

export interface UploadFileType {    
    extension: UploadFileExtension
    acceptType: UploadFileAccept
}

export enum UploadFileExtension {
    JPEG = ".JPEG",
    JPG = ".JPG",
    PNG = ".PNG",
    WEBP = ".WEBP",
    PDF = ".PDF"
}

export enum UploadFileAccept {
    JPEG = "image/jpeg",
    JPG = "image/jpg",
    PNG = "image/png",
    WEBP = "image/webp",
    PDF = "application/pdf"
}

export const DEFAULT_UPLOAD_FILE_TYPES: UploadFileType[] = [
    {
        extension: UploadFileExtension.JPEG,
        acceptType: UploadFileAccept.JPEG
    }, 
    {
        extension: UploadFileExtension.JPG,
        acceptType: UploadFileAccept.JPG
    }, 
    {
        extension: UploadFileExtension.WEBP,
        acceptType: UploadFileAccept.WEBP
    },
    {
        extension: UploadFileExtension.PNG,
        acceptType: UploadFileAccept.PNG
    }
    
] as UploadFileType[];

export const VERIFICATION_DOCUMENTS_FILE_TYPES =  [
    ...[
        {
            extension: UploadFileExtension.PDF,
            acceptType: UploadFileAccept.PDF
        }
    ], 
    ...DEFAULT_UPLOAD_FILE_TYPES
]