import { Inject, Injectable } from "@angular/core";
import { VerificationStateModel } from "./model/verification-state-model";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class VerificationStateService {

    private state$: BehaviorSubject<VerificationStateModel> = new BehaviorSubject<VerificationStateModel>(new VerificationStateModel());

    constructor(
        @Inject('env') private environment: any
    ) {
        
    }
    
    public getData$(): Observable<VerificationStateModel> {
        return this.state$.asObservable();
    }

    public getData(): VerificationStateModel {
        return this.state$.getValue();
    }

    public isStepCompleted(name: string): boolean {
        return this.getData().isStepCompleted(name);
    }

    public isStepCompletedAll(name: string): boolean {
        return this.getData().isStepCompletedAll(name);
    }

    public isSubLevelCompleted(name: string): boolean {
        return this.getData().isSubLevelCompleted(name);
    }

    // Setters

    public setData(data: VerificationStateModel) {
        this.state$.next(Object.assign(new VerificationStateModel(), data));        
    }

    public setNeedsVerification(val: boolean) {
        const d = this.getData();
        d.setNeedsVerification(val);
        this.setData(d);
    }

    public completeSubLevel(name: string, value: boolean) {
        const d = this.getData();
        d.completeSubLevel(name, value);
        this.setData(d);
    }
}