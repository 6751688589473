export class InvitationListResponse {
    invitations: InvitationResponse[];
    count: number;
}

export class InvitationResponse {
    id: string;
    email: string;
    status: InvitationStatus;
    expiresAt: string;
    createdDate: string;
}

export enum InvitationStatus {
    PENDING = "PENDING",
    ACCEPTED = "ACCEPTED"
}