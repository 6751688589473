import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ModalService} from "libs/shared-ui/src/lib/modal-service/modal-service.service";
import {RestaurantMenuService} from "../../../../../services/restaurant-menu/restaurant-menu.service";
import {BehaviorSubject, map, Observable, of} from "rxjs";
import {LocaleService} from "libs/shared-services/src/lib/locale.service";
import {LocaleTranslatePipe} from "libs/shared-services/src/lib/locale-pipe";
import {FormatCentsToFloatPipe} from "libs/shared-services/src/lib/cents-to-float-pipe";
import { AddonGroupListResponse } from "../../../../../services/restaurant-menu/data-store/model/addon-group-list-response";
import {AddonLocalResponse} from "../../../../../services/restaurant-menu/data-store/model/addon-local-response";
import {AddonGroupResponse} from "libs/shared-models/src/lib/restaurant/addon-group-response";
import { environment } from 'apps/restaurant/src/environments/environment';
import { AllergenEnum } from 'libs/shared-models/src/lib/restaurant/AllergenEnum';

@Component({
  selector: 'addon-item-modal',
  templateUrl: './addon-item-modal.component.html',
  styleUrls: ['./addon-item-modal.component.scss'],
})
export class AddonItemModalComponent implements OnInit {

    // Api data - used to provide groups list in the dropdown (for this current addon)
    public addonsGroupList$: Observable<AddonGroupListResponse> = this.menuService.getAddonsGroupListState$();


    /*
        Local data
     */
    public tempData$: BehaviorSubject<AddonLocalResponse> = new BehaviorSubject<AddonLocalResponse>(new AddonLocalResponse());
    
    public externalAllergenURL = environment.URL_ALLERGEN_EXTERNAL;


    constructor(
        @Inject(MAT_DIALOG_DATA) private data: AddonLocalResponse,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<AddonItemModalComponent>,
        private modalService: ModalService,
        private menuService: RestaurantMenuService,
        private localeService: LocaleService
    ) {
    }

    public ngOnInit() {

        // Assign the data received on Modal creation
        const newAddon = Object.assign(new AddonLocalResponse(), this.getDialogData()); // new instance
        newAddon.allergenList = Object.assign([], this.getDialogData().allergenList); // new instance

        // Assign the price with decimals (for inputs). From API it comes in cents
        if (!!newAddon.priceCents) {
          newAddon.price = +(new FormatCentsToFloatPipe().transform(newAddon.priceCents));
        }

        this.tempData$.next(Object.assign(new AddonLocalResponse(), newAddon));

        // Listen to UI modal backdrop:
        this.dialogRef.backdropClick().subscribe(() => {
            this.onCancel();
        });
    }

    private getData(): AddonLocalResponse {
        return this.tempData$.getValue();
    }

    private setData(val: AddonLocalResponse) {
        this.tempData$.next(val);
    }

    // The data added on Modal creation (injected in the dialog from parent call)
    private getDialogData(): AddonLocalResponse {
      return this.data;
    }

    // Popup cancel
    public onCancel() {
        const newAddon = Object.assign(new AddonLocalResponse(), this.getData());
        // make sure that the initial changes done inside this component don't affect the comparison. So make them as they were initially
        newAddon.price = 0;

        const isSameContent: boolean = JSON.stringify(newAddon) === JSON.stringify(this.getDialogData()); // compare to the initial one before the popup was opened
        if (!isSameContent) {
            // Notify the user that he'll lose the current changes
            this.modalService.openConfirmDialog$().subscribe((response) => {
                if (response) { // true
                    this.dialogRef.close();
                }
            });
        } else {
            this.dialogRef.close();
        }
    }

  // Button add new Group
  public onAddClick() {
    const currData: AddonLocalResponse = this.tempData$.getValue();
    currData.priceCents = +(new FormatCentsToFloatPipe().transformReverse(currData.price)) || 0;

    if (this.getDialogData().isTemporaryCallback) {
      // just return the data to the parent
      this.dialogRef.close({data: currData});

    } else {
      // call the api and update it
      this.menuService.upsertAddonAPI$(currData).subscribe((value) => {
        if (value) {
          // close the popup
          this.dialogRef.close();
        }
      })
    }
  }

  public nameUpdate(event: any) {
      const currentData = this.getData();
      currentData.name = event;
      this.setData(currentData);
  }

  public nameEnUpdate(event: any) {
    const currentData = this.getData();
    currentData.nameEn = event;
    this.setData(currentData);
  }

  // Price
  public priceUpdate(event: any) {
    const currentData = this.getData();

    // Make it input-friendly
    currentData.price = +parseFloat("" + event).toFixed(2) || 0;

    // Store it for the future (api) + the continuous check of the isDisabled button
    currentData.priceCents = +(new FormatCentsToFloatPipe().transformReverse(currentData.price)) || 0;

    this.setData(currentData);
  }

  // Availability
  public onAvailabilityToggleClick(event: any) {
    const currentData = this.getData();
    currentData.available = event.checked;
    this.setData(currentData);
  }


  // Create button status
  public isButtonDisabled$(): Observable<boolean> {
    return this.tempData$.pipe(
      map(data => {
        // came from API
        if (!!data.id) {
          // make sure that the initial changes done inside this component don't affect the comparison. So make them as they were initially
          const newAddonGroup = Object.assign(new AddonLocalResponse(), data);
          newAddonGroup.price = 0;

          return JSON.stringify(newAddonGroup) === JSON.stringify(this.getDialogData()); // compare to the initial one before the popup was opened
        } else {
          // it was a temp one
          return !data.name || (!data.addonGroupId && !data.isTemporaryCallback);
        }
      })
    );
  }

  // Delete addon
  public onDeleteClick() {
    const config = this.modalService.getDefaultDialogConfig();
    config.width = "500px";
    config.data = {
      title: new LocaleTranslatePipe(this.localeService).transform("menu_addon_confirm_modal_delete_title"),
      midContent: new LocaleTranslatePipe(this.localeService).transform("menu_addon_confirm_modal_delete_body"),
      leftButtonContent: new LocaleTranslatePipe(this.localeService).transform("menu_addon_confirm_modal_delete_left_action"),
      rightButtonContent: new LocaleTranslatePipe(this.localeService).transform("menu_addon_confirm_modal_delete_right_action"),
      preselectedButton: "left"
    }

    this.modalService.openConfirmDialog$(config).subscribe((response) => {
      if (response) { // YES pressed in the confirm modal
        this.menuService.deleteAddonAPI$(this.getData()).subscribe((value) => {
          if (value) {
            // close the popup
            this.dialogRef.close();
          }
        })
      }
    });
  }

  // Allergens
  public getAllAllergenList(): string[] {
    return Object.keys(AllergenEnum);
  }

  public isAllergenSelected$(allergen: string): Observable<boolean> {
    if (!allergen) {
      return of(false);
    }
    return of(!!this.getData().allergenList.find((a) => a === allergen));
  }

  // Allergen click
  public onAllergenChange(event: any, selectedItem: string) {
    const letter = selectedItem as AllergenEnum;
    const enabled = event.selected;
    const currentData = this.getData();

    if (enabled) {
      // make sure we don't duplicate - so do a check before pushing it
      if (!currentData.allergenList.find((a) => a === letter)) {
        currentData.allergenList.push(letter);
      }
    } else {
      currentData.allergenList = currentData.allergenList.filter((item) => item !== letter)
    }
    currentData.allergenList.sort((a,b) => a > b ? 1 : b > a ? -1 : 0);
    this.setData(currentData);
  }

  // Add-on Group
  public getGroupById$(groupId: any): Observable<AddonGroupResponse | null> {
    if (!groupId) {
      return of(null);
    }
    return this.addonsGroupList$.pipe(
      map(list => {
        let group = list.addonGroupList.find(c => c.id === groupId);
        if (!!group) {
          return group;
        }
        return null;
      })
    );
  }

  // Selected Group
  public onSelectDropdownGroup(event: any) {
    const currentData = this.getData();
    currentData.addonGroupId = event.id; // TODO: multiple menus in the future.
    this.setData(currentData);
  }

}
