import { Component, OnDestroy, OnInit } from '@angular/core';
import { RestaurantFinanceService } from './service/restaurant-finance.service';
import { RestaurantInfoService } from '../../../services/restaurant-info/restaurant-info.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { RestaurantResponse } from 'libs/shared-models/src/lib/restaurant/restaurant-response';
import { StripeAccountSessionResponse } from './model/stripe-account-session-response';
import { ConnectElementTagName, loadConnectAndInitialize } from '@stripe/connect-js';
import { environment } from 'apps/restaurant/src/environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Constants } from '../../../utils/constants';

@UntilDestroy()
@Component({
  selector: 'web-foodis-section-finance',
  templateUrl: './section-finance.component.html',
  styleUrls: ['./section-finance.component.scss'],
})
export class SectionFinanceComponent implements OnInit, OnDestroy {

  public restaurantData$: Observable<RestaurantResponse> = this.restaurantInfoService.getState$();
  public sessionData$: Observable<StripeAccountSessionResponse> = this.restaurantFinanceService.getSessionData$();

  private HTML_STRIPE_CONTAINER = "stripe-session-load-container";
  public isLoadingCreateStripeID$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private restaurantFinanceService: RestaurantFinanceService,
    private restaurantInfoService: RestaurantInfoService
  ) {  
  } 
  public ngOnInit() {
    this.restaurantData$.subscribe((resData) => {
      if (resData.stripeAccountId) {
        if (this.restaurantFinanceService.getSessionData().clientSecret) {
          this.loadStripeSessionUIEmbedded();
        } else {
          this.restaurantFinanceService.requestStripeAccountSession$().subscribe((success) => {
            this.loadStripeSessionUIEmbedded();
          });
        }
      }
    })
  }

  public onPressCreate() {
    this.isLoadingCreateStripeID$.next(true);
    this.restaurantFinanceService.requestStripeAccountCreation$().subscribe((success) => {
      // re-fetch restaurant info data (so it gets the new assigned stripeAccountId)
      this.isLoadingCreateStripeID$.next(false);
      //this.restaurantInfoService.fetchRestaurantAPI();
    })
  }

  private loadStripeSessionUIEmbedded() {
    const stripeAccountSessionClientSecret = async () => {
      return this.restaurantFinanceService.getSessionData().clientSecret;
    };

    const stripeConnectInstance = loadConnectAndInitialize({
      publishableKey: environment.STRIPE_PUBLISHABLE_KEY,
      fetchClientSecret: stripeAccountSessionClientSecret
    });
    // const paymentComponent = stripeConnectInstance.create("payments");
    const paymentComponent = stripeConnectInstance.create(Constants.STRIPE_ONBOARDING_COMPONENT as ConnectElementTagName);
    const container = document.getElementById(this.HTML_STRIPE_CONTAINER);
    container?.appendChild(paymentComponent);
  }
  public ngOnDestroy(): void {
      this.restaurantFinanceService.resetSessionData();
  }
}
