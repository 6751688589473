import {Component, OnInit} from '@angular/core';
import {Product} from "libs/shared-models/src/lib/product-type";
import {CurrentProductService} from "../../../../../../../libs/shared-services/src/lib/current-product.service";
import {UntilDestroy} from "@ngneat/until-destroy";
import {Router} from "@angular/router";

@UntilDestroy()
@Component({
  selector: 'restaurant-logo',
  templateUrl: './restaurant-logo.component.html',
  styleUrls: ['./restaurant-logo.component.scss'],
})
export class RestaurantLogoComponent implements OnInit {

    private product: Product = Product.RESTAURANT;

    constructor(
        private currentProductService: CurrentProductService,
        private router: Router
    ) {
    }

    public ngOnInit() {
    }

    public getProductType(): Product {
        return this.product;
    }

    public pressed() {
        this.router.navigateByUrl("dashboard");
    }
}
