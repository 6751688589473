import { AllergenEnum } from "libs/shared-models/src/lib/restaurant/AllergenEnum";

export class AddonRequest {
    id?: string = ""; // the API doesn't accept empty string ID. So I had to create a Request model as well - which is actually sent to the API
    name: string = "";
    nameEn: string = "";
    priceCents: number = 0;
    allergenList: AllergenEnum[] = [];
    available: boolean = false;
}
