import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Language, LocaleService } from 'libs/shared-services/src/lib/locale.service';
import {ModalService} from "libs/shared-ui/src/lib/modal-service/modal-service.service";
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'info-structure-modal',
  templateUrl: './info-structure-modal.component.html',
  styleUrls: ['./info-structure-modal.component.scss'],
})
export class InfoStructureModal implements OnInit {

  public currentTab$: BehaviorSubject<string> = new BehaviorSubject<string>('products');
  public currentTabIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public isGermanLanguage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<InfoStructureModal>,
        private localeService: LocaleService
    ) {
    }

    public ngOnInit() {

      this.currentTab$.next(this.data.selectedTab);
      if (this.data.selectedTab === 'products') {
        this.currentTabIndex$.next(0);
      } else {
        this.currentTabIndex$.next(1); // addons
      }

      // Listen to language changes
      this.localeService.getLanguage$().subscribe((lang: Language) => {      
        this.isGermanLanguage$.next(lang.iso2 === "de");
      })

      // Listen to UI modal backdrop:
      this.dialogRef.backdropClick().subscribe(() => {
          this.dialogRef.close();
      });
    }

    // Popup cancel
    public onCancel() {
      this.dialogRef.close();
    }

    public onTabChange(event: MatTabChangeEvent) {
      this.currentTab$.next(event.tab.ariaLabel)
    }
}
