<div class="r-info-availability">
    <div class="r-info-availability-scrollable">

        <ng-container *ngIf="(verificationStatus$ | async) === RestaurantVerificationEnum.VERIFICATION_REQUESTED 
                    || (verificationStatus$ | async) === RestaurantVerificationEnum.VERIFICATION_DECLINED">
            <div class="state-submitted">
                <div class="state-submitted-box">
                    <div class="state-box-title">
                        {{ "verification_status_box_title" | translate }}
                    </div>
                    <div class="state-box-container">
                        <div class="state-box-image" [ngClass]="
                        {
                            'orange' : (verificationStatus$ | async) === RestaurantVerificationEnum.VERIFICATION_REQUESTED,
                            'red' : (verificationStatus$ | async) === RestaurantVerificationEnum.VERIFICATION_DECLINED
                        }">
                            <mat-icon>lens</mat-icon>
                        </div>
                        <div class="state-box-status">
                            <ng-container *ngIf="(verificationStatus$ | async) === RestaurantVerificationEnum.VERIFICATION_REQUESTED">
                                {{ "verification_status_box_requested" | translate }}
                            </ng-container>
                            <ng-container *ngIf="(verificationStatus$ | async) === RestaurantVerificationEnum.VERIFICATION_DECLINED">
                                {{ "verification_status_box_declined" | translate }}
                            </ng-container>
                        </div>
                    </div>        
                </div>
                <div class="state-submitted-details">
                    <img class="src-icon" [src]="'apps/restaurant/src/assets/card-verify-documents.svg'"/>
                    <ng-container *ngIf="(verificationStatus$ | async) === RestaurantVerificationEnum.VERIFICATION_REQUESTED">
                        {{ "verification_status_box_requested_description" | translate }}
                    </ng-container>
                    <ng-container *ngIf="(verificationStatus$ | async) === RestaurantVerificationEnum.VERIFICATION_DECLINED">
                        <div class="flex flex-col">
                            {{ "verification_status_box_declined_description" | translate }}
                            <ng-container *ngIf="!!(verificationMessage$ | async)">
                                <div class="verification-message">
                                    {{ verificationMessage$ | async }}
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>        
                </div>
            </div>
        </ng-container>

        <div class="buttons-container">
            <ng-container *ngFor="let item of (verificationData$ | async)?.getStepsList()">
                <div class="flex mr-10 mb-14">
                    <a [routerLink]="item.urlPath" >
                        <card-button 
                            [icon]="item.icon" 
                            [key]="item.lokaKey" 
                            [numberLabel]="item.orderNumber"
                            [completed]="(verificationData$ | async)?.isStepCompleted(item.name) || false">
                        </card-button>
                        <div class="requirements-container" [ngClass]="{'main-completed': (verificationData$ | async)?.isStepCompleted(item.name) || false}">
                            <ng-container *ngFor="let req of item.requirements">
                                <div class="requirement" [ngClass]="{'req-completed': req.completed}">
                                    <mat-icon class="req-icon">
                                        {{ (verificationData$ | async)?.isSubLevelCompleted(req.name) || false ?'check_box' : 'check_box_outline_blank' }}</mat-icon>
                                        {{ req.name | translate }}{{ req.type !== VerificationPriority.MANDATORY ? "*" : ''}}
                                </div>
                            </ng-container>
                        </div>
                    </a>
                </div>      
            </ng-container>
        </div>

        <div class="mandatory-text"> {{ "optional_steps" | translate }}</div>
    </div>
</div>


            