import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ModalService} from "libs/shared-ui/src/lib/modal-service/modal-service.service";
import {RestaurantMenuService} from "../../../../../services/restaurant-menu/restaurant-menu.service";
import {BehaviorSubject, map, Observable} from "rxjs";
import {LocaleService} from "libs/shared-services/src/lib/locale.service";
import {AddonGroupResponse} from "libs/shared-models/src/lib/restaurant/addon-group-response";
import {AddonResponse} from "libs/shared-models/src/lib/restaurant/addon-response";
import {LocaleTranslatePipe} from "libs/shared-services/src/lib/locale-pipe";
import {AddonLocalResponse} from "../../../../../services/restaurant-menu/data-store/model/addon-local-response";
import {AddonItemModalComponent} from "../addon-item-modal/addon-item-modal.component";

@Component({
  selector: 'addon-group-modal',
  templateUrl: './addon-group-modal.component.html',
  styleUrls: ['./addon-group-modal.component.scss'],
})
export class AddonGroupModalComponent implements OnInit {


    /*
        Local data
     */
    public tempData$: BehaviorSubject<AddonGroupResponse> = new BehaviorSubject<AddonGroupResponse>(new AddonGroupResponse());

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: AddonGroupResponse,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<AddonGroupModalComponent>,
        private modalService: ModalService,
        private menuService: RestaurantMenuService,
        private localeService: LocaleService
    ) {
    }

    public ngOnInit() {

        // Assign the data received on Modal creation
        const newAddonGroup = Object.assign(new AddonGroupResponse(), this.data); // new instance
        newAddonGroup.addonList = Object.assign([], this.data.addonList); // new instance
        this.tempData$.next(Object.assign(new AddonGroupResponse(), newAddonGroup));

        // Listen to UI modal backdrop:
        this.dialogRef.backdropClick().subscribe(() => {
            this.onCancel();
        });
    }
    private getData(): AddonGroupResponse {
        return this.tempData$.getValue();
    }

    private setData(val: AddonGroupResponse) {
        this.tempData$.next(val);
    }

    // Popup cancel
    public onCancel() {
        const newAddonGroup = Object.assign(new AddonGroupResponse(), this.getData());
        const isSameContent: boolean = JSON.stringify(newAddonGroup) === JSON.stringify(this.data); // compare to the initial one before the popup was opened
        if (!isSameContent) {
            // Notify the user that he'll lose the current changes
            this.modalService.openConfirmDialog$().subscribe((response) => {
                if (response) { // true
                    this.dialogRef.close();
                }
            });
        } else {
            this.dialogRef.close();
        }
    }

  // Button add new Group
  public onAddGroupClick() {
    const currData: AddonGroupResponse = this.tempData$.getValue();
    this.menuService.upsertAddonGroupAPI$(currData).subscribe((value) => {
      if (value) {
        // close the popup
        this.dialogRef.close();
      }
    })
  }

  public nameUpdate(event: any) {
      const currentData = this.getData();
      currentData.name = event;
      this.setData(currentData);
  }

  public nameEnUpdate(event: any) {
    const currentData = this.getData();
    currentData.nameEn = event;
    this.setData(currentData);
  }

  public minSelectionUpdate(event: any) {
    const currentData = this.getData();
    currentData.minSelection = +event;
    this.setData(currentData);
  }

  public maxSelectionUpdate(event: any) {
    const currentData = this.getData();
    currentData.maxSelection = +event;
    this.setData(currentData);
  }

  public isButtonDisabled$(): Observable<boolean> {
    return this.tempData$.pipe(
      map(data => {
        // came from API
        if (!!data.id) {
          // make sure that the initial changes done inside this componnent don't affect the comparison. So make them as they were initially
          const newAddonGroup = Object.assign(new AddonGroupResponse(), data);
          return JSON.stringify(newAddonGroup) === JSON.stringify(this.data); // compare to the initial one before the popup was opened
        } else {
          // it was a temp one
          return !data.name; //TODO: maybe add more conditions here
        }
      })
    );
  }

  // Remove add-on group
  public onDeleteClick() {
    const config = this.modalService.getDefaultDialogConfig();
    config.width = "500px";
    config.data = {
      title: new LocaleTranslatePipe(this.localeService).transform("menu_addon_group_modal_delete_confirm_title"),
      midContent: new LocaleTranslatePipe(this.localeService).transform("menu_addon_group_modal_delete_confirm_body"),
      leftButtonContent: new LocaleTranslatePipe(this.localeService).transform("generic_modal_default_left_action"),
      rightButtonContent: new LocaleTranslatePipe(this.localeService).transform("generic_modal_default_right_action"),
      preselectedButton: "left"
    }

    this.modalService.openConfirmDialog$(config).subscribe((response) => {
      if (response) { // YES pressed in the confirm modal
        this.menuService.deleteAddonGroupAPI$(this.getData()).subscribe((value) => {
          if (value) {
            // close the popup
            this.dialogRef.close();
          }
        })
      }
    });
  }

  public addCreateAddonPress() {
     const group = this.getData();
     this.addAddonPress(group);
  }

  // duplicated code from section-menu:  Maybe it can be refactored in a common place
  public addAddonPress(addonGroup: AddonGroupResponse | null = null) {
    const tempNewAddon = new AddonLocalResponse();
    tempNewAddon.available = true; // pre-select it in the modal
    tempNewAddon.isTemporaryCallback = true; // in order to wait for the callback data and not send API data yet
    if (addonGroup) {
      tempNewAddon.addonGroupId = addonGroup.id;
    }
    this.openAddonModal(tempNewAddon);
  }

  private openAddonModal(addonGroup: AddonResponse) {
    const modalConfig = this.modalService.getDefaultDialogConfig();
    modalConfig.data = addonGroup;
    modalConfig.width = "700px";
    modalConfig.disableClose = true;
    this.modalService.openCustomDialog$(AddonItemModalComponent, modalConfig).subscribe((callbackResponse) => {
      const data = Object.assign(new AddonGroupResponse(), this.getData());
      data.addonList.push(callbackResponse.data);
      this.setData(data);
    })
  }

  // Cancel (x) icon tap on the add-on chip
  public onRemoveAddon(addon: AddonResponse) {
    const config = this.modalService.getDefaultDialogConfig();
    config.width = "500px";
    config.data = {
      title: new LocaleTranslatePipe(this.localeService).transform("menu_addon_confirm_modal_delete_title"),
      midContent: new LocaleTranslatePipe(this.localeService).transform("menu_addon_confirm_modal_delete_body"),
      leftButtonContent: new LocaleTranslatePipe(this.localeService).transform("menu_addon_confirm_modal_delete_left_action"),
      rightButtonContent: new LocaleTranslatePipe(this.localeService).transform("menu_addon_confirm_modal_delete_right_action"),
      preselectedButton: "left"
    }

    // Notify the user that he'll lose the current changes
    this.modalService.openConfirmDialog$(config).subscribe((response) => {
      if (response) { // true
        const data = Object.assign(new AddonGroupResponse(), this.getData());
        const list = data.addonList.filter((a) => a.name !== addon.name);
        data.addonList = [...list];
        this.setData(data);
      }
    });
  }
}
