import {ProductSlimInfo} from "libs/shared-models/src/lib/restaurant/product-slim-info";
import { AllergenEnum } from "libs/shared-models/src/lib/restaurant/AllergenEnum";

export class ProductResponse extends ProductSlimInfo {

    public description: string = "";
    public descriptionEn: string = "";
    public allergenList: AllergenEnum[] = [];
    public override addonGroupIds: string[] = [];
    public menuCategoryId: string = "";
    public menuIds: string[] = [];
    public imageBlobId?: string;

    // Used locally (without cents) due to easier input work
    public price: number = 0;
    public deliveryPrice: number = 0;

    constructor() {
        super();
    }
}

