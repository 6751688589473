export class ProductSlimInfo {
    public id: string = "";
    public name: string = "";
    public nameEn: string = "";
    public priceCents: number = 0;
    public deliveryPriceCents: number = 0;
    public available: boolean = false;
    public imageUrl: string = "";
    public addonGroupIds: string[] = [];

    constructor() {
    }
}
