import { Component, OnInit } from '@angular/core';
import { SelfDataService } from '../../../services/self/self.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserSelfResponse } from '../../../models/user-response';
import { RestaurantAccounts } from './service/restaurant-accounts.service';
import { InvitationListResponse } from './models/invitation-response';
import { RestaurantTeamUsersResponse } from './models/user-response';

@Component({
  selector: 'web-foodis-section-accounts',
  templateUrl: './section-accounts.component.html',
  styleUrls: ['./section-accounts.component.scss'],
})
export class SectionAccountsComponent implements OnInit {

  // Self (email) data
  public selfData$: Observable<UserSelfResponse> = this.selfDataService.getSelfData$();

  // Invitations list
  public invitations$: Observable<InvitationListResponse> = this.accountsService.getInvitations$();

  public showInvitationInput$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private kitchenInputInviteEmail: string = "";


  // Users list
  public usersList$: Observable<RestaurantTeamUsersResponse> = this.accountsService.getUsersList$();

  constructor(
    private selfDataService: SelfDataService,
    private accountsService: RestaurantAccounts
  ) {

  }

  public ngOnInit() {
    this.accountsService.fetchInvitations();
    this.accountsService.fetchUsersListApi();
  }

  public addKitchenAccount() {
    this.showInvitationInput$.next(true);
  }

  public sendKitchenEmailInvite() {

    if (this.kitchenInputInviteEmail) {
      // do the api call      
      this.accountsService.requestInvitationApi$(this.kitchenInputInviteEmail).subscribe((result) => {
        if (result) {
          // hide the input
          this.showInvitationInput$.next(false);
        }        
      })
    }
    
  }
  
  public onEmailKitchenInputUpdate(value: any) {
    this.kitchenInputInviteEmail = value;
  }

  public removeUserPress(userId: string) {
    this.accountsService.deleteUser$(userId).subscribe((result) => {
      
    })
  }

}
