import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ModalService} from "libs/shared-ui/src/lib/modal-service/modal-service.service";
import {RestaurantMenuService} from "../../../services/restaurant-menu/restaurant-menu.service";
import {BehaviorSubject, catchError, map, Observable, of, throwError} from "rxjs";
import {LocaleService} from "libs/shared-services/src/lib/locale.service";
import { BlobResponse, BlobUsageTypeEnum, CustomUpload } from 'apps/restaurant/src/app/models/custom-upload';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { RestaurantInfoService } from '../../../services/restaurant-info/restaurant-info.service';

@Component({
  selector: 'upload-image-modal',
  templateUrl: './upload-image-modal.component.html',
  styleUrls: ['./upload-image-modal.component.scss'],
})
export class UploadImageModal implements OnInit, AfterViewInit {

    @ViewChild('uploadInputElem') uploadInputElem: ElementRef | undefined;

    /*
        Local data
     */
    public tempData$: BehaviorSubject<CustomUpload> = new BehaviorSubject<CustomUpload>(new CustomUpload());

    public uploadFileState$: BehaviorSubject<UploadFileState> = new BehaviorSubject<UploadFileState>(new UploadFileState());

    public BlobUsageTypeEnum: typeof BlobUsageTypeEnum = BlobUsageTypeEnum;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: CustomUpload,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<UploadImageModal>,
        private modalService: ModalService,
        private menuService: RestaurantMenuService,
        private localeService: LocaleService,
        private restaurantInfoService: RestaurantInfoService,
        private http: HttpClient
    ) {
    }

    public ngOnInit() {

        // Assign the data received on Modal creation
        const newUpload = Object.assign(new CustomUpload(), this.getDialogData()); // new instance
        
        // newAddon.allergenList = Object.assign([], this.getDialogData().allergenList); // new instance


        this.tempData$.next(Object.assign(new CustomUpload(), newUpload));

        // Listen to UI modal backdrop:
        this.dialogRef.backdropClick().subscribe(() => {
            this.onCancel();
        });
    }

    public ngAfterViewInit(): void {
      // Auto-open the choose file flow
      if (!this.getData().imageData.id && !this.getData().imageData.url) {
        this.onUploadPress();
      }
    }

    private getData(): CustomUpload {
        return this.tempData$.getValue();
    }

    private setData(val: CustomUpload) {
        this.tempData$.next(val);
    }

    // The data added on Modal creation (injected in the dialog from parent call)
    private getDialogData(): CustomUpload {
      return this.data;
    }

    // Popup cancel
    public onCancel() {
        const newAddon = Object.assign(new CustomUpload(), this.getData());

        // const isSameContent: boolean = JSON.stringify(newAddon) === JSON.stringify(this.getDialogData()); // compare to the initial one before the popup was opened
        // if (!isSameContent) {
        //     // Notify the user that he'll lose the current changes
        //     this.modalService.openConfirmDialog$().subscribe((response) => {
        //         if (response) { // true
        //             this.dialogRef.close();
        //         }
        //     });
        // } else {
        //     this.dialogRef.close();
        // }

        this.dialogRef.close();
    }

  // Create button status
  public isButtonDisabled$(): Observable<boolean> {
    return this.tempData$.pipe(
      map(data => {
        if (this.getUploadState().uploadSuccessful) {
          return false;
        } else {
          if (!!data.imageData.id) {
            // make sure that the initial changes done inside this component don't affect the comparison. So make them as they were initially
            const newCustom = Object.assign(new CustomUpload(), data);
            return JSON.stringify(newCustom) === JSON.stringify(this.getDialogData()); // compare to the initial one before the popup was opened
          }
          return true;
        }
      })
    );
  }

  // Accepted file types
  public contentTypeFiles$(): Observable<string> {
    return this.tempData$.pipe(
      map(data => {
        let files = "";
        data.supportedFiles.forEach((item, index, arr) => {
          files = files + item.acceptType + (index < arr.length - 1 ? "," : "");
        })
        return files;
      })
    );
  }


  public onUploadPress() {
    this.uploadInputElem?.nativeElement.click();
  }

  public onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {      
      // Check file size (MB)
      if (file.size > 10000000) {
        let state: UploadFileState = this.getUploadState();
        state.error = UploadFileError.SIZE_EXCEEDED;
        this.setUploadState(state);
        return;
      }

      // Trim the name
      // if (file.name.length > 100) {
      //   const newFile = this.createFileWithNewName(file);
      //   file = newFile; // replace it
      // }

      // Check file dimensions (width / height)
      // TODO in the future


      // Start auto-uploading

      // clear error state
      const errState: UploadFileState = this.getUploadState();
      errState.error = UploadFileError.NONE;
      this.setUploadState(errState);


      // update with the file
      const state: UploadFileState = this.getUploadState();
      state.file = file;
      state.uploadInProgres = true;
      state.uploadSuccessful = false;
      this.setUploadState(state);

      const restaurantId = this.restaurantInfoService.getState().id;

      // api call
      this.menuService.uploadFileAPI$(this.getData(), state, restaurantId).subscribe((res: BlobResponse | boolean) => {
        if (res) {
          const custom = Object.assign(new CustomUpload(), this.getData());
          custom.imageData = Object.assign(new BlobResponse(), res);
          custom.fileName = file.name;
          this.setData(custom);

          // clear error state
          const state: UploadFileState = this.getUploadState();
          state.error = UploadFileError.NONE;
          state.uploadInProgres = false;
          state.uploadSuccessful = true;
          this.setUploadState(state);
        } else {
          // error
          const state: UploadFileState = this.getUploadState();
          state.error = UploadFileError.API_ERROR;
          state.uploadInProgres = false;
          state.uploadSuccessful = false;
          this.setUploadState(state);
        }
      });
    }
  }

  private getUploadState(): UploadFileState {
    return this.uploadFileState$.getValue();
  }

  private setUploadState(val: UploadFileState) {
      this.uploadFileState$.next(val);
  }

  private trimFileName(filename: string, maxLength: number = 100): string {
    const extension = filename.slice(filename.lastIndexOf('.'));
    const maxNameLength = maxLength - extension.length;
    
    if (filename.length > maxLength) {
      const namePart = filename.slice(0, filename.lastIndexOf('.')).substring(0, maxNameLength);
      return `${namePart}${extension}`;
    }
    
    return filename;
  }

  private createFileWithNewName(originalFile: File, maxLength: number = 100): File {
    const trimmedName = this.trimFileName(originalFile.name, maxLength);
    // Create a new file with the trimmed name and same content
    const newFile = new File([originalFile], trimmedName, { type: originalFile.type, lastModified: originalFile.lastModified });
    return newFile;
  }

  
  // Submit changes to parent
  public onSaveChanges() {

    switch (this.getData().imageData.usageType) {

      case BlobUsageTypeEnum.RESTAURANT_PROFILE_IMAGE: {
        this.restaurantInfoService.updateProfileImage$(this.getData()).subscribe((result) => {
          if (result) {
              this.dialogRef.close();
          }
        })
        break;
      }

      case BlobUsageTypeEnum.RESTAURANT_LOGO_IMAGE: {
        this.restaurantInfoService.updateLogoImage$(this.getData()).subscribe((result) => {
          if (result) {
              this.dialogRef.close();
          }
        })
        break;
      }

      case BlobUsageTypeEnum.RESTAURANT_DOCUMENT: {
        this.restaurantInfoService.updateVerificationDocuments$(this.getData()).subscribe((result) => {
          if (result) {
              this.dialogRef.close();
          }
        })
        break;
      }
      default: {
        this.dialogRef.close({data: this.getData()});
        break;
      }
    }
  }
}

export class UploadFileState {
    public error: UploadFileError = UploadFileError.NONE;
    public file: File | undefined;
    public uploadInProgres: boolean = false;
    public uploadSuccessful: boolean = false;
}

export enum UploadFileError {
  NONE = "NONE",
  SIZE_EXCEEDED = "SIZE_EXCEEDED",
  DIMENSIONS_EXCEEDE = "DIMENSIONS_EXCEEDED",
  API_ERROR = "API_ERROR"
}
