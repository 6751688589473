import {animate, style, transition, trigger} from "@angular/animations";

export const expandCategoryAnimations = [
    trigger(
        'inOutBoxAnimation',
        [
            transition(
                ':enter',
                [
                    style({ transform: 'translateY(-10%)', opacity: 0 }),
                    animate('0.15s ease-out',
                        style({ transform: 'translateY(0)', opacity: 1 }))
                ]
            ),
            transition(
                ':leave',
                [
                    style({ transform: 'translateY(0)', opacity: 1 }),
                    animate('0.15s ease-in',
                        style({ transform: 'translateY(-10%)', opacity: 0 }))
                ]
            )
        ]
    )
]
