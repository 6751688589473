<main-layout [selected]="'DASHBOARD'">
    <content>
        <div class="dashboard-main-container">

            <!-- Unverified restaurant -->
            <ng-container *ngIf="verificationNeeded$ | async;else verifiedDashboard">
                <unverified-dashboard></unverified-dashboard>
            </ng-container>

            <!-- Verified restaurant-->
            <ng-template #verifiedDashboard>
                Verified state
            </ng-template>
        </div>
    </content>
</main-layout>

