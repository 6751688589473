<div class="modal-top-bar">
    <div class="modal-top-left-action"></div>

    <!-- Title -->
    <div class="modal-top-title">
       {{ (!!(tempData$ | async)?.id || !!(tempData$ | async)?.addonGroupId && !!(tempData$ | async)?.id ? 'menu_addon_modal_title_edit' : 'menu_addon_modal_title') | translate }}
    </div>

    <!-- Cancel button -->
    <div class="modal-top-right-action" >
        <div class="action-item" (click)="onCancel()">
            {{ 'menu_product_modal_cancel' | translate }}
        </div>
    </div>
</div>

<div class="modal-content">

    <div class="modal-top-inputs">

      <!-- Left side -->
      <div class="addon-input-container-left">
        <!-- Name title -->
        <div class="subtitle-text">
          {{ 'menu_addon_modal_label_name' | translate }}
        </div>

        <!-- Deutsch -->
        <div class="names-column-container">
          <div class="names-column-1-container">
            <!-- Icon -->
            <div class="icon-language-container">
              <img class="icon-language" [src]="'apps/restaurant/src/assets/flag-austria.svg'"/>
            </div>
            <!-- Input Name (Deutsch) -->
            <fs-input [label]="'menu_product_modal_name_de' | translate" [style]="'full-width'" class="r-info-public-name-input mr-6"
                      [inputValue]="(tempData$ | async)?.name || ''" (valueUpdate)="nameUpdate($event)">
            </fs-input>
          </div>
          <div class="names-column-2-container">
            <!-- Icon -->
            <div class="icon-language-container">
              <img class="icon-language" [src]="'apps/restaurant/src/assets/globe-green-round-v2.svg'"/>
            </div>
            <!-- Input Name (English) -->
            <fs-input [label]="'menu_product_modal_name_en' | translate" [style]="'full-width'" class="r-info-public-name-input mr-6"
                      [inputValue]="(tempData$ | async)?.nameEn || ''"  (valueUpdate)="nameEnUpdate($event)">
            </fs-input>
          </div>
        </div>
      </div>


      <!-- Right side -->
      <div class="addon-input-container-right">

        <!-- Selection title -->
        <div class="subtitle-text">
          {{ 'menu_addon_modal_label_price' | translate }}
        </div>

        <div class="names-column-container">

          <!-- Price-->
          <div class="names-column-1-container">
            <div class="icon-price-container">
              <img class="icon-price" [src]="'apps/restaurant/src/assets/euro-sign-round.svg'"/>
            </div>
            <fs-input [label]="'menu_addon_modal_price' | translate" [style]="'full-width'" class="r-product-price-input" [type]="'number'"
                      [inputValue]="'' + ((tempData$ | async)?.id ? (tempData$ | async)?.price : (tempData$ | async)?.price || '')"
                      (valueUpdate)="priceUpdate($event)">
            </fs-input>

          </div>

          <!-- Availability -->
          <div class="names-column-2-container">
            <div class="addon-availability-container">
              <div class="availability-text">
                {{ 'menu_product_modal_availability_subtitle' | translate }}
              </div>
              <div class="status-toggle-switch">
                <mat-slide-toggle [color]="'primary'" [disableRipple]="false" [checked]="(tempData$ | async)?.available || false"
                                  (change)="onAvailabilityToggleClick($event)"></mat-slide-toggle>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- Show the dropdown group container and the delete button only if it's not callback temporary type of modal -->
    <ng-container *ngIf="!((tempData$ | async)?.isTemporaryCallback)">

      <div class="addon-dropdown-delete-container">
        <div class="addon-input-container-left no-mr">
          <!-- Addon Group dropdown -->
          <div class="addon-group-container">
            <div class="addon-group-dropdown-text">
              {{ 'menu_addon_modal_addon_group_title' | translate }}
            </div>
            <fs-menu-advanced
              [itemsList]='(addonsGroupList$ | async)?.addonGroupList || []'
              [selectedItem]="(getGroupById$((tempData$ | async)?.addonGroupId) | async)"
              [preselectPlaceholder]="'menu_addon_modal_addon_group_pick' | translate"
              (onSelect)="onSelectDropdownGroup($event)"
              [disabled]="!!(getGroupById$((tempData$ | async)?.addonGroupId) | async) && !!(tempData$ | async)?.id">
            </fs-menu-advanced>
          </div>
        </div>


        <div class="addon-input-container-right">
          <!-- Delete Add-on -->
          <ng-container *ngIf="!!(tempData$ | async)?.id">
            <!-- Show only if it has an ID => meaning it was stored on API before. Otherwise, when creating a new Addon, we shouldn't see the Delete option -->
            <div class="addon-delete-container">
              <div class="delete-action-area" (mousedown)="onDeleteClick()">
                <div class="flex mr-1">
                  <img [src]="'apps/restaurant/src/assets/delete-bin-orange.svg'"/>
                </div>
                <div class="addon-delete-text">
                  {{ 'menu_addon_modal_addon_delete' | translate }}
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

    </ng-container>

    <!-- Allergens -->
    <div class="addon-alergens-container">
      <div class="addon-availability-allergen">
        {{ 'menu_product_modal_allergen_subtitle' | translate }} 
        <!-- Open external link -->
        <a [href]="externalAllergenURL" target="_blank" class="external-link-container">
          <img [src]="'apps/restaurant/src/assets/external-link-file.svg'"/>
        </a>
      </div>
      <div class="flex">
        <mat-chip-listbox aria-label="Availability selection" [multiple]="true">
          <ng-container *ngFor="let item of getAllAllergenList(); index as i ">
            <mat-chip-option [selected]="isAllergenSelected$(item) | async" color="primary" [id]="item" (selectionChange)="onAllergenChange($event, item)"
                #tooltip="matTooltip"
                [matTooltip]="item + ': ' + (('menu_allergen_name_' + item.toLocaleLowerCase()) | translate)"
                [matTooltipPosition]="'above'"
                [matTooltipClass]="'custom-selection-tooltip-small'"
                (mousedown)="tooltip.toggle()"
            >
              {{ item }}
            </mat-chip-option>
          </ng-container>
        </mat-chip-listbox>
      </div>
    </div>

    <!-- Add or Save changes button -->
    <div class="modal-bottom-action">
        <fs-button [text]="(!!(tempData$ | async)?.id  ? 'menu_addon_modal_create_button_save' : 'menu_addon_modal_create_button_add') | translate" [style]="'full-width-large-height'"
                   [type]="'raised-primary'" (onPress)="onAddClick()" [disabled]="(isButtonDisabled$() | async) || false">
        ></fs-button>
    </div>
</div>

