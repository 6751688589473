
import {AddonResponse} from "libs/shared-models/src/lib/restaurant/addon-response";

export class AddonLocalResponse extends AddonResponse {

    // Used locally (without cents) due to easier input work
    price: number = 0;

    // Used locally - assigned category when adding / editing an addon
    addonGroupId: string = ""

    // Is temporary created (triggered from Group Addon) - will not update API, but respond back as a modal callback
    isTemporaryCallback: boolean = false;
}
