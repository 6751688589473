import {Component, OnInit} from '@angular/core';
import { RestaurantVerificationEnum } from 'libs/shared-models/src/lib/restaurant/restaurant-response';
import { VerificationPriority, VerificationStateModel } from 'apps/restaurant/src/app/services/verification-status/data-store/model/verification-state-model';
import { VerificationService } from 'apps/restaurant/src/app/services/verification-status/verification.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'unverified-dashboard',
  templateUrl: './unverified-dashboard.component.html',
  styleUrls: ['./unverified-dashboard.component.scss'],
})
export class UnverifiedDashboardComponent implements OnInit {

    public verificationData$: Observable<VerificationStateModel> = this.verificationService.getData$();
    public verificationStatus$: Observable<RestaurantVerificationEnum> = this.verificationService.verificationStatus$.asObservable();
    public verificationMessage$: Observable<string> = this.verificationService.verificationMessage$.asObservable();

    public VerificationPriority: typeof VerificationPriority = VerificationPriority;
    public RestaurantVerificationEnum: typeof RestaurantVerificationEnum = RestaurantVerificationEnum;

    constructor(
      private verificationService: VerificationService
    ) {
    }

    public ngOnInit() {

    }
}
