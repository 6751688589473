<div class="modal-top-bar">
    <div class="modal-top-left-action"></div>

    <!-- Title -->
    <div class="modal-top-title">
       {{ (!!(tempData$ | async)?.imageData?.url
          ? ((tempData$ | async)?.imageData?.usageType === BlobUsageTypeEnum.RESTAURANT_DOCUMENT ? 'menu_upload_modal_document_edit' : 'menu_upload_modal_image_edit')
          : ((tempData$ | async)?.imageData?.usageType === BlobUsageTypeEnum.RESTAURANT_DOCUMENT ? 'menu_upload_modal_document_new' : 'menu_upload_modal_image_new')       
       ) | translate }}
    </div>

    <!-- Cancel button -->
    <div class="modal-top-right-action" >
        <div class="action-item" (click)="onCancel()">
            {{ 'menu_product_modal_cancel' | translate }}
        </div>
    </div>
</div>

<div class="modal-content">

    <div class="top-part">
      <!-- Used for -->
      <ng-container *ngIf="!!(tempData$ | async)?.usageName">
        <div class="usage-text-container">    
          <!-- Name title -->
          <div class="subtitle-text">
            {{ 'menu_upload_modal_image_usage' | translate }}
          </div>
          <div class="pl-3">
            {{ (tempData$ | async)?.usageName }}
          </div>
        </div>
      </ng-container>

      <!-- Image -->
      <div class="main-image-container">

        <!-- The (real) image -->
        <ng-container *ngIf="!!(tempData$ | async)?.imageData?.url; else defaultImage">
          <div class="product-image-filled">
            <!-- It is a PDF / -->
            <ng-container *ngIf="((tempData$ | async)?.imageData?.url || '').indexOf('.pdf') > 0; else imageSrc">                
                <div class="no-preview-box">
                  {{ 'menu_upload_modal_no_preview'| translate }}
                </div>
            </ng-container>

            <!-- Image preview -->
            <ng-template #imageSrc>
                <img class="product-image-src" [src]="(tempData$ | async)?.imageData?.url || 'apps/restaurant/src/assets/add-image-default.svg'"/>
            </ng-template>
          </div>

          <!-- Open externally as link -->
          <a [href]="(tempData$ | async)?.imageData?.url || '#'" target="_blank" class="external-link-container">
            {{ ((tempData$ | async)?.imageData?.usageType === BlobUsageTypeEnum.RESTAURANT_DOCUMENT 
                ? 'menu_upload_modal_document_link' : 'menu_upload_modal_image_link') | translate }}
            <img [src]="'apps/restaurant/src/assets/external-link-file.svg'"/>
          </a>
        </ng-container>

        <!-- Default (not set) image -->
        <ng-template #defaultImage>
          <div class="default-main-container">
            <div class="default-image-container">
              <!-- Image -->
              <div class="product-image-default">
                <div class="product-image-default-icon">
                  <img [src]="'apps/restaurant/src/assets/add-image-default.svg'"/>
                </div>
              </div>
              
              <!-- Text -->
              <div class="default-text-container">
                <ng-container *ngIf="((tempData$ | async)?.imageData?.usageType === 'PRODUCT_IMAGE')">
                  <div class="default-text-title"> 
                    {{ 'menu_upload_modal_image_product_missing'| translate }}
                  </div>
                  <div class="default-text-description">
                    {{ 'menu_upload_modal_image_product_missing_extra'| translate }}
                  </div>
                </ng-container>
              </div>            
            </div>
          </div>
        </ng-template>

        <!-- File requirements -->
        <div class="requirements-text">              
          <div> •  {{ 'menu_upload_modal_image_files'| translate }}
            <ng-container *ngFor="let item of (tempData$ | async)?.supportedFiles; index as i">
              {{ item?.extension }}<span>{{ i === ((tempData$ | async)?.supportedFiles?.length || 0) - 1  ? "." : "," }}</span>
            </ng-container>
            {{ 'menu_upload_modal_image_filesize'| translate }}</div>
          <div> •  {{ 'menu_upload_modal_image_size'| translate }}</div>              
        </div>
      
        <!-- Upload -->
        <div class="button-upload">
          <!-- Button - upload-->
          <fs-button [text]="(!!(tempData$ | async)?.imageData?.url 
                        ? ((tempData$ | async)?.imageData?.usageType === BlobUsageTypeEnum.RESTAURANT_DOCUMENT ? 'menu_upload_modal_document_upload_other_button' : 'menu_upload_modal_image_upload_button_other') 
                        : ((tempData$ | async)?.imageData?.usageType === BlobUsageTypeEnum.RESTAURANT_DOCUMENT ? 'menu_upload_modal_document_upload_button' : 'menu_upload_modal_image_upload_button' )) 
                        | translate"
                     [type]="'raised'" [leadingIcon]="'add'"
                     (onPress)="onUploadPress()"
                     [disabled]="((uploadFileState$ | async)?.uploadInProgres) || false">                     
          </fs-button>
          <input #uploadInputElem type="file" (change)="onFileSelected($event)" style="display: none;"
              accept="{{contentTypeFiles$() | async }}">

          <!-- <div> {{ 'menu_upload_modal_image_upload_drag'| translate }}</div>      -->
        </div>

        <!-- Status of uploading -->
        <ng-container *ngIf="(uploadFileState$ | async)?.file">
          <div class="file-status-container">
            <div class="file-name-text">
              <span class="flex items-center attach-files-info">
                <mat-icon class="mr-2 overflow-auto"> attach_file</mat-icon> 
                <span class="pl-1 attach-file-name">{{ 'menu_upload_modal_image_file_name' | translate }}</span>
                <span class="pr-3 pl-1">{{ (uploadFileState$ | async)?.file?.name }}</span>
              </span>
              <span class="flex items-center">
                <mat-icon> cloud_queue</mat-icon>
                <span class="pl-1">{{ 'menu_upload_modal_image_file_size' | translate }}</span>
                <span class="pr-3 pl-1"> {{ ((uploadFileState$ | async)?.file?.size || 0) | bytesToMB }}</span>
              </span>
            </div>
          </div>
        </ng-container>

        <!-- Uploaded started / loading -->
        <ng-container *ngIf="(uploadFileState$ | async)?.uploadInProgres">
            <div class="loading-container">
              <!-- Loading spinner -->
              <div class="upload-spinner"></div>
              <div class="loading-text">{{ 'menu_upload_modal_image_uploading' | translate }}</div>
            </div>            
        </ng-container>

        <!-- Upload successful -->
        <ng-container *ngIf="(uploadFileState$ | async)?.uploadSuccessful">
            <div class="success-container">
              <div class="loading-text"><mat-icon>check</mat-icon> <span class="pl-2">{{ 'menu_upload_modal_image_uploaded' | translate }}</span></div>
            </div>
        </ng-container>

        <!-- Error -->
        <ng-container *ngIf="(uploadFileState$ | async)?.error !== 'NONE'">
          <div class="success-container">
            <div class="error-text"><span class="mr-3"><mat-icon>error_outline</mat-icon></span>
              <ng-container *ngIf="(uploadFileState$ | async)?.error === 'SIZE_EXCEEDED'">{{ 'menu_upload_modal_image_failed_size' | translate }}</ng-container>
              <ng-container *ngIf="(uploadFileState$ | async)?.error === 'API_ERROR'">{{ 'menu_upload_modal_image_failed_api' | translate }}</ng-container>
              <ng-container *ngIf="(uploadFileState$ | async)?.error === 'DIMENSIONS_EXCEEDED'">{{ 'menu_upload_modal_image_failed_dimensions' | translate }}</ng-container>
            </div>
          </div>
        </ng-container>
          

      </div>
    </div>
    
    <!-- Add or Save changes button -->
    <div class="modal-bottom-action">
        <!-- <fs-button [text]="(!!(tempData$ | async)?.id  ? 'menu_addon_modal_create_button_save' : 'menu_addon_modal_create_button_add') | translate" [style]="'full-width-large-height'" -->
        <fs-button [text]="((tempData$ | async)?.imageData?.usageType === BlobUsageTypeEnum.RESTAURANT_DOCUMENT ? 'menu_upload_modal_document_button_save' :'menu_upload_modal_image_button_save') | translate" [style]="'full-width-large-height'"
                   [type]="'raised-primary'" (onPress)="onSaveChanges()" [disabled]="(isButtonDisabled$() | async) || false">
        ></fs-button>
    </div>
</div>
