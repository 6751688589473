import {Component, OnDestroy, OnInit} from '@angular/core';
import { VerificationService } from '../../../services/verification-status/verification.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';


@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {

  public verificationNeeded$ = this.verificationService.verificationNeeded$();
  
  private routeSub: Subscription;

  constructor(
      private verificationService: VerificationService,
      private router: Router
    ) {
    
    }

    public ngOnInit() {
      this.checkRouteParams();
    }

    private checkRouteParams() {   
      
      this.checkSubmitRoute(this.router.url);

      this.routeSub = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.checkSubmitRoute(event.url);
        }
      });
    }

    private checkSubmitRoute(path: string) {
      if (path === "/dashboard/submit-verification") {
        this.submitVerification();
        this.router.navigateByUrl("/dashboard");
      }
    }

    private submitVerification() {
      this.verificationService.submitVerification();
    }

    public ngOnDestroy(): void {
        if (!!this.routeSub ) {
          this.routeSub.unsubscribe();
        }
    }
}
