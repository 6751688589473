<main-layout [selected]="'ORDERS'">
    <content>
        <!-- Orders -->
        <div *ngIf="(((ordersList$ | async)?.length || 0) > 0); else noOrders">
            <div class="orders-container">
                <!-- The list of all oders-->
                <div class="orders-list">

                    <!-- Each item -->
                    <ng-container *ngFor="let order of (ordersList$ | async)">
                        <div class="order-href" (click)="onOpenOrder(order)" > 
                            <div class="order-item" [ngClass]="
                                    {'is-current-selected': order.id === (currentSelectedOrder$ | async)?.id,
                                    'is-action-needed': order.status === OrderStatus.PAID}
                                ">
                                <!-- Image -->
                                <div class="logo-main-container">
                                    <div class="logo-image-container">
                                        <!-- Image -->
                                        <div class="logo-image-default">
                                            <div class="logo-icon-default">
                                                
                                                <img [src]="
                                                    order.status === OrderStatus.PENDING_PAYMENT ? 'apps/restaurant/src/assets/restaurant_order_status_payment_pending.svg' :
                                                    order.status === OrderStatus.PAID ? 'apps/restaurant/src/assets/restaurant_order_status_action_needed.svg' :
                                                    order.status === OrderStatus.PREPARING ? 'apps/restaurant/src/assets/restaurant_order_status_preparing.svg' :
                                                    order.status === OrderStatus.READY_FOR_PICKUP ? 'apps/restaurant/src/assets/restaurant_order_status_ready_for_pick.svg' :
                                                    order.status === OrderStatus.IN_DELIVERY ? 'apps/restaurant/src/assets/restaurant_order_status_in_delivery.svg' :
                                                    order.status === OrderStatus.DELIVERED ? 'apps/restaurant/src/assets/restaurant_order_status_delivered.svg' :
                                                    order.status === OrderStatus.CANCELLED || order.status === OrderStatus.DECLINED_BY_RESTAURANT ? 'apps/restaurant/src/assets/restaurant_order_status_cancelled.svg' :
                                                    order.status === OrderStatus.REFUNDED ? 'apps/restaurant/src/assets/restaurant_order_status_refunded.svg' :
                                                    'apps/restaurant/src/assets/add-image-default.svg'"/>                                            
                                            </div>
                                            <!-- mini loading spinner-->
                                            <ng-container *ngIf="
                                                order.status === OrderStatus.PAID"
                                            >
                                                <div class="spinning-loader-action-needed">
                                                    <fs-loading-spinner [variant]="LOADING_STYLE.TWO_DOTS_RED"></fs-loading-spinner>
                                                </div>    
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            
                                <!-- Content -->
                                <div class="item-text-content">
                                    
                                    <!-- Content bottom -->
                                    <div class="item-content-mid">
                                        <div class="item-restaurant-name">
                                            <ng-container *ngFor="let product of order.items; index as productIndex">
                                                {{ product.productName }}{{ productIndex === (order.items.length - 1)  ? "" : ","  }}
                                            </ng-container>
                                        </div>
                                    
                                        
                                        <div class="flex flex-row items-center">
                                            <!-- Status -->
                                                <ng-container *ngIf="order.status === OrderStatus.PAID">
                                                    <div class="item-order-status white mr-2">
                                                        {{ "orders_page_action_required" | translate }}
                                                    </div>
                                                </ng-container>

                                                <div class="item-order-status mr-2" 
                                                    [ngClass]="{                                                    
                                                        'orange': 
                                                            order.status === OrderStatus.CREATED || 
                                                            order.status === OrderStatus.PENDING_PAYMENT,
                                                        'light-blue': 
                                                            order.status === OrderStatus.PAID || 
                                                            order.status === OrderStatus.ACCEPTED_BY_RESTAURANT,
                                                        'light-red': 
                                                            order.status === OrderStatus.PAYMENT_FAILED || 
                                                            order.status === OrderStatus.CANCELLED ||
                                                            order.status === OrderStatus.DECLINED_BY_RESTAURANT,
                                                        'yellow': order.status === OrderStatus.PREPARING,
                                                        'light-green': 
                                                            order.status === OrderStatus.IN_DELIVERY || 
                                                            order.status === OrderStatus.OUT_FOR_DELIVERY || 
                                                            order.status === OrderStatus.READY_FOR_PICKUP,
                                                        'green': order.status === OrderStatus.DELIVERED,                                                                                                    
                                                        'violet': order.status === OrderStatus.REFUNDED,
                                                    }">
                                                    {{ order.status.charAt(0).toUpperCase() + order.status.slice(1).toLowerCase() }}
                                                </div>

                                            <!-- Delivery type -->
                                            <div class="item-products mr-2">                                            
                                                {{ order.deliveryOption.charAt(0).toUpperCase() + order.deliveryOption.slice(1).toLowerCase() }}
                                            </div>
                                            <!-- Icon bicycle or hand-->
                                            <span class="item-delivery-type">
                                                <img [src]="'apps/restaurant/src/assets/' + ((order.deliveryOption === DeliveryOption.DELIVERY) ? 'delivery-type-bicycle-selected' : 'delivery-type-pick-up-hand-selected')  + '.svg'"/>
                                            </span>
                                        </div>
                                    </div>
                
                                    <!-- Content bottom -->
                                    <div class="item-content-right">
                                        
                                        <div class="item-order-delivery-date">
                                            <span class="item-delivery-type">
                                                <!-- <img [src]="'apps/user-app/src/assets/' + ((order.deliveryOption === DeliveryOption.DELIVERY) ? 'delivery-type-bicycle-selected' : 'delivery-type-pick-up-hand-selected')  + '.svg'"/> -->
                                            </span>
                                            <span class="item-date">{{ order.createdDate | date:"dd.MMM, hh:mm"}}</span> 
                                        </div>
                                        <div class="item-order-slug">
                                            #{{ order.slug }}
                                        </div>
                                        <div class="item-price">
                                            {{ order.totalPriceCents | format_cents: true }}
                                        </div>
                                    </div>     
                                    
                                    <!-- Selected arrow -->
                                    <div *ngIf="order.id === (currentSelectedOrder$ | async)?.id" class="order-opened-arrow">
                                        <mat-icon class="opened-arrow-icon">play_arrow</mat-icon>
                                    </div>  
                                </div>     
                                                                                
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div class="order-wrapper">
                    <div class="order-rectangle">
                        <div class="order-rectangle-inner">

                            <div class="left-right-container">
                                <div class="left-text">
                                    <div>Order #{{ (currentSelectedOrder$ | async)?.slug }}</div>
                                    <div>Hash id: {{ (currentSelectedOrder$ | async)?.id }}</div>
                                    <div>Delivery option: {{ (currentSelectedOrder$ | async)?.deliveryOption }}</div>
                                    <div>createdDate: {{ (currentSelectedOrder$ | async)?.createdDate | date:"dd.MM.yyyy, hh:mm"}}</div>                            
                                    <div>lastModifiedDate: {{ (currentSelectedOrder$ | async)?.lastModifiedDate | date:"dd.MM.yyyy, hh:mm"}}</div>                            
                                    <div>initialPriceCents: {{ (currentSelectedOrder$ | async)?.initialPriceCents || 0 | format_cents: true }}</div>
                                    <div>costOfDeliveryCents: {{ (currentSelectedOrder$ | async)?.costOfDeliveryCents || 0 | format_cents: true }}</div>
                                    <div>deliveryDiscountCents: {{ (currentSelectedOrder$ | async)?.deliveryDiscountCents || 0 | format_cents: true }}</div>
                                    <div>totalDeliveryPriceCents: {{ (currentSelectedOrder$ | async)?.totalDeliveryPriceCents || 0 | format_cents: true }}</div>
                                    <div>totalPriceCents: {{ (currentSelectedOrder$ | async)?.totalPriceCents || 0 | format_cents: true }}</div>
                                    <div>items: [
                                        <!-- Product item -->
                                        <ng-container *ngFor="let product of (currentSelectedOrder$ | async)?.items; index as i">
                                            <div class="product-item">
                                                <div class="product-row-1">
                                                    <div class="product-name"> {{ product.quantity + " x " + product.productName }}</div>                                            
                                                    <div class="product-price">
                                                        productPriceCents: {{ product.productPriceCents || 0 | format_cents: true }}
                                                        productPriceCentsDelivery: {{ product.productPriceCentsDelivery || 0 | format_cents: true }}
                                                        <ng-container *ngFor="let addon of product.addons">
                                                            <p> Addon:
                                                                {{ addon.addonName }} price: {{ addon.addonPriceCents || 0 | format_cents: true }}, quantity: {{ addon.quantity}}
                                                            </p>
                                                            
                                                        </ng-container>                                                
                                                    </div>
                                                </div>
        
                                            </div>
                                        </ng-container>
                                        ]
                                    </div>    
        
                                    <div>paymentType: {{ (currentSelectedOrder$ | async)?.paymentType }}</div>
                                    <div>stripePaymentIntent: {{ (currentSelectedOrder$ | async)?.stripePaymentIntent }}</div>                            
                                    <div>statusTimestamps: [
                                        <div *ngFor="let item of (currentSelectedOrder$ | async)?.statusTimestamps | keyvalue">
                                        <p>{{ item.key }}: {{ (item.value?.toString()) | date: "dd.MM.yyyy, hh:mm" }}</p>
                                        </div>
                                        ]
                                    </div>    
                                    <div>status: {{ (currentSelectedOrder$ | async)?.status }}</div>
                                </div>
    
                                <div class="right-text">

                                    <!-- Address - user's delivery address -->
                                    <div class="orders-item-headline">
                                        {{ "orders_page_delivery_type" | translate }}
                                        <span class="orders-item-headline-selected">
                                            {{ ("orders_page_delivery_type_" + (currentSelectedOrder$ | async)?.deliveryOption?.toLowerCase()) | translate }}
                                        </span>
                                    </div>
                                    <div class="address-container">                                        
                                        <ng-container *ngIf="(currentSelectedOrder$ | async)?.userAddress; else noUserAddress">
                                            <div class="fs-address-container">
                                                <fs-delivery-address [inputAddress]="(currentSelectedOrder$ | async)?.userAddress">
                                                </fs-delivery-address>    
                                            </div>                                            
                                            <div class="address-cta-buttons">
                                                <div class="address-cta" (mousedown)="onCopyClick()">
                                                    <img class="address-cta-src" [src]="'apps/restaurant/src/assets/copy.svg'"/>
                                                    {{ "orders_page_address_copy" | translate }}
                                                </div>
                                    
                                                <div class="address-cta"  (mousedown)="onExternalMapsClick()">
                                                    <img class="address-cta-src" [src]="'apps/restaurant/src/assets/external-link-file.svg'"/>
                                                    {{ "orders_page_address_maps" | translate }}
                                                </div>
                                            </div>

                                        </ng-container>
                                        <ng-template #noUserAddress>
                                            Customer address not found for this order.
                                        </ng-template>
                                    </div>
                                    
                                    <!-- Payment -->
                                    <div class="payment-container">
                                        <div class="orders-item-headline">
                                            {{ "orders_page_payment_header" | translate }}
                                        </div>
                                        <div class="payment-container-box">                                            
                                            <!-- CASH selected, pay at pickup location -->
                                            <ng-container *ngIf="(currentSelectedOrder$ | async)?.paymentType === PaymentOption.CASH">
                                                <div class="payment-option">
                                                    <div class="payment-option-logo">
                                                        <img class="pt-2" [src]="'apps/restaurant/src/assets/checkout-cash-selected.svg'">
                                                    </div>
                                                    <span class="payment-option-text">{{ "orders_page_payment_cash" | translate }}</span>
                                                </div>
                                            </ng-container>
                            
                                            <!-- Already paid online -->
                                            <ng-container *ngIf="(currentSelectedOrder$ | async)?.paymentType === PaymentOption.ONLINE">
                                                <div class="payment-option">
                                                    <div class="payment-option-logo">
                                                        <img [src]="'apps/restaurant/src/assets/checkout-online-selected.svg' ">
                                                    </div>
                                                    <span class="payment-option-text">{{ "orders_page_payment_online" | translate }}</span>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                                                        

                            <!-- Button - Change status -->
                                <div class="change-status-container">
                                <strong>CHANGE STATUS TO:</strong>
                                    
                                <fs-button [text]="'PAID' | translate"
                                        class="button-container"
                                        [type]="'raised-primary'" (onPress)="pressStateChange('PAID')">
                                </fs-button>
    
                                <fs-button [text]="'ACCEPTED_BY_RESTAURANT' | translate"
                                        class="button-container"
                                        [type]="'raised-primary'" (onPress)="pressStateChange('ACCEPTED_BY_RESTAURANT')">
                                </fs-button>
    
                                <fs-button [text]="'DECLINED_BY_RESTAURANT' | translate"
                                        class="button-container"
                                        [type]="'raised-primary'" (onPress)="pressStateChange('DECLINED_BY_RESTAURANT')">
                                </fs-button>
    
                                <fs-button [text]="'PREPARING' | translate"
                                        class="button-container"
                                        [type]="'raised-primary'" (onPress)="pressStateChange('PREPARING')">
                                </fs-button>
    
                                <fs-button [text]="'READY_FOR_PICKUP' | translate"
                                        class="button-container"
                                        [type]="'raised-primary'" (onPress)="pressStateChange('READY_FOR_PICKUP')">
                                </fs-button>
    
                                <fs-button [text]="'OUT_FOR_DELIVERY' | translate"
                                        class="button-container"
                                        [type]="'raised-primary'" (onPress)="pressStateChange('OUT_FOR_DELIVERY')">
                                </fs-button>
    
                                <fs-button [text]="'DELIVERED' | translate"
                                        class="button-container"
                                        [type]="'raised-primary'" (onPress)="pressStateChange('DELIVERED')">
                                </fs-button>
    
                                <fs-button [text]="'CANCELLED' | translate"
                                        class="button-container"
                                        [type]="'raised-primary'" (onPress)="pressStateChange('CANCELLED')">
                                </fs-button>
    
                                <fs-button [text]="'REFUNDED' | translate"
                                        class="button-container"
                                        [type]="'raised-primary'" (onPress)="pressStateChange('REFUNDED')">
                                </fs-button>
                                </div>
                            

                        </div>
                    </div>
                </div>
            </div>                
        </div>     
        
        <!-- User has no orders -->
        <ng-template #noOrders>                

            <div *ngIf="(isLoadingOrdersAPI$ | async)" class="loading-orders-spin-container">
                <fs-loading-spinner [variant]="LOADING_STYLE.CIRCLE_GRAY"></fs-loading-spinner>
            </div>

            <div *ngIf="!(isLoadingOrdersAPI$ | async)" class="missing-orders-container">
                <!-- Image -->
                <div class="missing-orders-image">
                    <img [src]="'apps/restaurant/src/assets/no-orders-found.jpg'"/>
                </div>

                <!-- Texts -->
                <div class="missing-orders-text-container">
                    <!-- Title -->
                    <div class="missing-orders-title">
                        <!-- Image (mobile) -->
                        <div class="missing-orders-image-mobile">
                            <img [src]="'apps/restaurant/src/assets/no-orders-found.jpg'"/>
                        </div>
                        <div class="mr-2">{{ "orders_page_no_orders_title" |  translate }}</div>
                        <mat-icon>history</mat-icon>
                    </div>
                    <!-- Body -->
                    <div class="missing-orders-body">
                        {{ "orders_page_no_orders_body" |  translate }}
                    </div>
                    <!-- Button -->
                    <ng-container *ngIf="(isVerified$ | async) === false"> 
                        <div class="missing-orders-button">
                            <!-- Button - Check restaurants -->
                            <fs-button [text]="'orders_page_no_orders_cta_1' | translate"
                                    class="button-container"
                                    [type]="'raised'" [leadingIcon]="'assignment_ind'" (onPress)="onPressVerify()">
                            </fs-button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-template>
    </content>
</main-layout>

